import absoluteRouter from 'app/model/absoluteRouter';
import router from 'app/model/router';
import 'minimal/component/posting-locations';
import i18n from 'core/i18n/i18n';

export default class CsApplicationTileViewModel {

    constructor({ application }) {
        this.application = application;
        this.menuId = `#menu-${application.submissionId()}`;

        // Only the first one should be displayed in the component.
        const [unscheduledInterviewRequest] = this.application.unscheduledInterviewRequests();

        this.unscheduledInterviewRequest = unscheduledInterviewRequest;

        this.isApplicationActive = this.application.isActive();

        this.url = absoluteRouter.interpolate({}, 'job-details', { jobId: this.application.jobId });
        this.applicationStatusButtonAriaLabel = `${i18n('candidate-self-service.tile.status')} ${this.application.status()}`;
    }

    openJobPreview() {
        router.go('job-details', { jobId: this.application.jobId });
    }

}