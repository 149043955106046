import http from 'core/http/http';
import storageService from 'core/storage/sessionStorage';
import localStorageService from 'core/storage/localStorage';
import cookieStorage from 'core/cookie/storage';
import appConfig from 'app/model/config';
import siteLanguage from 'ce-common/service/language/siteLanguage';
import generateUUID from 'app/module/core/utils/generateUUID';
import favouriteEvents from 'app/module/cx/module/favourite/config/events';
import userEvents, { USER_ACCEPTED_COOKIE_CONSENT_ANALYTICAL, USER_DECLINED_COOKIE_CONSENT_ANALYTICAL } from 'cx/config/userEvents';
import geolocationService from 'cx/service/geolocation/geolocation';
import sourceTrace from '../module/source-tracking/service/sourceTrace';
import { applicationDraft } from '../module/apply-flow/service/applicationDraftInstance';
import { isAnalyticalCookieAllowed } from './cookieAgreement';

const COOKIE_USER_TRACKING = 'ORA_CX_USERID';
const FAVOURITE_JOBS = 'favourite-jobs-ids';
const FAVOURITE_STORAGE_KEY = `favourite-jobs_${siteLanguage.get()}`;
const RECENT_SEARCH = 'recent-searches';

const Actions = {
    SUBMIT: 'SUBMIT',
    SUBMIT_SUCCESS: 'SUBMIT_SUCCESS',
    VERIFY: 'VERIFY',
    VALID: 'VALID',
    TOKEN_NEW: 'NEW',
    ID_NEXT: 'ID_NEXT',
    ID_CANCEL: 'ID_CANCEL',
    APPLY_FLOW_VISIT: 'APPLY_FLOW_VISIT',
    NEW: 'NEW',
    VIEW: 'VIEW',
    PIN: 'PIN',
    UNPIN: 'UNPIN',
    SEARCH: 'SEARCH',
    ASSESSMENT_START: 'ASSESSMENT_START',
    ASSESSMENT_SUCCESS: 'ASSESSMENT_SUCCESS',
    TAXCREDIT_START: 'TAXCREDIT_START',
    TAXCREDIT_SUCCESS: 'TAXCREDIT_SUCCESS',
};

const originDictionary = {
    'job-details.email-verification': 'pre-apply',
    'job-preview.email-verification': 'pre-apply',
    'job-details.confirm-email': 'post-apply',
    'home-page': 'css',
    'talent-community': 'tc',
    'apply-flow': 'job-apply',
    'job-preview.apply-flow': 'job-apply',
    'candidate-self-service': 'css',
    'candidate-self-service.sign-in': 'css',
    misc: 'misc',
};

export const TEMP_CANDIDATE_NUMBER = 'ORA_CANDIDATE_NUMBER';

let geoLocation = null;

function addLocation() {
    const location = {
        location: {
            settlement: geoLocation?.settlement ?? '',
            municipality: geoLocation?.city ?? '',
            region: geoLocation?.state ?? '',
            country: geoLocation?.countryCode ?? '',
        },
    };

    return location;
}

function addSource() {
    const {
        source,
        sourceMedium,
        campaignCode,
        campaignPostId,
        referrerHeader,
        sourceInternalInfo,
    } = sourceTrace.getSourceTraceData();

    const data = {
        source: {
            sourceTrackingId: sourceTrace.getParentSourceTrackingId(),
            source,
            sourceMedium,
            campaignCode,
            campaignPostId,
            referrerHeader,
            sourceInternalInfo,
        },
    };

    return data;
}

function getContentForSignIn(action, jobId, origin) {
    let content = {
        entity: 'SIGN-IN',
        action,
        identifier: jobId,
        draftId: applicationDraft?.id ?? undefined,
        origin: originDictionary[origin] ?? originDictionary.misc,
        siteNumber: appConfig.siteNumber,
        attributes: null,
    };

    content = Object.assign(content, addSource(), addLocation());

    return content;
}

function getContentForJA(jobId, action, attributes) {
    let content = {
        entity: 'JOB_APPLICATION',
        action,
        identifier: jobId,
        draftId: applicationDraft?.id ?? undefined,
        siteNumber: appConfig.siteNumber,
        attributes,
    };

    content = Object.assign(content, addSource(), addLocation());

    return content;
}

function getContentForReq(jobId, action, attributes) {
    let content =
    {
        entity: 'REQUISITION',
        action,
        identifier: jobId,
        siteNumber: appConfig.siteNumber,
        attributes,
    };

    content = Object.assign(content, addSource(), addLocation());

    return content;
}

function trackCandidateNumber(candidateNumber) {
    _trackUserAction(null, candidateNumber)
        .then((candidateData) => {
            if (candidateData) {
                trackDataForNewUser(candidateData.userId);
            }
        })
        .catch(error => console.error(error));
}

function trackDataForNewUser(newUserId) {
    let userId = cookieStorage.get(COOKIE_USER_TRACKING);

    if (userId !== newUserId) {
        cookieStorage.set(COOKIE_USER_TRACKING, newUserId, { days: 90 });
        userId = newUserId;
    }

    _restoreUserTrackingData(userId);
}

function trackUserByUUID() {
    const userId = generateUUID();

    cookieStorage.set(COOKIE_USER_TRACKING, userId, { days: 90 });
    _trackUserAction(null, null);
}

function trackJobApplicationSubmit(jobId) {
    const content = getContentForJA(jobId, Actions.SUBMIT);

    _trackUserAction(content, null);
}

function trackJobAppSubmitSuccess(jobId, submitId) {
    let content = getContentForJA(jobId, Actions.SUBMIT_SUCCESS);

    const addOn = {
        submissionId: submitId,
    };

    content = Object.assign(content, addOn);

    _trackUserAction(content, null);
}

function trackVerifyToken(jobId, origin) {
    const content = getContentForSignIn(Actions.VERIFY, jobId, origin);

    _trackUserAction(content);
}

function trackVerifyTokenSucess(jobId, origin) {
    const content = getContentForSignIn(Actions.VALID, jobId, origin);

    _trackUserAction(content);
}

function trackResendToken(jobId, origin) {
    const content = getContentForSignIn(Actions.TOKEN_NEW, jobId, origin);

    _trackUserAction(content);
}

function trackJobAppEntryNext(jobId, origin) {
    const content = getContentForSignIn(Actions.ID_NEXT, jobId, origin);

    _trackUserAction(content, null);
}

function trackJobAppEntryCancel(jobId, origin) {
    const content = getContentForSignIn(Actions.ID_CANCEL, jobId, origin);

    _trackUserAction(content, null);
}

function trackJobApplyIn(jobId, presentSection, sections) {
    let content = getContentForJA(jobId, Actions.APPLY_FLOW_VISIT);

    const addOn = {
        pages: {
            currentSection: presentSection,
            totalSections: sections,
        },
    };

    content = Object.assign(content, addOn);

    _trackUserAction(content, null);
}

function _trackPartner({ jobId, submitId, action, origin }) {
    let content = getContentForJA(jobId, action);

    const addOn = {
        submissionId: submitId,
        origin: originDictionary[origin] ?? originDictionary.misc,
    };

    content = Object.assign(content, addOn);

    _trackUserAction(content, null);
}

function trackJobAppAssessmentStart(jobId, submitId, origin) {
    _trackPartner({
        jobId,
        submitId,
        action: Actions.ASSESSMENT_START,
        origin,
    });
}

function trackJobAppAssessmentSuccess(jobId, submitId, origin) {
    _trackPartner({
        jobId,
        submitId,
        action: Actions.ASSESSMENT_SUCCESS,
        origin,
    });
}

function trackJobAppTaxCreditStart(jobId, submitId, origin) {
    _trackPartner({
        jobId,
        submitId,
        action: Actions.TAXCREDIT_START,
        origin,
    });
}

function trackJobAppTaxCreditSuccess(jobId, submitId, origin) {
    _trackPartner({
        jobId,
        submitId,
        action: Actions.TAXCREDIT_SUCCESS,
        origin,
    });
}

function trackJobApplicationStart(jobId, applyMethod) {
    const attributes = {
        apply: applyMethod,
    };

    const content = getContentForJA(jobId, Actions.NEW, attributes);

    _trackUserAction(content);
}

function trackJobView(jobId) {
    const content = getContentForReq(jobId, Actions.VIEW, null);

    _trackUserAction(content);
}

function trackAddRequisitionToFavourites(job) {
    const content = getContentForReq(job.id, Actions.PIN, null);

    _trackUserAction(content, null);
}

function trackRemoveRequisitionToFavourites(job) {
    const content = getContentForReq(job.id, Actions.UNPIN, null);

    _trackUserAction(content, null);
}

function trackRequisitionSearch(searchParams) {
    let searchKey = null;

    if (searchParams.keyword && searchParams.locationId) {
        searchKey = `${searchParams.keyword}|${searchParams.locationId}`;
    } else if (searchParams.keyword) {
        searchKey = searchParams.keyword;
    } else if (searchParams.locationId) {
        searchKey = searchParams.locationId;
    }


    const attributes = {
        searchKey,
        keyword: searchParams.keyword,
        location: searchParams.location,
        locationId: searchParams.locationId,
        locationLevel: searchParams.locationLevel,
        radius: searchParams.radius,
        radiusUnit: searchParams.radiusUnit,
    };

    const content = getContentForReq(null, Actions.SEARCH, attributes);

    _trackUserAction(content);
}

async function initializeUserTracking() {
    const userId = cookieStorage.get(COOKIE_USER_TRACKING);

    await geolocationService.query()
        .then((location) => {
            geoLocation = location;
        })
        .catch((error) => {
            if (error?.code === window.GeolocationPositionError.PERMISSION_DENIED) {
                console.info(error);
            } else {
                console.error(error);
            }
        });

    if (userId) {
        _restoreUserTrackingData(userId);
        cookieStorage.set(COOKIE_USER_TRACKING, userId, { days: 90 });
    } else {
        trackUserByUUID();
    }
}

function isCookieAllowed() {
    return isAnalyticalCookieAllowed();
}

function init() {
    if (isCookieAllowed()) {
        initializeUserTracking();
    }
}

function clearUserTrackingCookie() {
    cookieStorage.remove(COOKIE_USER_TRACKING);
    cookieStorage.remove(TEMP_CANDIDATE_NUMBER);
    localStorageService.remove(RECENT_SEARCH);
    _clearFavouriteJobs();
}


function _clearFavouriteJobs() {
    storageService.remove(FAVOURITE_JOBS);
    storageService.remove(FAVOURITE_STORAGE_KEY);
}

function _storeUserTrackingData(userTracking) {
    const { candidateNumber } = userTracking;

    if (candidateNumber) {
        cookieStorage.set(TEMP_CANDIDATE_NUMBER, candidateNumber, { days: 90 });
    }

    if (userTracking.content) {
        const userTrackingContent = JSON.parse(userTracking.content);

        const pinJobs = userTrackingContent.filter(el => el.action === 'PIN')
            .map(item => item.identifier);

        const recentSearchesParams = userTrackingContent
            .filter(item => item.action === 'SEARCH')
            .map((item) => {
                delete item.attributes.searchKey;

                return item.attributes;
            });

        if (pinJobs) {
            _clearFavouriteJobs();
            storageService.store(FAVOURITE_JOBS, pinJobs);
            favouriteEvents.favouriteJobsSet.dispatch(pinJobs);
        }

        if (recentSearchesParams) {
            localStorageService.store(RECENT_SEARCH, recentSearchesParams);
        }
    }
}


function _restoreUserTrackingData(userId) {
    return http.get(`/recruitingCEUserTrackings/${userId}`).then(userTracking => _storeUserTrackingData(userTracking));
}

function _trackUserAction(content, candidateNumber) {
    if (isCookieAllowed()) {
        let temporaryUserId = null;
        const candidateIdCookie = cookieStorage.get(TEMP_CANDIDATE_NUMBER);

        if (candidateNumber) {
            temporaryUserId = generateUUID();
            cookieStorage.set(TEMP_CANDIDATE_NUMBER, candidateNumber, { days: 90 });
        }

        const userId = cookieStorage.get(COOKIE_USER_TRACKING);

        const userData =
        {
            userId,
            candidateNumber: candidateNumber || candidateIdCookie,
            temporaryUserId,
            Content: content ? JSON.stringify(content) : null,
        };

        return http.post('/recruitingCEUserTrackings', userData, {
            statusCodes: {
                401() {
                    return Promise.resolve();
                },

                500() {
                    return Promise.resolve();
                },
            },
        });
    }

    return Promise.resolve();
}

userEvents[USER_ACCEPTED_COOKIE_CONSENT_ANALYTICAL].add(init);
userEvents[USER_DECLINED_COOKIE_CONSENT_ANALYTICAL].add(clearUserTrackingCookie);


export default {
    trackCandidateNumber,
    trackDataForNewUser,
    trackUserByUUID,
    trackJobAppEntryNext,
    trackJobAppEntryCancel,
    trackJobApplicationSubmit,
    trackJobAppSubmitSuccess,
    trackJobApplicationStart,
    trackJobApplyIn,
    trackJobView,
    trackVerifyToken,
    trackVerifyTokenSucess,
    trackResendToken,
    trackJobAppAssessmentStart,
    trackJobAppAssessmentSuccess,
    trackJobAppTaxCreditStart,
    trackJobAppTaxCreditSuccess,
    trackAddRequisitionToFavourites,
    trackRemoveRequisitionToFavourites,
    trackRequisitionSearch,
    initializeUserTracking,
    isCookieAllowed,
    init,
    clearUserTrackingCookie,
};
