import mapping from 'knockout-mapping';
import base64 from 'core/utils/base64';
import cxComponentMapper from 'cx/module/custom-content/mapper/component';
import paramsMapper from 'cx/module/custom-content/mapper/params';

function encodeContent(content) {
    return base64.encode(content);
}

function mapComponentTranslationsToRest(translations, content) {
    if (!translations) {
        return translations;
    }

    translations.forEach((translation) => {
        translation.componentContent = encodeContent(content);
    });

    return translations;
}

export default {
    mapComponentsFromRest: cxComponentMapper.mapComponentsFromRest,

    mapComponentFromRest: cxComponentMapper.mapComponentFromRest,

    mapComponentsToRest(componentModels) {
        return componentModels.map(componentModel => this.mapComponentToRest(componentModel));
    },

    mapComponentToRest(componentModel) {
        const {
            id,
            order,
            content,
            params,
        } = mapping.toJS(componentModel);

        return {
            elementNumber: id,
            elementOrder: order,
            componentContent: encodeContent(content),
            componentParams: paramsMapper.mapParamsToRest(params),
            componentTranslations: componentModel.translations,
        };
    },

    mapComponentUpdateToRest(componentModel) {
        const { content, params, translations } = mapping.toJS(componentModel);

        return {
            componentContent: encodeContent(content),
            componentParams: paramsMapper.mapParamsToRest(params),
            componentTranslations: mapComponentTranslationsToRest(translations, content),
        };
    },

    mapComponentParamsUpdateToRest(componentModel) {
        const { params } = mapping.toJS(componentModel);

        return {
            componentParams: paramsMapper.mapParamsToRest(params),
        };
    },

    mapComponentOrderUpdateToRest(componentModel) {
        const { id, order } = mapping.toJS(componentModel);

        return {
            elementNumber: id,
            elementOrder: order,
        };
    },
};
