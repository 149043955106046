import { notReachable } from 'app/types/utils';

export const STYLES_SEPARATOR = '\r\n';

type StyleField =
    | 'color'
    | 'fontFamily'
    | 'fontSize'
    | 'fontStyle'
    | 'fontWeight'
    | 'backgroundColor'
    | 'borderColor'
    | 'borderRadius'
    | 'borderWidth'
    | 'display'
    | 'maxWidth'
    | 'width'
    | 'maxHeight'
    | 'height'
    | 'textAlign'
    | 'lineHeight'
    | 'margin'
    | 'padding'
    | 'alignment'
    | 'background';

export const getStyle = (field: StyleField, value: string): string => {
    switch (field) {
        case 'color':
            return `color: ${value};`;

        case 'fontFamily':
            return `font-family: ${value};`;

        case 'fontSize':
            return `font-size: ${value};`;

        case 'fontStyle':
            return `font-style: ${value};`;

        case 'fontWeight':
            return `font-weight: ${value};`;

        case 'backgroundColor':
            return `background-color: ${value};`;

        case 'background':
            return `background: ${value};`;

        case 'borderColor':
            return `border-color: ${value};`;

        case 'borderRadius':
            return `border-radius: ${value}px;`;

        case 'borderWidth':
            return `border-width: ${value}px; border-style: solid;`;

        case 'maxWidth':
            return `max-width: ${value};`;

        case 'width':
            return `width: ${value};`;

        case 'display':
            return `display: ${value};`;

        case 'maxHeight':
            return `max-height: ${value};`;

        case 'height':
            return `height: ${value};`;

        case 'textAlign':
            return `text-align: ${value};`;

        case 'lineHeight':
            return `line-height: ${value}`;

        case 'margin':
            return `margin: ${value};`;

        case 'padding':
            return `padding: ${value};`;

        case 'alignment':
            return `flex-direction: column; align-items: ${value};`;

        default:
            return notReachable(field);
    }
};

export const isStyleEmpty = (value: string | null): boolean => {
    return value === null || value.trim().length === 0;
};
