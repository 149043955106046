import mapping from 'knockout-mapping';

function mapContextAttributesFromRest(restAttributes) {
    return restAttributes.reduce((attributes, { name, value }) => {
        // Sometimes REST is returning 'null' string. We were told that it is big effort to fix it on REST side...
        attributes[name] = value === 'null' ? '' : value;

        return attributes;
    }, {});
}

function mapContextAttributesToRest(attributes) {
    return Object.keys(attributes).map(name => ({ name, value: attributes[name] || null }));
}

function mapContextRowFromRest({ contextRowId, contextRowLink, attributes }) {
    return {
        id: contextRowId,
        link: contextRowLink,
        attributes: mapContextAttributesFromRest(attributes),
    };
}

function mapContextRowToRest({ id, link, attributes }, rowAction) {
    return {
        contextRowId: id,
        contextRowLink: link,
        rowAction,
        attributes: mapContextAttributesToRest(attributes),
    };
}

function mapContextFromRest({ contextName, contextLink, dataRows }) {
    return {
        name: contextName,
        link: contextLink,
        rows: dataRows.map(mapContextRowFromRest)
            .sort(sortByRowId),
    };
}

function mapContextToRest({ name, link, rows, removedRows }) {
    const dataRows = [
        ...rows.map(row => mapContextRowToRest(row, row.id ? 'Update' : 'Create')),
        ...removedRows.map(row => mapContextRowToRest(row, 'Delete')),
    ];

    return {
        contextName: name,
        contextLink: link,
        dataRows,
    };
}

function filterEmptyContexts({ rows, removedRows }) {
    return [...rows, ...removedRows].length;
}

function sortByRowId(row1, row2) {
    return Number(row1.id) - Number(row2.id);
}

export function mapExtraInformationFromRest(candidateExtraInformation) {
    const [candidateContext] = candidateExtraInformation.items;

    if (!candidateContext) {
        return null;
    }

    return mapContextFromRest(candidateContext);
}

export function mapExtraInformationToRest(candidateNumber, extraInformationModel) {
    const extraInformation = mapping.toJS(extraInformationModel);

    return extraInformation.filter(filterEmptyContexts)
        .map(context => ({
            candidateNumber,
            candidateExtraInformation: [mapContextToRest(context)],
            action: 'updateEFF',
        }));
}