import { observable, pureComputed } from 'knockout';
import i18n from 'core/i18n/i18n';
import router from 'app/model/router';
import interviewSchedulingService from '../../service/interviewScheduling';
import interviewSchedulingEvents from '../../config/events';
import notificationsService from 'cx/service/notifications';

export default class SelfScheduleConfirmDialogViewModel {

    constructor({ submissionId, requestId }) {
        this.submissionId = submissionId;
        this.interviewModel = observable();
        this.isVisible = observable(false);
        this.isScheduleConfirmDialogProgress = observable(false);
        this.requestId = requestId;

        this.action = null;

        this.isScheduled = pureComputed(() => {
            if (!this.interviewModel()) {
                return false;
            }

            switch (this.interviewModel().action) {
                case 'SCHEDULE':
                    return false;
                case 'RESCHEDULE':
                    return true;
                default: // Support legacy interview requests.
                    return Boolean(this.requestId());
            }
        });

        this.buttonLabel = pureComputed(() => {
            if (this.isScheduled()) {
                return 'interview-scheduling.interview-detail.buttons.reschedule-interview';
            }

            return 'interview-scheduling.schedule-confirm.buttons.schedule-interview';
        });

        this._scheduleConfirmDialogShowSignal =
            interviewSchedulingEvents.showConfirmDialog.add(({ interviewModel }) => {
                this.interviewModel(interviewModel);
                this.isVisible(true);
            });
    }

    closeDialog() {
        this.isVisible(false);
    }

    confirm() {
        this.isScheduleConfirmDialogProgress(true);

        const { interviewId } = this.interviewModel();

        if (this.interviewModel().autoCalendarSlot) {
            this.action = this.isScheduled()
                ? interviewSchedulingService.reScheduleAutoSlotInterview({
                    requestId: this.requestId(),
                    submissionId: this.submissionId(),
                    startDate: this.interviewModel().startTimeUTC(),
                    endDate: this.interviewModel().endTimeUTC(),
                })
                : interviewSchedulingService.scheduleAutoSlotInterview({
                    requestId: this.requestId(),
                    scheduleId: this.interviewModel().scheduleId(),
                    submissionId: this.submissionId(),
                    startDate: this.interviewModel().startTimeUTC(),
                    endDate: this.interviewModel().endTimeUTC(),
                });
        } else {
            this.action = this.isScheduled()
                ? interviewSchedulingService.reScheduleInterview(this.requestId(), interviewId)
                : interviewSchedulingService.scheduleInterview(this.requestId(), interviewId, this.submissionId());
        }

        return this.action
            .then((value) => {
                if (value.message === 'SCHEDULED' || value.message === 'RESCHEDULED') {
                    notificationsService.success(i18n('interview-scheduling.self-schedule.messages.schedule-success'));

                    router.go('interview-detail', {
                        id: value.interviewId,
                    });
                } else if (value.message === 'INTERVIEW_ALREADY_SCHEDULED') {
                    notificationsService.error(i18n('interview-scheduling.self-schedule.messages.already-scheduled'));
                } else {
                    notificationsService.error(i18n('interview-scheduling.self-schedule.errors.schedule-error'));
                }
            })
            .catch(() => {
                notificationsService.error(i18n('interview-scheduling.self-schedule.errors.schedule-error'));
            })
            .then(() => {
                this.isScheduleConfirmDialogProgress(false);
                this.closeDialog();
            });
    }

    dispose() {
        this._scheduleConfirmDialogShowSignal.detach();
    }

}