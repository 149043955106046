import { observable } from 'knockout';
import i18n from 'core/i18n/i18n';
import notificationsService from 'cx/service/notifications';
import tokenService from 'candidate-verification/service/token';
import CandidateVerificationAbstractViewModel from 'candidate-verification/component/email-verification/CandidateVerificationAbstractViewModel';
import router from 'app/model/router';
import { FILL_IN, ATTEMPS_LIMIT_REACHED, PHONE_BLOCKED, CHALLENGE_REQUIRED, PIN_LIMIT_REACHED } from 'candidate-verification/config/pinVerificationStatus';
import { CSS_ENTRY } from 'candidate-verification/config/verificationSubmodules';
import cssEvents from 'candidate-self-service/config/events';
import tokenVerification from 'candidate-verification/component/email-verification/service/tokenVerification';
import lastNameFormBuilder from 'candidate-verification/component/email-verification/service/lastNameFormBuilder';
import dateOfBirthFormBuilder from 'candidate-verification/component/email-verification/service/dateOfBirthFormBuilder';
import { LAST_NAME, DATE_OF_BIRTH } from 'app/module/cx/module/candidate-verification/config/claimVerificationChecks';
import { INTERNAL_CANDIDATE, INVALID_COOKIE, NO_PROFILE } from 'candidate-verification/config/candidateVerificationStatus';
import { SMS, EMAIL } from 'candidate-verification/config/verificationMethods';
import userTracking from 'app/module/cx/service/userTracking';
import { removeLastName, saveLastName } from 'cx/module/candidate-verification/service/lastNameStorage';
import { removeDateOfBirth, saveDateOfBirth } from 'cx/module/candidate-verification/service/dateOfBirthStorage';

const PHONE_BLOCKED_MESSAGE = i18n('apply-flow.candidate-verification.cant-use-this-phone');

const NO_PROFILE_MESSAGES = {
    [SMS]: 'apply-flow.candidate-verification.no-profile-phone',
    [EMAIL]: 'apply-flow.candidate-verification.no-profile-email',
};

export default class CsConfirmDialogViewModel extends CandidateVerificationAbstractViewModel {

    constructor() {
        super();

        this.isDialogVisible = observable(false);
        this.refresh = observable(false);
        this.isVerificationInProgress = observable(false);
        this.isLoading = observable(false);
        this.pinCodeRequired = observable(false);
        this.challenge.submodule = CSS_ENTRY;

        this._openSignInDialogHandler = this._openSignInDialogHandler.bind(this);
        this.routerSubscription = router.route.subscribe(this._openDialogIfDeepLinkActive, this);
        this._registerOpenDialogEvent();
        this._openDialogIfDeepLinkActive();
        this.lastName = observable();
        this.lastNameForm = lastNameFormBuilder.createForm(this.lastName);
        this.lastNameNotMatched = observable(false);
        this.showLastNameMismatchMessage = observable(false);
        this.showClaimMessage = false;
        this.isLastNameCheck = observable(false);

        this.dateOfBirth = observable();
        this.dateOfBirthForm = dateOfBirthFormBuilder.createForm(this.dateOfBirth);
        this.dateOfBirthNotMatched = observable(false);
        this.showDateOfBirthMismatchMessage = observable(false);
        this.isDateOfBirthCheck = observable(false);

        this.onClose = this._resetState.bind(this);
    }

    _closeDialog() {
        this.isDialogVisible(false);
        this._resetState();
    }

    next() {
        userTracking.trackJobAppEntryNext(router.routeParams().jobId, router.route().id);

        const form = this.activeForm();

        form.enableImmediateValidation();

        form.validate()
            .then((isFormValid) => {
                if (!isFormValid) {
                    return;
                }

                this.beforeCaptchaVerify();
            });
    }

    verify() {
        this.isVerificationInProgress(true);

        const params = {
            candidate: this.candidate,
            submodule: this.challenge.submodule,
            captchaToken: this.captchaToken(),
            honeyPot: this.honeyPot(),
        };

        tokenService.verifyCandidate(params)
            .then((verificationToken) => {
                if (!verificationToken.cookieMatchedFlag) {
                    if (verificationToken.nextField === DATE_OF_BIRTH) {
                        this.dateOfBirthNotMatched(true);
                        this.lastNameNotMatched(false);
                        this.isDateOfBirthCheck(true);
                    } else if (!verificationToken.challengeFlag || verificationToken.nextField === LAST_NAME) {
                        this.lastNameNotMatched(true);
                        this.dateOfBirthNotMatched(false);
                        this.isLastNameCheck(true);
                    }

                    this.captchaToken(verificationToken.captchaToken);
                } else {
                    this._showPinChallenge();
                }

                this.refresh(true);
            })
            .catch(this._handleError.bind(this));
    }

    verifyLastName() {
        this.lastNameForm.enableImmediateValidation();

        this.lastNameForm.validate()
            .then((isFormValid) => {
                if (!isFormValid) {
                    return;
                }

                this.isLoading(true);
                saveLastName(this.lastName());

                tokenVerification.verifyLastName({
                    jobId: this.jobId,
                    candidate: this.candidate,
                    submodule: this.challenge.submodule,
                    captchaToken: this.captchaToken() || null,
                    lastName: this.lastName(),
                })
                    .catch(this._handleError.bind(this));
            });
    }

    verifyDateOfBirth() {
        this.dateOfBirthForm.enableImmediateValidation();

        this.dateOfBirthForm.validate()
            .then((isFormValid) => {
                if (!isFormValid) {
                    return;
                }

                this.isLoading(true);
                saveDateOfBirth(this.dateOfBirth());

                tokenVerification.verifyDateOfBirth({
                    jobId: this.jobId,
                    candidate: this.candidate,
                    submodule: this.challenge.submodule,
                    captchaToken: this.captchaToken() || null,
                    dateOfBirth: this.dateOfBirth(),
                }).catch(this._handleError.bind(this));
            });
    }

    redirectBackToEmailVerification() {
        this._resetState();
    }

    _resetState() {
        this.isVerificationInProgress(false);
        this.lastNameNotMatched(false);
        this.lastName(null);
        this.showLastNameMismatchMessage(false);
        this.isLastNameCheck(false);
        this.dateOfBirthNotMatched(false);
        this.dateOfBirth(null);
        this.showDateOfBirthMismatchMessage(false);
        this.isDateOfBirthCheck(false);
    }

    _handleError(error) {
        this.isVerificationInProgress(false);
        this.isLoading(false);

        if (error !== CHALLENGE_REQUIRED) {
            removeLastName();
            removeDateOfBirth();
        }

        if (error === INTERNAL_CANDIDATE) {
            return;
        }

        if (error === ATTEMPS_LIMIT_REACHED || error === PIN_LIMIT_REACHED) {
            this.verificationStatus(ATTEMPS_LIMIT_REACHED);
            this._showPinChallenge();
        } else if (error === PHONE_BLOCKED) {
            notificationsService.error(PHONE_BLOCKED_MESSAGE, 0);
        } else if (error === INVALID_COOKIE) {
            if (this.dateOfBirth()) {
                this.showDateOfBirthMismatchMessage(true);
            } else {
                this.showLastNameMismatchMessage(true);
            }
        } else if (error === CHALLENGE_REQUIRED) {
            this.lastNameNotMatched(false);
            this.dateOfBirthNotMatched(false);
            this._showPinChallenge();
        } else if (error === NO_PROFILE) {
            this._showNoProfileNotification();
        } else {
            this._closeDialog();
            notificationsService.error();
            console.error(error);
        }
    }

    _showNoProfileNotification() {
        const errorMessage = i18n(NO_PROFILE_MESSAGES[this.candidate.verificationMethod()]);

        notificationsService.error(errorMessage, 0);
    }

    _showPinChallenge() {
        this.pinCodeRequired(true);
    }

    onPinValid() {
        this.pinCodeRequired(false);
        this._closeDialog();

        router.go('candidate-self-service');
    }

    onAttempsLimitReached() {
        this.pinCodeRequired(false);
        this._closeDialog();
        this.verificationStatus(FILL_IN);

        return router.go('home-page');
    }

    dispose() {
        cssEvents.openSignInDialog.remove(this._openSignInDialogHandler);
        this.routerSubscription.dispose();
    }

    _openDialogIfDeepLinkActive() {
        if (router.isActive('candidate-self-service.sign-in')) {
            setTimeout(
                () => this.isDialogVisible(true),
                100,
            );
        }
    }

    _registerOpenDialogEvent() {
        cssEvents.openSignInDialog.add(this._openSignInDialogHandler);
    }

    _openSignInDialogHandler() {
        this.isDialogVisible(true);
    }

}
