import http from 'core/http/http';
import profileImportRestMapper from '../mapper/profileImportRestMapper';
import profileImportConverterVerCan from '../mapper/profileImportConverterVerCan';
import awliProfileImportConverter from '../mapper/awliProfileImportConverter';
import flowService from 'apply-flow/service/flow';
import profileImportEvents from '../config/events';
import { applicationDraft } from 'apply-flow/service/applicationDraftInstance';
import fileAttachment from 'apply-flow/module/file-upload/mapper/fileAttachment';
import draftAttachmentService from 'apply-flow/module/file-upload/service/draftAttachmentService';
import storageService from 'core/storage/sessionStorage';
import i18n from 'core/i18n/i18n';
import candidateModel from 'apply-flow/model/candidate';
import mapping from 'knockout-mapping';
import sectionValidator from 'apply-flow/model/sectionValidator';
import applyFlowEvents from 'apply-flow/config/events';
import accessibilityEvents from 'apply-flow/component/apply-flow-accessibility-messages/config/events';
import { EMAIL, SMS } from 'candidate-verification/config/verificationMethods';
import applicationDraftService from 'apply-flow/service/applicationDraft';
import { isCWKAndCWKAddressUpdateDisabled } from 'apply-flow/service/cwkAddressCheck';
import { addressBackupInstance } from '../../../mapper/addressBackup';
import PhoneNumberFormatterForUS from 'cx/service/phone/PhoneNumberFormatterForUS';

const IN_PROFILE_KEY = 'inProfile';
const NANP_CALL_PREFIX = '1';

async function _addAttachmentToProfile(profileCollection, attachment, isReferral) {
    const [candidateProfile] = profileCollection.items;

    const attachmentToAdd = applicationDraft
        ? await _copyOrAddAttachmentToDraft(attachment, isReferral)
        : attachment;

    candidateProfile.attachments.push(attachmentToAdd);

    return profileCollection;
}

function _getProfile(params, oauthToken, flowVersionId) {
    const flow = flowService.get();
    const flowVersionIdFromFlow = flowVersionId || flow?.flowVersionId;
    const finderParams = Object.assign(params, { bFlowVersionId: flowVersionIdFromFlow });
    const limit = 100;

    if (finderParams.bProfileType === 'rp') {
        if (finderParams.bAttachmentId) {
            return http.get('/recruitingCECandidateResumeParsers?expand=all&onlyData=true&limit=:limit:&finder=findByProfileType;:finderParams:', {
                params: {
                    finderParams,
                    limit,
                },
                paramsDelimiter: ',',
                credentials: 'same-origin',
            });
        } else if (finderParams.bAttachmentData) {
            return http.post('/recruitingCECandidateResumeParsers', profileImportRestMapper.mapResumeParserInputToRest(finderParams.bAttachmentData, flow.flowVersionId))
                .then(response => profileImportRestMapper.flattenResumeParserResponseToProfile(response));
        }
    }

    const headers = {};

    if (oauthToken) {
        Object.assign(headers, { 'Ora-Irc-Oauth-Token': oauthToken });
    }

    return http.get('/recruitingCECandidateProfileImports?expand=all&onlyData=true&limit=:limit:&finder=findByProfileType;:finderParams:', {
        params: {
            finderParams,
            limit,
        },
        headers,
        paramsDelimiter: ',',
        credentials: 'same-origin',
    });
}

function _parseReferredResume(attachedDocumentId) {
    const config = {
        bProfileType: 'rp',
        bAttachmentId: attachedDocumentId,
    };

    return _getProfile(config);
}

function _parseResume(attachmentData) {
    const config = {
        bProfileType: 'rp',
        bAttachmentData: attachmentData,
    };

    return _getProfile(config);
}

async function _copyOrAddAttachmentToDraft(attachmentData, isReferral) {
    const mappedFileAttachment = fileAttachment.mapFileAttachmentFromRest(attachmentData);

    const draftAttachment = isReferral
        ? await draftAttachmentService.copyAttachmentToDraft(applicationDraft.id, mappedFileAttachment)
        : await draftAttachmentService.create(applicationDraft.id, mappedFileAttachment, null);

    return {
        ...attachmentData,
        id: draftAttachment.id(),
        appdraftId: draftAttachment.appDraftId(),
    };
}


function _logPIStats(profileData) {
    let statusCode = 200;

    if (!profileData || (!profileData.emailAddress && !profileData.firstName
         && !profileData.lastName && !profileData.phoneNumber)) {
        statusCode = 400;
    }

    const payLoad = { StatusCode: statusCode };

    http.post('/recruitingCECandidateProfileImports', payLoad);
}

export default {
    _startHandler(profileData) {
        profileImportEvents.awliClicked.dispatch();
        profileImportEvents.awliProfileImportStarted.dispatch(profileData);
    },

    getAwliProfile(profileData) {
        _logPIStats(profileData);
        this._startHandler(profileData);
    },

    importAwliProfile(awliProfileData) {
        return awliProfileImportConverter.getRestData(awliProfileData);
    },

    getProfile(params, oauthToken) {
        const profileConverter = profileImportConverterVerCan.getInstance();

        return _getProfile(params, oauthToken).then(restResponse => profileConverter.toLocalModel(restResponse));
    },

    getProfileFromIndeed(params, oauthToken, flowVersionId) {
        return _getProfile(params, oauthToken, flowVersionId).then((response) => {
            storageService.store(IN_PROFILE_KEY, response);

            return response;
        });
    },

    getIndeedProfileFromCache() {
        return storageService.restore(IN_PROFILE_KEY);
    },

    clearIndeedProfileFromCache() {
        storageService.remove(IN_PROFILE_KEY);
    },

    async parseAttachment(attachmentData) {
        const profileConverter = profileImportConverterVerCan.getInstance();

        const profileCollection = await _parseResume(attachmentData);

        const attachment = profileConverter.toLocalAttachment(attachmentData);

        await _addAttachmentToProfile(profileCollection, attachment, false);

        return profileConverter.toLocalModel(profileCollection);
    },

    async parseReferrerAttachment(attachmentData) {
        const profileConverter = profileImportConverterVerCan.getInstance();
        const attachment = profileConverter.toLocalReferralAttachment(attachmentData);

        const profileCollection = await _parseReferredResume(attachment.sourceAttachmentId);

        await _addAttachmentToProfile(profileCollection, attachment, true);

        return profileConverter.toLocalModel(profileCollection);
    },

    async fillApplyFlow(candidateRest, profileImportType) {
        applyFlowEvents.profileImportLoading.dispatch();

        const phoneNumberData = candidateRest?.basicInformation?.phone;

        if (phoneNumberData.areaCode()
            || phoneNumberData.number()
        ) {
            if (phoneNumberData.countryCode() === NANP_CALL_PREFIX
                && !phoneNumberData.areaCode()
            ) {
                const formattedPhoneNumber = new PhoneNumberFormatterForUS({
                    countryCode: phoneNumberData.countryCode(),
                    number: phoneNumberData.number(),
                    legislationCode: phoneNumberData.legislationCode(),
                });

                phoneNumberData.areaCode(formattedPhoneNumber.areaCode);
                phoneNumberData.number(formattedPhoneNumber.number);
            }

            profileImportEvents.isPhoneImported.dispatch(phoneNumberData);
        }

        if (profileImportType) {
            applicationDraftService.updateDraftSource(profileImportType);
        }

        const workPreferences = candidateModel.workPreferences();

        candidateModel.clearAfterImport();

        removeUnwantedFieldsFromImport(candidateRest);
        removeFieldsToProtectCommunicationChannel(candidateRest.basicInformation);
        removeAddressFieldsCWK(candidateRest);

        mapping.fromJS(candidateRest, candidateModel);

        if (candidateModel.workPreferences().length === 0) {
            candidateModel.workPreferences.push(...workPreferences);
        }

        await sectionValidator.validateAfterImport();

        const sectionErrorMessages = sectionValidator.getAllSectionErrorMessages().join(' ');
        const errorsAlert = i18n('apply-flow.profile-import.success-label') + sectionErrorMessages;

        setTimeout(() => {
            accessibilityEvents.addAlert.dispatch(errorsAlert);
            applyFlowEvents.profileImportSucceed.dispatch();

            profileImportEvents.indeedProfileImportEnded.dispatch({
                status: 'success',
            });
        }, 500);

        function removeUnwantedFieldsFromImport(model) {
            delete model.id;
        }

        function removeAddressFieldsCWK(model) {
            if (isCWKAndCWKAddressUpdateDisabled(addressBackupInstance.get())) {
                delete model.address;
            }
        }

        function removeFieldsToProtectCommunicationChannel(source) {
            const { basicInformation } = candidateModel;

            if (basicInformation.verificationMethod() === EMAIL
                || basicInformation.emailVerifiedFlag()) {
                delete source.email;
            }

            if (basicInformation.verificationMethod() === SMS
            || basicInformation.phoneVerifiedFlag()) {
                delete source.phone;
                delete source.phoneNumber;
            }
        }
    },
};
