import CandidateChallengeAbstractViewModel from 'app/module/cx/module/candidate-verification/component/challenge-layout/CandidateChallengeAbstractViewModel';
import router from 'app/model/router';
import applyFlowEvents from 'apply-flow/config/events';
import submitChecklist from 'apply-flow/service/submit-checklist/submitChecklist';
import tokenService from 'candidate-verification/service/token';
import unverifiedService from 'candidate-verification/service/unverified';
import scrollKeeper from 'minimal/service/scrollKeeper';
import { clearCandidate } from 'apply-flow/component/apply-flow-summary/service/candidateSummary';
import { INVALID_ESIGNATURE } from 'apply-flow/service/application';
import candidate from 'apply-flow/model/candidate';
import notificationsService from 'cx/service/notifications';
import i18n from 'core/i18n/i18n';
import {
    ATTACHMENT_MIME_TYPE_ERROR,
    ATTACHMENT_UPLOAD_ERROR,
} from 'apply-flow/module/file-upload/config/attachmentErrorCodes';
import profileItemErrorHandler from 'apply-flow/module/profile-items/service/errorHandler';
import questionnairesMapper from 'apply-flow/module/questionnaire/mapper/allQuestionnaires';
import { EVENT_REGISTER } from 'candidate-verification/config/verificationSubmodules';
import {
    EventRegisterFlowModel,
    EventRegisterModel,
    EventRegistrationCreationResponse,
    RouterParams,
} from '../../config/types';
import { saveCandidateAndRegisterEvent, confirmEventRegistration } from '../../service/eventRegistration';
import appConfig from 'app/model/config';
import { observable, Observable } from 'knockout';
import { cachedCandidateRegistrations } from 'app/module/cx/module/apply-flow/service/cachedCandidateRegistrations';
import Questionnaire from 'app/module/cx/module/apply-flow/module/questionnaire/model/Questionnaire';
import { EventActionResponse } from '../../../candidate-self-service/config/types';

const TEMPLATES = ['already-applied', 'candidate-exists', 'token-expired-apply-flow'];

type ValidationError = {
    validationError: string;
};

const REGISTRATION_NOT_AVAILABLE = 'IRC_EVENT_NOT_ACCEPTING_REGN';
const ERR_EVENT_CANCELLED = 'EVENT_CANCELLED';
const CANDIDATE_DISQUALIFIED = 'CE_CANDIDATE_DISQUALIFIED';
const PROFILE_REDIRECTION_DIALOG_DELAY = 5000;

export default class EventRegisterSummaryViewModel extends CandidateChallengeAbstractViewModel {
    private template: Observable<string>;
    private registrationFlowModel: Observable<EventRegisterFlowModel>;
    private eventRegisterationData: EventRegisterModel;
    private submitHandler: VoidFunction;
    private isCandidateDisqualifiedForThisEvent = false;
    private confirmEventRegistrationErrorOccured = false;
    private eventNotAcceptingRegistrationErrorOccured = false;
    private showProfileRedirectionDialog = observable(false);
    private disqualifiedMsgKey = 'event-register-flow.submit-errors.disqualified';
    private manualConfirmationMsgKey = 'event-register-flow.thank-you-dialog.manual-confirmation-message';
    private isEventRequireManualConfirmation = false;
    private profileRedirectionDialogMessage = observable('');

    constructor({
        template,
        registrationFlowModelParam,
    }: {
        template: Observable<string>;
        registrationFlowModelParam: Observable<EventRegisterFlowModel>;
    }) {
        super();
        this.template = template;
        this.registrationFlowModel = registrationFlowModelParam;

        this.eventRegisterationData = {
            candidateNumber: candidate.id(),
            eventNumber: (router.routeParams() as RouterParams).eventId,
            action: 'CREATE',
            siteNumber: appConfig.siteNumber,
        };

        this.confirmEventRegistrationErrorOccured = false;
        this.eventNotAcceptingRegistrationErrorOccured = false;
        this.candidate.email = candidate.basicInformation.email;
        this.candidate.phone = candidate.basicInformation.phoneNumber;
        this.candidate.verificationMethod = candidate.basicInformation.verificationMethod;
        this.challenge.submodule = EVENT_REGISTER;
        this.submitHandler = () => this.registerEvent();
        applyFlowEvents.submit.add(this.submitHandler);
    }

    private registerEvent(): Promise<void> {
        submitChecklist.reset();
        submitChecklist.show();
        this.eventRegisterationData.candidateNumber = candidate.id();

        const questionnaires: Questionnaire[] = candidate.questionnaires();

        if (questionnaires?.length) {
            this.eventRegisterationData.RecruitingEventQuestionnaireResponses =
                questionnairesMapper.mapAllQuestionnairesToRest(questionnaires);
        }

        this.eventRegisterationData.flowVersionId = this.registrationFlowModel()?.flowVersionId;

        this.eventRegisterationData.legalDescriptionVersionId =
            this.registrationFlowModel()?.legalDisclaimer?.versionId;

        return saveCandidateAndRegisterEvent(this.eventRegisterationData)
            .then((response: EventRegistrationCreationResponse) => {
                this.isCandidateDisqualifiedForThisEvent = response.isCandidateDisqualified;

                return this.onCandidateSubmitted();
            })
            .catch(this.manageEventRegistrationErrors.bind(this))
            .finally(submitChecklist.hide);
    }

    private async onCandidateSubmitted(): Promise<void> {
        if (!tokenService.isKnownCandidate()) {
            return this.showPinVerification();
        }

        return this.postProcessApplication();
    }

    private showPinVerification(): Promise<void> {
        applyFlowEvents.submitSucceed.dispatch();

        submitChecklist.hide();
        this.resetScroll();

        this.template('pin-code-required');

        return Promise.resolve();
    }

    async onPinValid(): Promise<void> {
        // overridden
        return this.postProcessApplication();
    }

    private async postProcessApplication(): Promise<void> {
        return this.triggerEventRegistrationConfirmation()
            .then((response: EventActionResponse) => {
                cachedCandidateRegistrations.clear();
                this.isEventRequireManualConfirmation = response.isManualConfirmationRequired;
            })
            .catch(this.onConfirmApplicationError.bind(this))
            .finally(this.afterCandidateVerification.bind(this));
    }

    private triggerEventRegistrationConfirmation(): Promise<EventActionResponse> {
        if (this.isCandidateDisqualifiedForThisEvent) {
            return Promise.reject(CANDIDATE_DISQUALIFIED);
        }

        return confirmEventRegistration(this.eventRegisterationData);
    }

    private onConfirmApplicationError(error: string): Promise<void> {
        if (error === REGISTRATION_NOT_AVAILABLE || error === ERR_EVENT_CANCELLED) {
            this.eventNotAcceptingRegistrationErrorOccured = true;
        } else if (error === CANDIDATE_DISQUALIFIED) {
            this.isCandidateDisqualifiedForThisEvent = true;
        } else {
            this.confirmEventRegistrationErrorOccured = true;
        }

        console.error(error);

        return Promise.resolve();
    }

    private afterCandidateVerification(): void {
        if (this.isCandidateDisqualifiedForThisEvent || this.isEventRequireManualConfirmation) {
            const dialogText = this.isCandidateDisqualifiedForThisEvent
                ? i18n(this.disqualifiedMsgKey)
                : i18n(this.manualConfirmationMsgKey);

            this.profileRedirectionDialogMessage(dialogText);
            this.showProfileRedirectionDialog(true);

            setTimeout(() => {
                this.showProfileRedirectionDialog(false);
                this.redirectToCSS();
            }, PROFILE_REDIRECTION_DIALOG_DELAY);
        } else {
            this.redirectToCSS();
        }
    }

    private redirectToCSS(): void {
        candidate.clear();
        unverifiedService.destroy();

        router
            .go('candidate-self-service.events', {}, { inherit: false })
            .then(this.notifyAfterRedirectToCSS.bind(this));

        applyFlowEvents.submitSucceed.dispatch();
    }

    private notifyAfterRedirectToCSS(): void {
        if (this.eventNotAcceptingRegistrationErrorOccured) {
            notificationsService.errorAfterLoaded(
                i18n('event-register-flow.submit-errors.event-reg-closed'),
                500
            );

            return;
        }

        if (this.confirmEventRegistrationErrorOccured) {
            // TODO replace the below hardcoding with actual event title on taking up relevant story
            notificationsService.errorAfterLoaded(
                i18n('candidate-self-service.confirm-your-event-registration', {
                    EVENT_TITLE: /*this.job.title*/ 'Sample Event Title',
                }),
                500
            );

            return;
        }

        if (this.isCandidateDisqualifiedForThisEvent) {
            notificationsService.errorAfterLoaded(
                i18n('event-register-flow.submit-errors.not-processed'),
                500
            );

            return;
        }

        const THANK_YOU_MESSAGE = this.isEventRequireManualConfirmation
            ? i18n('candidate-self-service.events.interest-thank-you-message')
            : i18n('event-register-flow.thank-you-dialog.success.message');

        notificationsService.successAfterLoaded(THANK_YOU_MESSAGE);
    }

    expiredCandidateRedirection(): Promise<void> {
        // TODO correct this with event related screens
        const route = 'job-details.confirm-email';

        unverifiedService.setUnverifiedVerificationData();
        tokenService.destroy();

        return router.go(route);
    }

    private async manageEventRegistrationErrors(error: string | ValidationError): Promise<void> {
        clearCandidate();
        console.error(error);
        applyFlowEvents.refresh.dispatch();

        if (typeof error === 'string' && TEMPLATES.indexOf(error) > -1) {
            this.template(error);
            applyFlowEvents.submitFailed.dispatch();
        } else if (error === INVALID_ESIGNATURE) {
            applyFlowEvents.invalidESignature.dispatch();
        } else if (error === REGISTRATION_NOT_AVAILABLE || error === ERR_EVENT_CANCELLED) {
            notificationsService.error(i18n('event-register-flow.submit-errors.event-reg-closed'), 0);
        } else if (error === ATTACHMENT_UPLOAD_ERROR) {
            notificationsService.error(i18n('event.register-flow.submit-errors.file-upload'), 0);
        } else if (error === ATTACHMENT_MIME_TYPE_ERROR) {
            notificationsService.error(i18n('validators.file-upload.mime-type-error'), 0);
        } else if (profileItemErrorHandler.isValidationError(error)) {
            profileItemErrorHandler.handleValidationError(error as ValidationError);
        } else {
            // REST APIs are designed to send the error message instead of error code.
            // Hence instead of mapping the code to message in client side, showing the server message as it is
            notificationsService.error(error as string, 0);
        }
    }

    private resetScroll(): void {
        scrollKeeper.scrollTo(0);
    }

    dispose(): void {
        applyFlowEvents.submit.remove(this.submitHandler);
    }
}
