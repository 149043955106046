import http from 'core/http/http';
import appConfig from 'app/model/config';

const TRANSLATIONS = {};

function hasTranslationsForLang(language) {
    return Boolean(TRANSLATIONS[language]);
}

function updateBundleWith(newTranslations) {
    Object.assign(TRANSLATIONS, newTranslations);
}

function fetch(languages) {
    const fetchCalls = languages.map(language => http.get(`/translations?language=${language}`, {
        baseUrl: appConfig.ceBaseUrl,
        transformResponse: response => response,
    }));

    return Promise.all(fetchCalls);
}

async function ensure(languages) {
    const languagesToFetch = languages
        .filter(language => !hasTranslationsForLang(language));

    if (!languagesToFetch.length) {
        return;
    }

    try {
        const translations = await fetch(languagesToFetch);

        translations.forEach(updateBundleWith);
    } catch (error) {
        console.error(error);
    }
}

async function init() {
    const languages = [
        ...getLanguagesFromAppConfig(),
        getLanguageFromHTMLTag(),
    ]
        .filter(Boolean);

    const uniqueLanguages = [...new Set(languages)];

    return await ensure(uniqueLanguages);
}

function getLanguageFromHTMLTag() {
    return document.documentElement.lang;
}

function getLanguagesFromAppConfig() {
    return appConfig.languages
        .map(({ code }) => code);
}

function getResource(combinedKey, lang) {
    const keys = combinedKey
        .toLowerCase()
        .split('.');

    return keys.reduce((resource, key) => resource && resource[key], TRANSLATIONS[lang]);
}

export default {
    TRANSLATIONS,
    init,
    ensure,
    getResource,
};

