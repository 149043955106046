import ko from 'knockout';
import Route from 'core/router/Route';
import PageTitle from 'core/router/PageTitle';
import urlEncoder from 'core/router/urlEncoder';

// polyfill for browsers not supporting document.baseURI
if (!('baseURI' in document)) {
    document.baseURI = (() => {
        const [baseTag] = document.getElementsByTagName('base');

        if (!baseTag) {
            console.error('<base> tag in <head> section is missing.');

            return undefined;
        }

        return baseTag.href;
    })();
}

export default class Router {

    constructor(name) {
        this.name = name;

        this._routes = [];
        this._defaultRoute = Route.createEmpty();
        this._pageTitle = new PageTitle();

        this.route = ko.observable(this._defaultRoute);
        this.routeParams = ko.observable({});
    }

    configure(routes) {
        Object.keys(routes).forEach((routeId) => {
            const routeConfig = routes[routeId];
            const route = this._createRoute(routeId, routeConfig);

            this.addRoute(route);
        });
    }

    _createRoute(routeId, routeConfig) {
        const parentRoute = this._resolveParentRoute(routeConfig);

        return new Route(routeId, routeConfig, parentRoute);
    }

    addRoute(route) {
        if (route.isDefault) {
            this._defaultRoute = route;
        }

        this._routes.push(route);
    }

    _resolveParentRoute(routeConfig) {
        if (routeConfig.parent) {
            return this.getRoute(routeConfig.parent);
        }

        return null;
    }

    getRoute(routeId) {
        return ko.utils.arrayFirst(this._routes, route => route.id === routeId);
    }

    _getUrl() {
        const baseUri = document.baseURI.replace(/\/$/, '');

        if (window.location.href.indexOf(baseUri) === -1) {
            throw new Error("Route doesn't match baseURI");
        }

        const baseFreeUrl = window.location.href.replace(baseUri, '');
        const [hashFreeUrl] = baseFreeUrl.split('#');

        return hashFreeUrl;
    }

    _setUrl(url) {
        if (url.indexOf('//') !== -1) {
            window.location.assign(url);
        } else {
            const baseUri = document.baseURI.replace(/\/$/, '');
            const newUrl = `${baseUri}${url}`;

            window.history.pushState({}, '', newUrl);
        }
    }

    sync() {
        window.addEventListener('popstate', this._parseUrl.bind(this));
        this._parseUrl();
    }

    _parseUrl() {
        const url = this._getUrl();
        const newRoute = this._getMatchingRoute(url) || this._defaultRoute;
        const newRouteParams = newRoute.getParamsFromUrl(url);

        return this._transitionTo(newRoute, newRouteParams);
    }

    _getMatchingRoute(url) {
        return ko.utils.arrayFirst(this._routes, route => !route.isAbstract && route.match(url));
    }

    _transitionTo(newRoute, newRouteParams) {
        const currentRoute = this.route();
        const currentRouteParams = this.routeParams();

        return Promise.resolve()
            .then(currentRoute.canExit.bind(currentRoute, currentRouteParams, newRoute.id, newRouteParams))
            .then(newRoute.canEnter.bind(newRoute, newRouteParams, currentRoute.id, currentRoute))
            .then(currentRoute.exit.bind(currentRoute, currentRouteParams, newRoute.id, newRouteParams))
            .then(this._setTitle.bind(this, newRoute))
            .then(newRoute.enter.bind(newRoute, newRouteParams, currentRoute.id, currentRoute))
            .then(() => {
                this._update(newRoute, newRouteParams);

                return Promise.resolve();
            })
            .catch((exception) => {
                if (exception) {
                    if ('redirectTo' in exception) {
                        let params = newRouteParams;

                        if (exception.redirectParams) {
                            params = { ...newRouteParams, ...exception.redirectParams };
                        }

                        this._redirect(exception.redirectTo, params);
                    } else {
                        console.error(`Router Exception:${exception}`);
                    }
                }

                Promise.reject(exception);
            });
    }

    _setTitle(newRoute) {
        this._pageTitle.setRouteTitle(newRoute.title);

        if (newRoute.bareTitle) {
            this._pageTitle.setTitle(newRoute.bareTitle);
        } else {
            this._pageTitle.setTitleWithTemplate();
        }
    }

    _update(newRoute, newRouteParams) {
        let newParams = ko.toJS(newRouteParams);

        newParams = urlEncoder.decode(newParams);

        this.route(newRoute);
        this.routeParams(newParams);
    }

    _redirect(routeId, newRouteParams) {
        this.go(routeId, newRouteParams, {
            encode: false,
        });
    }

    interpolate(routeId, routeParams, routeConfig) {
        const route = this.getRoute(routeId);

        if (!route) {
            return '';
        }

        const params = this._getRouteParams(routeParams, routeConfig);

        return route.interpolate(params);
    }

    _getRouteParams(params = {}, config) {
        let routeParams = params;
        let inheritParams = true;
        let encodeParams = true;

        if (config) {
            if (config.inherit !== undefined) {
                inheritParams = config.inherit;
            }

            if (config.encode !== undefined) {
                encodeParams = config.encode;
            }
        }

        if (inheritParams) {
            const inheritedRouteParams = Object.assign({}, this.routeParams());

            if (inheritedRouteParams.query || routeParams.query) {
                routeParams.query = Object.assign({}, inheritedRouteParams.query, routeParams.query);
            }

            routeParams = Object.assign(inheritedRouteParams, routeParams);
        }

        // TODO: is it necessary to encode here as patterLexer does the same?
        if (encodeParams) {
            const { query, ...pathParams } = ko.toJS(routeParams);

            routeParams = {
                ...urlEncoder.encode(pathParams),
                query: urlEncoder.encodeQueryParams(query),
            };
        }

        return routeParams;
    }

    go(routeId, routeParams, routeConfig) {
        const newRoute = this.getRoute(routeId);

        if (!newRoute) {
            return Promise.reject('Route does not exists');
        }

        const newRouteParams = this._getRouteParams(routeParams, routeConfig);
        const runTransition = !(routeConfig && routeConfig.transition === false);

        return Promise.resolve()
            .then(() => {
                if (runTransition) {
                    return this._transitionTo(newRoute, newRouteParams);
                }

                return this._update(newRoute, newRouteParams);
            })
            .then(() => this._setUrl(decodeURI(newRoute.interpolate(newRouteParams))));
    }

    redirect(url) {
        window.location.href = url;
    }

    isActive(routeId) {
        return this.route().is(routeId);
    }

}
