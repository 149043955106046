import { PureComputed, pureComputed } from 'knockout';
import { EventHeadingConfig } from '../../../types';
import { DistanceUnit, EventShape } from 'cx/module/search/config/types';
import { DistanceInfo } from 'minimal/component/search-results-distance/SearchResultsDistanceViewModel';
import { searchEventResults } from 'app/module/minimal/module/search/model/searchEventResults';
import i18n from 'app/module/core/i18n/i18n';
import router from 'app/model/router';
import { isDistanceOutOfRadius } from 'app/module/cx/module/search/service/distanceCalculation';
import { getDistanceText } from 'minimal/component/search-results-distance/service/distance';

type Props = {
    event: EventShape;
    eventHeadingConfig: PureComputed<EventHeadingConfig>;
};

const DISTANCE_UNITS_NAMES: Record<Exclude<DistanceUnit, null>, string> = {
    MI: i18n('location-bar.dropdown.radius-unit.mi'),
    KM: i18n('location-bar.dropdown.radius-unit.km'),
};

export default class SearchResultItemHeaderViewModel {
    event: EventShape;
    eventHeadingConfig: PureComputed<EventHeadingConfig>;
    hasSubheader: PureComputed<boolean>;
    distanceInfo: DistanceInfo;

    constructor({ event, eventHeadingConfig }: Props) {
        this.event = event;
        this.eventHeadingConfig = eventHeadingConfig;
        this.distanceInfo = this.getDistanceInfo(event.distance);

        this.hasSubheader = pureComputed(() => {
            const { isLocationVisible, isEventFormatVisible, isEventDateVisible } = eventHeadingConfig();

            return isLocationVisible || isEventFormatVisible || isEventDateVisible;
        });
    }

    getDistanceInfo(jobDistance?: string): DistanceInfo {
        const distanceUnit = searchEventResults.distanceUnit as DistanceUnit;
        const distanceUnitName = distanceUnit ? DISTANCE_UNITS_NAMES[distanceUnit] : '';
        const distanceStringKey = 'search.job-item.distance-single-location';
        const inputRadius = this.getInputRadius();
        const isOutOfRadius = Boolean(isDistanceOutOfRadius(inputRadius, jobDistance));
        const distanceText = getDistanceText({
            isDistanceOutOfRadius: isOutOfRadius,
            inputRadius,
            jobDistance,
        });

        return {
            distanceUnit: distanceUnitName,
            distanceStringKey,
            inputRadius,
            isDistanceOutOfRadius: isOutOfRadius,
            distanceText,
        };
    }

    getInputRadius(): string | undefined {
        const routeParams = router.routeParams() as {
            query?: {
                radius: string;
            };
        };

        return routeParams.query?.radius;
    }
}
