import { Observable, observable, PureComputed, pureComputed } from 'knockout';
import themeEvents from 'cx/module/site-editor/config/events';
import appConfig from 'app/model/config';
import router from 'app/model/router';
import { mapTrueFalseStringToBoolean } from 'app/module/core/utils/stringToBoolean';
import customContentService from 'cx/module/custom-content/service/customContent';
import Page from 'cx/module/custom-content/model/Page';
import { CUSTOM_SPLASH_PAGE } from 'cx/module/custom-content/enums/pageTypes';
import { getPageComponentByType } from 'cx/module/custom-content/service/getPageComponents';
import { getSplashBrandingContent } from 'site-editor/module/pages/config/splashBrandingContent';
import Signal from 'signals';
import appEvents from 'app/config/events';
import { openBot } from 'minimal/component/splash-page/service/openBot';
import { ERROR_NO_PAGE_SET } from 'cx/module/custom-content/service/customContentHelper';

type RouterQuery = Record<string, unknown>;
type RouterParams = {
    query: RouterQuery;
};

export default class SplashPageViewModel {
    splashPageCode: string;
    splashPage: Observable<Page | null>;
    isPreview: PureComputed<boolean>;
    signals: Signal.SignalBinding<string>[];
    parallaxEnabled: Observable<boolean>;

    constructor() {
        const { pageCode: splashPageCode } = appConfig.templates[CUSTOM_SPLASH_PAGE] ?? {};

        this.splashPageCode = splashPageCode;
        this.splashPage = observable(null);
        this.signals = [];
        this.parallaxEnabled = observable(this.isParallaxEnabled());

        this.isPreview = pureComputed((): boolean => {
            const { query } = router.routeParams() as RouterParams;

            return Boolean(query?.preview);
        });

        this.addParallaxSignal();
        this.addBotInitializedSignal();
        this.loadPage();
    }

    async loadPage(): Promise<void> {
        try {
            const getMethod = this.isPreview() ? customContentService.getDraft : customContentService.get;
            const page = await getMethod.bind(customContentService)(this.splashPageCode);

            this.splashPage(page);
        } catch (error: string | unknown) {
            this.handleError(error);
        }
    }

    handleError(error: string | unknown): void {
        if (error !== ERROR_NO_PAGE_SET) {
            console.error(error);

            return;
        }

        this.setDefaultPage();
    }

    setDefaultPage(): void {
        const defaultPage = customContentService.getDefaultPage(CUSTOM_SPLASH_PAGE);

        this.splashPage(defaultPage);

        const {
            template: { welcomeText },
        } = appConfig;

        this.setWelcomeText(welcomeText);
        this.addWelcomeTextSignal();
    }

    setWelcomeText(welcomeText: string): void {
        const splashPage = this.splashPage();

        if (!splashPage) {
            return;
        }

        const branding = getPageComponentByType(splashPage.sections, 'cc-paragraph');

        branding.content = welcomeText
            ? getSplashBrandingContent(welcomeText, appConfig.template.templateNumber)
            : '';

        this.splashPage(splashPage);
    }

    addWelcomeTextSignal(): void {
        const welcomeTextUpdatedSubscription = themeEvents.welcomeTextUpdated.add(
            this.setWelcomeText.bind(this)
        );

        this.signals.push(welcomeTextUpdatedSubscription);
    }

    isParallaxEnabled(): boolean {
        const setting = appConfig.getSettingByKey('PARALLAX_ENABLED');

        return mapTrueFalseStringToBoolean(setting);
    }

    addParallaxSignal(): void {
        const themeSettingsUpdatedSignal = themeEvents.themeSettingsUpdated.add(
            ({ parallaxEnabled }: { parallaxEnabled: boolean }) => {
                this.parallaxEnabled(parallaxEnabled);
            }
        );

        this.signals.push(themeSettingsUpdatedSignal);
    }

    addBotInitializedSignal(): void {
        const botInitializedSignal = appEvents.botInitializedSignal.add(openBot);

        this.signals.push(botInitializedSignal);
    }

    dispose(): void {
        this.signals.forEach((subscription) => subscription.detach());
    }
}
