import ko from 'knockout';
import FormValidator from 'core/form/validator/Validator';

export default class MinLengthFormValidator extends FormValidator {

    constructor(...args) {
        super(...args);

        this._message = 'validators.value-min-length';

        ko.utils.extend(this._options, {
            number_of_characters: this._options.min,
        });
    }

    _validate(value, options) {
        return value ? String(value).length >= options.min : true;
    }

}