import { propertyEquals } from 'app/module/core/utils/functional/propertyEquals';
import { AlreadyRegisteredEvents, EventData } from '../config/types';

export function gatherRegistrationDetails(
    mappedEventData: EventData,
    registeredEventList: AlreadyRegisteredEvents[],
    eventNumber: number
): void {
    const event = registeredEventList.find(propertyEquals('id', eventNumber));

    setIsConfirmed(mappedEventData, Boolean(event?.confirmedFlag));
    setIsAlreadyRegistered(mappedEventData, Boolean(event?.id));
    setRegistrationPhase(mappedEventData, event);
}

function setIsConfirmed(mappedEventData: EventData, isConfirmed: boolean) {
    mappedEventData.isConfirmed = isConfirmed;
}

function setIsAlreadyRegistered(mappedEventData: EventData, isAlreadyRegistered: boolean) {
    mappedEventData.isAlreadyRegistered = isAlreadyRegistered;
}

function setRegistrationPhase(
    mappedEventData: EventData,
    eventRegistration: AlreadyRegisteredEvents | undefined
) {
    if (eventRegistration) {
        mappedEventData.registrationInfo = { registrationPhase: eventRegistration.registrationPhase };
    }
}
