import tokenMapper from 'candidate-verification/mapper/token';
import http from 'core/http/http';
import storageService from 'candidate-verification/service/user-session/storage';
import userTracking from 'cx/service/userTracking';
import { getShortToken, storeShortTokenData } from './shortTokenStorage';
import errorHandler from './tokenErrorHandler';
import router from 'app/model/router';
import {
    FLOW_ID, STEP_ACTION_USAGE_ID, SUBMISSION_ID, INTERVIEW_REQUEST_ID, SCHEDULE_ID, INTERVIEW_ID,
    REFERRAL_ID, DRAFT_USER, MERGED_CANDIDATE,
} from 'candidate-verification/enum/consumerAttributes';
import sessionPersistence from './user-session/sessionPersistence';
import { EMAIL } from '../config/verificationMethods';

export const VERIFICATION_TOKEN_KEY = 'verificationToken';

const NO_ACCESS_CODE = 'Access code does not exist';

function verificationTokens(data) {
    return http.post('/recruitingCEVerificationTokens', data, {
        statusCodes: {
            400({ response }) {
                return errorHandler.handleBadRequest(response);
            },
            500() {
                return Promise.reject();
            },
        },
    }).then(tokenMapper.mapTokenFromRest);
}

function createVerificationTokens(data) {
    return http.post('/recruitingCEVerificationTokens', data, {
        statusCodes: {
            400({ response }) {
                const { mode } = data;

                return errorHandler.handleBadRequestOnCreateToken(response, mode);
            },
            500() {
                return Promise.reject();
            },
        },
    }).then(tokenMapper.mapTokenFromRest);
}

function applyVerificationToken(verificationToken, verificationMethod) {
    if (verificationMethod) {
        verificationToken.verificationMethod = verificationMethod;
    }

    if (verificationToken.accessCode) {
        storageService.store(VERIFICATION_TOKEN_KEY, verificationToken);
    }

    if (verificationToken.candidateNumber) {
        userTracking.trackCandidateNumber(verificationToken.candidateNumber);
    }

    if (verificationToken.urlshortCode) {
        storeShortTokenData({
            token: verificationToken.urlshortCode,
            mergedCandidate: verificationToken.mergedCandidate,
        });
    }

    return verificationToken;
}

function applyAccessCode({ accessCode }) {
    if (!accessCode) {
        return;
    }

    const verificationToken = storageService.restore(VERIFICATION_TOKEN_KEY);

    verificationToken.accessCode = accessCode;

    storageService.store(VERIFICATION_TOKEN_KEY, verificationToken);
}

function applyConsumerResponse(verificationToken) {
    const storedToken = storageService.restore(VERIFICATION_TOKEN_KEY);

    if (!storedToken?.accessCode || !containsConsumerAttributes(verificationToken)) {
        return verificationToken;
    }

    [FLOW_ID, STEP_ACTION_USAGE_ID, SUBMISSION_ID, INTERVIEW_REQUEST_ID, SCHEDULE_ID, INTERVIEW_ID, REFERRAL_ID,
        MERGED_CANDIDATE]
        .forEach((attribute) => {
            storedToken[attribute] = verificationToken[attribute];
        });


    storageService.store(VERIFICATION_TOKEN_KEY, storedToken);

    return verificationToken;
}

function applyDraftUserFlag(verificationToken) {
    this.draftUser = verificationToken.draftUser;

    return verificationToken;
}

function containsConsumerAttributes(verificationToken) {
    return verificationToken[FLOW_ID]
        || verificationToken[STEP_ACTION_USAGE_ID]
        || verificationToken[SUBMISSION_ID]
        || verificationToken[INTERVIEW_REQUEST_ID]
        || verificationToken[SCHEDULE_ID]
        || verificationToken[INTERVIEW_ID]
        || verificationToken[REFERRAL_ID]
        || verificationToken[DRAFT_USER]
        || verificationToken[MERGED_CANDIDATE];
}

export default {
    create(tokenData) {
        const { verificationMethod } = tokenData.candidate;

        const data = tokenMapper.mapCreateToRest(tokenData);

        return createVerificationTokens(data)
            .then(verificationToken => applyVerificationToken(verificationToken, verificationMethod()))
            .then(applyDraftUserFlag.bind(this));
    },

    refreshDeviceIDCookie() {
        const accessCode = this.get()?.accessCode;

        if (!accessCode || !sessionPersistence.isEnabled()) {
            return Promise.resolve();
        }

        const verificationTokenPayload = {
            mode: 'REFRESH_DEVICE',
            accessCode,
        };

        return http.post('/recruitingCEVerificationTokens', verificationTokenPayload, {
            statusCodes: {
                400() {
                    return Promise.resolve();
                },
                500() {
                    return Promise.resolve();
                },
            },
        }).catch(() => Promise.resolve());
    },

    verifyToken(token) {
        const data = tokenMapper.mapVerifyTokenToRest(token);

        return verificationTokens(data)
            .then(applyVerificationToken);
    },

    verifyTokenWithChallengeFlag(token) {
        const data = tokenMapper.mapVerifyTokenWithChallengeToRest(token);

        return verificationTokens(data)
            .then(applyVerificationToken)
            .then(applyConsumerResponse)
            .then(applyDraftUserFlag.bind(this));
    },


    verifyCandidate(formData) {
        const data = tokenMapper.mapCandidateToRest(formData);

        return verificationTokens(data);
    },

    verifyCode(data) {
        const shortTokenData = getShortToken();
        const { verificationMethod } = data;

        if (shortTokenData) {
            data.shortToken = shortTokenData.token;
        }

        const payload = tokenMapper.mapVerificationCodeToRest(data);

        return verificationTokens(payload)
            .then((token) => {
                userTracking.trackVerifyTokenSucess(data.jobId, router.route().id);

                return applyVerificationToken(token, verificationMethod());
            });
    },

    resendCode(data) {
        const shortTokenData = getShortToken();

        if (shortTokenData) {
            data.shortToken = shortTokenData.token;
        }

        const payload = tokenMapper.mapResendVerificationCodeToRest(data);

        return verificationTokens(payload);
    },

    claimPhoneNumber(data) {
        const payload = tokenMapper.mapClaimPhoneNumberToRest(data);

        return verificationTokens(payload);
    },

    claimEmail(data) {
        const payload = tokenMapper.mapClaimEmailToRest(data);

        return verificationTokens(payload);
    },


    extendAccess() {
        const accessCode = this.get()?.accessCode;

        if (!accessCode) {
            return Promise.reject(NO_ACCESS_CODE);
        }

        const payload = tokenMapper.mapExtendAccessToRest(accessCode);

        return verificationTokens(payload)
            .then(applyAccessCode);
    },

    signOut() {
        const accessCode = this.get()?.accessCode;

        if (!accessCode) {
            return Promise.reject(NO_ACCESS_CODE);
        }

        const payload = tokenMapper.mapSignOutToRest(accessCode);

        return verificationTokens(payload);
    },

    signOutAll() {
        const accessCode = this.get()?.accessCode;

        if (!accessCode) {
            return Promise.reject(NO_ACCESS_CODE);
        }

        const payload = tokenMapper.mapSignOutAllToRest(accessCode);

        return verificationTokens(payload);
    },

    applyVerificationToken(token) {
        return applyVerificationToken(token);
    },

    accessCodeExists() {
        return this.get()?.accessCode;
    },

    isCandidateVerified() {
        const { accessCode, candidateNumber } = this.get() || {};

        return accessCode && candidateNumber;
    },

    isKnownCandidate() {
        return Boolean(this.getCandidateNumber());
    },

    isDraftCandidate() {
        return !this.accessCodeExists() && this.draftUser;
    },

    setCandidateNumber(candidateNumber) {
        const token = this.get();

        token.candidateNumber = candidateNumber;
        storageService.store(VERIFICATION_TOKEN_KEY, token);
    },

    getCandidateNumber() {
        return this.get()?.candidateNumber;
    },

    get() {
        return storageService.restore(VERIFICATION_TOKEN_KEY);
    },

    destroy() {
        storageService.remove(VERIFICATION_TOKEN_KEY);

        return this;
    },

    setCandidateCommunicationChannel(verificationMethod, newValue) {
        const token = this.get();

        token.verificationMethod = verificationMethod;

        if (verificationMethod === EMAIL) {
            token.email = newValue;
            token.phone = undefined;
        } else {
            token.phone = newValue;
            token.email = undefined;
        }

        storageService.store(VERIFICATION_TOKEN_KEY, token);
    },
};
