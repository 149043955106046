import { observable } from 'knockout';
import { EventFacetResultFromRest, PrimaryFacetType } from '../config/types';
import { FacetFindParams, FacetResultFromRest, FacetValue, FlexFieldFacet, Filter } from '../config/types';
import { RouterQuery } from 'cx/module/search/config/types';

const getFacetPropertyName = (facetName: string): string => {
    const underscoreIndex = facetName.indexOf('_');

    const substrToReplace = `_${facetName.toLowerCase()[underscoreIndex + 1]}`;

    return `${facetName
        .toLowerCase()
        .replace(substrToReplace, facetName[underscoreIndex + 1].toUpperCase())}Facet`;
};

const isFacetStandard = (facetName: PrimaryFacetType | string): facetName is PrimaryFacetType => {
    switch (facetName) {
        case 'LOCATIONS':
        case 'POSTING_DATES':
        case 'CATEGORIES':
        case 'ORGANIZATIONS':
        case 'WORK_LOCATIONS':
        case 'TITLES':
        case 'WORKPLACE_TYPES':
            return true;
        default:
            return false;
    }
};

const mapFacetResultToFilters = (facetFiltersResult: FacetValue[]): Filter[] => {
    return facetFiltersResult.map((facetFilter: FacetValue) => {
        const lastWordIndex = facetFilter.name.lastIndexOf(' ');

        return {
            text: facetFilter.name,
            label: `${facetFilter.name} (${facetFilter.totalCount})`,
            textParts: {
                main: `${facetFilter.name.substring(0, lastWordIndex)} `,
                last: facetFilter.name.substring(lastWordIndex),
            },
            value: facetFilter.id.toString(),
            totalCount: facetFilter.totalCount,
            selected: observable(false),
        } as Filter;
    });
};

export const mapFacetParamsToRest = (
    siteNumber: string,
    facetName: string,
    facetKeyword: string,
    selectedFiltersQuery: RouterQuery
): FacetFindParams => {
    selectedFiltersQuery.mode = undefined;
    selectedFiltersQuery.locationLevel = undefined;

    return {
        siteNumber,
        userTargetFacetName: facetName,
        userTargetFacetInputTerm: facetKeyword,
        ...selectedFiltersQuery,
    };
};

export const mapFacetFiltersFromRest = (result: FacetResultFromRest): Filter[] => {
    const facetName = result.userTargetFacetName;

    let filters: Filter[] = [];

    if (isFacetStandard(facetName)) {
        const standardFacetResult = result[getFacetPropertyName(facetName)] as FacetValue[];

        filters = mapFacetResultToFilters(standardFacetResult);
    } else {
        const [flexFieldFacetResult] = result['flexFieldsFacet'] as FlexFieldFacet[];

        filters = mapFacetResultToFilters(flexFieldFacetResult?.values || []);
    }

    return filters;
};

export const mapEventFacetFiltersFromRest = (result: EventFacetResultFromRest): Filter[] => {
    const facetName = result.userTargetFacetNameAttr;

    const standardFacetResult = result[getFacetPropertyName(facetName)] as FacetValue[];

    return mapFacetResultToFilters(standardFacetResult);
};
