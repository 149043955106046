import { pureComputed, observable } from 'knockout';
import i18n from 'core/i18n/i18n';
import EditPersonalInformationForm from './EditPersonalInformationForm';
import notificationsService from 'cx/service/notifications';
import debounce from 'core/utils/debouncePromise';
import ChallengeModel from 'candidate-verification/model/challenge';
import { EMAIL, SMS } from 'candidate-verification/config/verificationMethods';
import communicationsChannelService from '../service/prefferedCommunicationsChannel';
import applyFlowEvents from '../../../config/events';
import {
    IN_PROGRESS, FILL_IN,
} from 'candidate-verification/config/pinVerificationStatus';
import accessibilityEvents from 'apply-flow/component/apply-flow-accessibility-messages/config/events';
import PhoneEditFormElement from '../element/PhoneEditFormElement';

export default class PersonalInformationForm extends EditPersonalInformationForm {

    constructor(config, params) {
        super(config, params);

        const { emailVerifiedFlag, emailPreferredFlag, phoneVerifiedFlag, phonePreferredFlag } = params;

        this.emailVerifiedFlag = emailVerifiedFlag;
        this.emailPreferredFlag = emailPreferredFlag;
        this.phoneVerifiedFlag = phoneVerifiedFlag;
        this.phonePreferredFlag = phonePreferredFlag;

        this.isCandidateNew = params.candidate.isNew();

        if (this.isCandidateNew) {
            this._setCommunicationChannelForNewCandidate();
        }
    }

    _setupEmailFields() {
        this.emailEdit = this.getElement('email');

        this.emailCommunication = this.getElement('emailPreferredFlag');
        this.emailCommunication.isDisabled = pureComputed(this._isEmailCommunicationDisabled.bind(this));

        this.emailFields = [
            this.emailCommunication,
        ];

        if (this.params.candidate.isNew()) {
            this.emailEdit.requestVerification = this._requestEmailCommunicationChannelChange.bind(this);
            this.emailEdit.requestRemoval = this._requestCommunicationChannelRemoval.bind(this);
            this.emailEdit.throttlingMessage(IN_PROGRESS);
            this.emailEdit.noPinVerification = true;

            this.emailEdit.challenge = new ChallengeModel({
                actions: {
                    onPinValid: () => {},
                    onAttempsLimitReached: () => {},
                },
            });

            this.makeEmailPreferred = debounce(this._requestEmailCommunicationChannelChange.bind(this), 200);
        } else {
            this.emailEdit.requestVerification = this._requestEmailVerification.bind(this);
            this.emailEdit.requestRemoval = this._requestEmailRemoval.bind(this);

            this.emailEdit.challenge = new ChallengeModel({
                candidate: this._getCandidate({
                    attachEmail: true,
                    attachPhone: false,
                }),
                actions: {
                    onPinValid: this._onEmailCommunicationChannelVerificationSuccess.bind(this),
                    onAttempsLimitReached: () => {},
                },
            });

            this.makeEmailPreferred = debounce(communicationsChannelService.makeEmailPreferred, 200);

            this.emailFields = [
                this.getElement('emailVerifiedFlag'),
                ...this.emailFields,
            ];
        }
    }

    _setupPhoneField() {
        this.phoneEdit = this.getElement('phoneNumber');

        this.phoneCommunication = this.getElement('phonePreferredFlag');
        this.phoneCommunication.isDisabled = pureComputed(this._isPhoneCommunicationDisabled.bind(this));

        this.phoneFields = [
            this.phoneCommunication,
        ];

        if (this.params.candidate.isNew()) {
            this.phoneEdit.requestVerification = this._requestPhoneCommunicationChannelChange.bind(this);
            this.phoneEdit.requestRemoval = this._requestCommunicationChannelRemoval.bind(this);
            this.phoneEdit.throttlingMessage = observable(IN_PROGRESS);
            this.phoneEdit.noPinVerification = true;

            this.phoneEdit.challenge = new ChallengeModel({
                actions: {
                    onPinValid: () => {},
                    onAttempsLimitReached: () => {},
                },
            });

            this.makePhonePreferred = debounce(this._requestPhoneCommunicationChannelChange.bind(this), 200);
        } else {
            this.phoneEdit.requestVerification = this._requestPhoneVerification.bind(this);
            this.phoneEdit.requestRemoval = this._requestPhoneRemoval.bind(this);

            this.phoneEdit.challenge = new ChallengeModel({
                candidate: this._getCandidate({
                    attachEmail: false,
                    attachPhone: true,
                }),
                actions: {
                    onPinValid: this._onPhoneCommunicationChannelVerificationSuccess.bind(this),
                    onAttempsLimitReached: () => {},
                },
            });

            this.makePhonePreferred = debounce(communicationsChannelService.makePhonePreferred, 200);

            this.phoneFields = [
                this.getElement('phoneVerifiedFlag'),
                ...this.phoneFields,
            ];
        }
    }

    _onEmailPreferredChanged(value) {
        if (this.isCandidateNew && value) {
            this.makeEmailPreferred(this.emailEdit.value());

            return;
        }

        if (value && !this.emailVerifiedFlag()) {
            this._requestEmailCommunicationChannelVerification();

            return;
        }

        if (this.emailVerifiedFlag() || this.emailPreferredFlag()) {
            super._onEmailPreferredChanged(value);
        }
    }

    _onPhonePreferredChanged(value) {
        if (this.isCandidateNew && value) {
            this.makePhonePreferred(this.phoneEdit.value());

            return;
        }

        if (value && !this.phoneVerifiedFlag()) {
            this._requestPhoneCommunicationChannelVerification();

            return;
        }

        if (this.phoneVerifiedFlag() || this.phonePreferredFlag()) {
            super._onPhonePreferredChanged(value);
        }
    }

    async _requestEmailCommunicationChannelVerification() {
        this.emailEdit.isVerificationInProgress(true);

        await this._requestEmailVerification(this.emailEdit.value());

        this.emailEdit.challenge.verificationStatus(FILL_IN);
        this.emailCommunication.value(false);
        accessibilityEvents.addAlert.dispatch(this.emailEdit.accessibiliyVerificationMessage());
    }

    async _requestPhoneCommunicationChannelVerification() {
        this.phoneEdit.isVerificationInProgress(true);

        await this._requestPhoneVerification(this.phoneEdit.value());

        this.phoneEdit.challenge.verificationStatus(FILL_IN);
        this.phoneCommunication.value(false);
        accessibilityEvents.addAlert.dispatch(this.phoneEdit.accessibiliyVerificationMessage());
    }

    _requestPhoneCommunicationChannelChange(value) {
        this.phoneEdit.isVerificationInProgress(true);

        return communicationsChannelService.changeNewCandidatePhoneChannel(value)
            .then(() => {
                this._onPhoneCommunicationChannelChangeSuccess();
                applyFlowEvents.afterCommunicationChannelChange.dispatch(SMS, value);
            });
    }

    _requestEmailCommunicationChannelChange(value) {
        this.emailEdit.isVerificationInProgress(true);

        return communicationsChannelService.changeNewCandidateEmailChannel(value)
            .then(() => {
                this._onEmailCommunicationChannelSuccessChange();
                applyFlowEvents.afterCommunicationChannelChange.dispatch(EMAIL, value);
            });
    }

    _requestCommunicationChannelRemoval() {
        return Promise.reject('IRC_CAND_NO_ALT_PREF_CHANNEL');
    }

    _requestEmailRemoval() {
        return super._requestEmailRemoval()
            .then(applyFlowEvents.afterCommunicationChannelChange.dispatch(EMAIL, null));
    }

    _requestPhoneRemoval() {
        return super._requestPhoneRemoval()
            .then(applyFlowEvents.afterCommunicationChannelChange.dispatch(SMS, null));
    }

    _isEmailCommunicationDisabled() {
        if (this.isCandidateNew && this.emailPreferredFlag()) {
            return true;
        }

        if (!this.emailVerifiedFlag()) {
            return !this.emailEdit.value() || !this.emailEdit.isValid();
        }

        if (!this.phoneVerifiedFlag()) {
            return true;
        }

        return super._isEmailCommunicationDisabled();
    }

    _isPhoneCommunicationDisabled() {
        if (this.isCandidateNew && this.phonePreferredFlag()) {
            return true;
        }

        if (!this.phoneVerifiedFlag()) {
            return this.phoneEdit.isEmpty() || !this.phoneEdit.isValid();
        }

        if (!this.emailVerifiedFlag()) {
            return true;
        }

        return super._isPhoneCommunicationDisabled();
    }

    _onPhoneCommunicationChannelChangeSuccess() {
        this.phoneEdit.isDisabled(true);
        this.phoneEdit.isVerificationInProgress(false);
        this.phoneEdit.removeValidator(this.phoneEdit.getValidator('required'));

        if (this.emailEdit.editMode()) {
            this.emailEdit.cancel();
        }

        this.emailVerifiedFlag(false);
        this.emailPreferredFlag(false);
        this.emailEdit.isDisabled(false);
        this.emailEdit.editMode(false);
        this.emailEdit.validateImmediately(false);

        if (this.emailEdit.initiallyRequired()) {
            this.emailEdit.addValidator('required', { label: this.emailEdit.label() }, 'prepend');
            this.emailEdit.validateImmediately(true);
        }

        if (this.params.candidate.basicInformation.verificationMethod() === SMS) {
            return this._displayPhoneVerificationNotification();
        }

        return true;
    }

    _onEmailCommunicationChannelSuccessChange() {
        this.emailEdit.isDisabled(true);
        this.emailEdit.isVerificationInProgress(false);
        this.emailEdit.removeValidator(this.emailEdit.getValidator('required'));

        this.phoneVerifiedFlag(false);
        this.phonePreferredFlag(false);
        this.phoneEdit.isDisabled(false);
        this.phoneEdit.validateImmediately(false);

        if (this.phoneEdit instanceof PhoneEditFormElement) {
            this.phoneEdit.cancel();
            this.phoneEdit.editMode(false);

            if (this.phoneEdit.initiallyRequired()) {
                this.phoneEdit.addValidator('required', { label: this.emailEdit.label() }, 'prepend');
                this.phoneEdit.validateImmediately(true);
            }
        }

        if (this.params.candidate.basicInformation.verificationMethod() === EMAIL) {
            return notificationsService
                .success(i18n('talent-community.email-change-verification-confirmed'), 0);
        }

        return true;
    }

    _setCommunicationChannelForNewCandidate() {
        if (this.params.candidate.basicInformation.verificationMethod() === EMAIL) {
            this.emailPreferredFlag(true);
        } else {
            this.phonePreferredFlag(true);
        }
    }

    _onPhoneCommunicationChannelVerificationSuccess() {
        super._onPhoneVerificationSuccess();

        this.phoneCommunication.value(true);
        applyFlowEvents.afterCommunicationChannelChange.dispatch(SMS, this.phoneEdit.value());
    }

    _onEmailCommunicationChannelVerificationSuccess() {
        super._onEmailVerificationSuccess();

        this.emailCommunication.value(true);
        applyFlowEvents.afterCommunicationChannelChange.dispatch(EMAIL, this.emailEdit.value());
    }

}