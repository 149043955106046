import { pureComputed } from 'knockout';
import applicationsModel from '../../model/applications';
import applicationService from 'apply-flow/service/application';
import notificationsService from 'cx/service/notifications';
import csEvents from '../../config/events';
import i18n from 'core/i18n/i18n';

const WITHDRAW_KEY = 'candidate-self-service.withdraw-button-label';
const REMOVE_KEY = 'candidate-self-service.tile.remove-button';

export default class CsApplicationContextMenuViewModel {

    constructor({ application }) {
        this.application = application;
        this.menuId = `menu-${application.submissionId()}`;

        this.onMenuSelect = this.onMenuSelect.bind(this);
        this._removeApplication = this._removeApplication.bind(this);

        this.ariaLabels = {
            withdraw: pureComputed(() => `${i18n(WITHDRAW_KEY)} ${this.application.jobTitle()}`),
            remove: pureComputed(() => `${i18n(REMOVE_KEY)} ${this.application.jobTitle()}`),
        };
    }

    triggerMenuItemAction(event) {
        switch (event.detail.selectedValue) {
            case 'withdrawApplication':
                this._showWithdrawDialog();
                break;
            case 'removeApplication':
                this._removeApplication();
                break;
            default:
                break;
        }
    }

    _showWithdrawDialog() {
        csEvents.openWithdrawDialog.dispatch(this.application);
    }

    _removeApplication() {
        applicationService.remove(this.application)
            .then(() => {
                applicationsModel.remove(this.application);
                notificationsService.success(i18n('candidate-self-service.remove-application-success-message'));
            })
            .catch((err) => {
                notificationsService.error();

                throw new Error(err);
            });
    }

    onMenuSelect(event, { item: selectedAction }) {
        const actionName = selectedAction.children('a').attr('data-action-name');

        this[actionName]();
    }

}