import { observable } from 'knockout';
import mapping from 'knockout-mapping';

export default class Component {

    constructor(data) {
        this.elementNumber = data.elementNumber;
        this.id = data.id;
        this.params = mapping.fromJS(data.params);
        this.order = observable(data.order);
    }

    get type() {
        return this.params.type().toLowerCase();
    }

}
