import base64 from 'core/utils/base64';
import itemSorter from '../service/itemSorter';
import { mapComponentParamsFromRest } from 'cx/module/custom-content/mapper/mapComponentParams';

function mapComponentTranslationsFromRest(translations) {
    translations.forEach((translation) => {
        delete translation.elementId;
    });

    return translations;
}

export default {
    mapComponentsFromRest(components, componentFactory) {
        if (!components || !components.length) {
            return [];
        }

        const sortedComponents = itemSorter.sort(components);

        return sortedComponents.map(component => this.mapComponentFromRest(component, componentFactory));
    },

    mapComponentFromRest(component, componentFactory) {
        const content = component.componentContent || component.content;

        return componentFactory.create({
            elementNumber: component.elementNumber,
            content: base64.decode(content),
            order: component.elementOrder,
            params: mapComponentParamsFromRest(component),
            translations: mapComponentTranslationsFromRest(component.componentTranslations ?? []),
        });
    },
};