import http from 'core/http/http';
import errorHandler from 'candidate-verification/service/tokenErrorHandler';
import {
    flattenResumeParserResponseToRecommendedJobsMetadata,
    mapResumeParserInputToRest,
} from '../mapper/resumeParserRestMapper';
import {
    RecommendedJobsMetadata,
    AttachmentedResumePayloadKeys,
    VerificationTokenPayload,
} from '../config/types';
import tokenService from 'candidate-verification/service/token';
import storageService from 'core/storage/sessionStorage';
import { ViewBy, RouterParams, RouterQuery } from '../config/types';
import { areEventsEnabled } from 'app/service/areEventsEnabled';
import { SEARCH_RESULTS_STRINGS } from 'app/module/cx/module/search/config/types';
import router from 'app/model/router';
import { isLocationSearch, isKeywordSearch } from 'app/module/cx/module/search/service/searchTypes';

export const STORAGE_RECOMMENDED_JOBS_METADATA = 'storage_recommended_jobs_metadata';
export const DATA_TYPE_CODE = 'datatypeCode';
export const DEFAULT_CLASS_ID = 'default';
export const RECOMMENDED_JOB_RESUME = 'recommended_job_resume';

const CREATE_VERIFICATION_TOKENS_URL = '/recruitingCEVerificationTokens';
const CANDIDATE_RESUME_PARSER_URL = '/recruitingCECandidateResumeParsers';

export async function createVerificationToken(): Promise<Record<string, string | unknown>> {
    const accessCode: string = tokenService.accessCodeExists();

    if (accessCode) {
        return { accessCode };
    }

    const verificationTokenPayload: VerificationTokenPayload = {
        mode: 'JOB_APPLY_RECOMMENDED',
        email: `RECOMMENDED_JOB_DEMO_DEFAULT_${Date.now()}@adcdat05.us.oracle.com`,
    };

    return http.post(CREATE_VERIFICATION_TOKENS_URL, verificationTokenPayload, {
        statusCodes: {
            400({ response }: Record<string, unknown>) {
                const { mode } = verificationTokenPayload;

                return errorHandler.handleBadRequestOnCreateToken(response, mode);
            },
            500() {
                return Promise.reject();
            },
        },
    });
}

export function fetchAttachedResumeMetadata(
    attachmentedResumePayload: Record<AttachmentedResumePayloadKeys, string>,
    accesscode: string
): Promise<RecommendedJobsMetadata> {
    const headers = { 'Ora-Irc-Access-Code': accesscode };

    return http
        .post(CANDIDATE_RESUME_PARSER_URL, mapResumeParserInputToRest(attachmentedResumePayload), { headers })
        .then(flattenResumeParserResponseToRecommendedJobsMetadata);
}

export function saveRecommendedJobsMetadataInStorage(recommendedJobsMetadata: RecommendedJobsMetadata): void {
    const { skills, workExperiences, displayName, educations } = recommendedJobsMetadata;

    const skillsList = skills.map(({ skills }) => skills);

    const currentJobTitle =
        workExperiences.find(({ currentJobFlag }) => currentJobFlag === 'Y')?.jobTitle ??
        workExperiences[0]?.jobTitle;

    const prepareRecommendedJobsMetadata = {
        skills: skillsList,
        titles: currentJobTitle ? [currentJobTitle] : [],
        displayName: displayName,
        workExperiences,
        educations,
    };

    storageService.store(STORAGE_RECOMMENDED_JOBS_METADATA, prepareRecommendedJobsMetadata);
}

export function redirectToSearchBasedOnViewBy(viewBy: ViewBy, userName?: string): void {
    let query: { viewBy?: ViewBy; selectedFile?: string } = { viewBy };

    if (viewBy === 'RECOMMENDED_JOBS') {
        query['selectedFile'] = userName;
    } else {
        query = {};
    }

    if (areEventsEnabled()) {
        storageService.store(SEARCH_RESULTS_STRINGS.STORAGE_JOBS_PARAMS, { query: {} });
    }

    router.go('search', { query }, { inherit: false });
}

export const areRecommendedJobsViewEnabled = (query: RouterQuery): boolean => {
    return query?.viewBy === 'RECOMMENDED_JOBS';
};

export const areRecommendedJobsEnabledWithParams = (): boolean => {
    const { query } = router.routeParams() as RouterParams;

    const isKeywordOrLocationBasedSearch = query ? isKeywordSearch(query) || isLocationSearch(query) : false;

    return areRecommendedJobsViewEnabled(query) && !isKeywordOrLocationBasedSearch;
};
