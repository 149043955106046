import { Observable, observable, pureComputed, PureComputed } from 'knockout';
import router from 'app/model/router';
import i18n from 'core/i18n/i18n';
import {
    getEventDetails,
    getEventDetailsPreview,
    isEventAdminPreview,
    isEventOpenForRegistration,
    isPublicEvent,
} from 'minimal/module/event-details/service/eventService';
import { EventData, RegistrationPhase, RouterParams } from 'minimal/module/event-details/config/types';
import { areEventsEnabledLocally } from 'app/service/areEventsEnabled';
import { RouterQuery, SearchContext, SEARCH_RESULTS_STRINGS } from 'cx/module/search/config/types';
import cxEvents from 'cx/config/events';
import { getEventRegisterFlowRouting } from '../../event-register-flow/config/eventRegisterFlowRoutes';
import appConfig from 'app/model/config';
import signedCandidate from 'candidate-verification/model/signedCandidate';

export default class EventDetailsViewModel {
    private isDetailsOpened: Observable<boolean>;
    public goBackLabel: string;
    public viewType: string;
    private eventData: Observable<EventData | null>;
    public isPreviousRouteStored: boolean;
    private loaded: Observable<boolean>;
    private CSS_CONTEXT = 'css';
    private NO_CONTEXT = '';
    private isEventEnabled: boolean;
    private langToUse: Observable<string>;
    private eventFormatValue: string; // TODO  to be in ENGLISH for this release and later it should be same as eventlanguage
    private showAllEventsLink: boolean;
    private isApplyButtonEnabled: Observable<boolean>;
    private applyButtonLabel: Observable<string>;
    buttonStatusClass: Observable<string>;
    private isEventPublic: Observable<boolean>;
    private showCopyLink: Observable<boolean>;
    private showShareWidget: PureComputed<boolean>;
    private context: SearchContext;

    constructor() {
        this.eventData = observable(null);
        this.loaded = observable<boolean>(false);
        this.isDetailsOpened = observable<boolean>(true);
        this.afterDialogClose = this.afterDialogClose.bind(this);
        this.goBackLabel = i18n('job-details.a11y.back-button-label');
        this.isEventEnabled = areEventsEnabledLocally();
        this.langToUse = observable('en');
        this.eventFormatValue = '';
        this.loadEvent();
        this.isPreviousRouteStored = true;
        this.showAllEventsLink = true;
        this.viewType = 'modal';

        this.isEventPublic = observable<boolean>(false);
        this.showCopyLink = observable<boolean>(false);

        this.showShareWidget = pureComputed(() => this.isShareWidgetVisible());
        this.context = SEARCH_RESULTS_STRINGS.SEARCH_CONTEXT_EVENTS;

        this.isApplyButtonEnabled = observable<boolean>(false);
        this.applyButtonLabel = observable('event-details.register-event-button');
        this.buttonStatusClass = observable('');
        this.setApplyButtonState();
    }

    private setApplyButtonState(): void {
        this.eventData.subscribe((event: EventData | null) => {
            const { isSignedIn } = signedCandidate;

            if (Boolean(isSignedIn()) && event?.isAlreadyRegistered) {
                this.isApplyButtonEnabled(!event.isConfirmed);
                this.applyButtonLabel(this.getApplyButtonLabel(event));
                this.buttonStatusClass(event.isConfirmed ? 'apply-now-button--already-applied' : '');
            } else {
                this.isApplyButtonEnabled(true);
                this.buttonStatusClass('');
            }
        });
    }

    private getApplyButtonLabel(event: EventData): string {
        if (!event.isConfirmed) {
            return 'event-details.confirm-registration';
        }

        if (!event.registrationInfo) {
            return '';
        }

        const registrationPhase: RegistrationPhase = event.registrationInfo.registrationPhase;

        switch (registrationPhase) {
            case 'PENDING':
                return 'event-details.already-expressed-interest';
            case 'WITHDRAWN':
                return 'event-details.already-withdrawn';
        }

        return 'event-details.already-registered';
    }

    isShareWidgetVisible(): boolean {
        return this.showCopyLink();
    }

    afterDialogClose(): void {
        const routeId = router.route().id;

        if (routeId === 'event-preview') {
            this.redirectToMainPage();
        }
    }

    redirectToMainPage(): void {
        const { query } = router.routeParams() as { query: RouterQuery };
        const previewCloseNavRoute = this.isEventEnabled ? 'search-events' : 'search';

        router.go(
            previewCloseNavRoute,
            {
                query,
            },
            {
                inherit: false,
            }
        );
    }

    close(): void {
        this.isDetailsOpened(false);
    }

    async loadEvent(): Promise<void> {
        try {
            this.loaded(false);

            const { eventId } = router.routeParams() as RouterParams;
            const eventAttributes = await this.getEvent(eventId, this.getCurrentContext());

            this.loaded(true);

            if (eventAttributes) {
                this.langToUse(this.deriveLangToUse(eventAttributes.lang));

                // TODO strip off the arguments other than key from i18n once REST API returns the event language
                this.eventFormatValue = eventAttributes.isVirtual
                    ? i18n('event-details.virtual-event', undefined, eventAttributes.lang)
                    : i18n('event-details.in-person', undefined, eventAttributes.lang);

                this.eventData(eventAttributes);

                this.setEventShareAttributes();
            }

            this.setPageTitle();
        } catch (error: unknown) {
            this.loaded(true);
            this.eventData(null);
            console.error('Error while setting event data ' + error);
        }
    }

    async apply(): Promise<void> {
        const { eventId } = router.routeParams() as RouterParams;
        const routeObj = await getEventRegisterFlowRouting(eventId, router.route().id == 'event-preview');

        router.go(routeObj.route, routeObj.params);
    }

    getCurrentContext(): string {
        const currentRoute = router.route().id;

        if (
            currentRoute === 'candidate-self-service.event-preview' ||
            currentRoute === 'candidate-self-service.event-detail-fullscreen'
        ) {
            return this.CSS_CONTEXT;
        } else {
            return this.NO_CONTEXT;
        }
    }

    private async getEvent(eventId: string, currentContext: string): Promise<EventData | null> {
        switch (router.route().id) {
            case 'event-preview':
            case 'candidate-self-service.event-preview':
            case 'candidate-self-service.event-detail-fullscreen':
            case 'event-details':
            case 'event-details-with-token':
                return await getEventDetails(eventId, currentContext);

            case 'admin-event-preview':
                return await getEventDetailsPreview(eventId, currentContext);
        }

        return Promise.reject('Unexpected route');
    }

    private setPageTitle() {
        cxEvents.pageTitleUpdate.dispatch(this.eventData()?.title);
    }

    deriveLangToUse(eventLang: string): string {
        const siteLang: string = appConfig.siteLang;

        if (eventLang === siteLang) {
            return eventLang;
        }

        const isEventLangMatchesAnyOfTheSiteLanguage: boolean = appConfig.languages.some(
            (language: { code: string }) => language.code === eventLang
        );

        return isEventLangMatchesAnyOfTheSiteLanguage ? eventLang : siteLang;
    }

    setEventShareAttributes(): void {
        this.isEventPublic(isPublicEvent(this.eventData()));
        this.showCopyLink(this.isCopyLinkVisible());
    }

    validateEventRegistrationStatus(): boolean {
        return !isEventAdminPreview() && !isEventOpenForRegistration(this.eventData());
    }

    isCopyLinkVisible(): boolean {
        if (this.validateEventRegistrationStatus()) {
            return false;
        }

        const isPrivateEventInAdminPreview = !this.isEventPublic() && isEventAdminPreview();

        return this.isEventPublic() || isPrivateEventInAdminPreview;
    }
}
