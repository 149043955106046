import { ObservableParams } from 'minimal/module/search/service/observableParams';
import { AddCategoryTileStoreAction } from 'site-editor/module/store/model/categoryTile/AddCategoryTileStoreAction';
import { Observable } from 'knockout';

export const CATEGORY_TILES_CONTENT_GENERATED = 'category-tiles-content-generated';

export const SOURCE_COLLECTION_CATEGORY_LINKS = 'jobCategoryLinks';

export type CategoryTileModel = {
    linkNumber: string | undefined;
    parentLinkNumber: string | undefined;
    order: number;
    id: string;
    title: string;
    url: string | undefined;
    heroImageUrl: string | undefined;
    tileImageUrl: string | undefined;
    introText: string | undefined;
    sourceCollection?: string;
    pageNumber: string;
    isLoading: boolean;
};

export type CategoryTileModelObservable = ObservableParams<CategoryTileModel> & {
    __ko_mapping__?: unknown;
    addAction?: AddCategoryTileStoreAction;
};

export type Category = {
    id: string;
    value: string;
    activeStatus: string;
};

export type Page = {
    label: string;
    value: string;
};

export type JobCategoryFilter = Record<'id' | 'value', Observable<string>>;

export type CreatePage = Record<'pageCode' | 'elementNumber', string>;
