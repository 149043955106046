import tokenService from 'cx/module/candidate-verification/service/token';
import { CHALLENGE_REQUIRED } from 'cx/module/candidate-verification/config/pinVerificationStatus';
import { INVALID_COOKIE } from 'cx/module/candidate-verification/config/candidateVerificationStatus';
import { redirectToRegisterFlow } from '../../../config/eventRegisterFlowRoutes';
import { CandidateVerificationRequest } from '../../../config/types';
import { Token } from 'cx/module/candidate-verification/config/types';
import {
    getSourceTrace,
    removeSourceTrace,
} from 'app/module/cx/module/candidate-verification/service/sourceTraceStorage';

let claimedLastName: string | null;
let cachedLastName: string | null;

export const verifyLastName = async (
    candidateVerificationRequest: CandidateVerificationRequest
): Promise<void> => {
    claimedLastName = null;

    const { eventNumber, candidate, submodule, lastName, captchaToken, honeyPot } =
        candidateVerificationRequest;

    const sourceTrace = getSourceTrace();

    return tokenService
        .create({ eventNumber, candidate, submodule, sourceTrace, lastName, captchaToken, honeyPot })
        .then((verificationToken: Token) => {
            if (verificationToken.challengeFlag) {
                removeSourceTrace();

                return Promise.reject(CHALLENGE_REQUIRED);
            }

            return redirectToRegisterFlow();
        })
        .catch((error: string) => {
            if (error === INVALID_COOKIE) {
                cachedLastName = lastName;
            }

            return Promise.reject(error);
        });
};

export const getClaimedLastName = (): string | null => {
    return claimedLastName;
};

export const setClaimedLastName = (lastName: string): void => {
    claimedLastName = lastName;
};

export const reclaimLastName = (): void => {
    claimedLastName = cachedLastName;
};
