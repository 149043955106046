import ko from 'knockout';
import ProfileItemsViewModel from 'apply-flow/module/profile-items/ProfileItemsViewModel';
import sectionValidator from 'apply-flow/model/sectionValidator';
import timelineEvents from './config/events';
import FormChangeDetector from 'core/form/service/formChangeDetector';

export default class TimelineProfileItemsViewModel extends ProfileItemsViewModel {

    constructor(...args) {
        super(...args);

        this.isValid = ko.pureComputed(() => this.forms().length > 0);
        this._editTimelineItemSignal = timelineEvents.editTimelineItem.add(this.editProfileItem.bind(this));
    }

    addProfileItem() {
        const profileItem = this._createItem();
        const form = this._createSingleForm(profileItem);

        this._showForm(form, this._persistProfileItem);
    }

    editProfileItem(profileItemId) {
        const profileItemForm = this._getFormByItemId(profileItemId);

        if (profileItemForm) {
            this._showForm(profileItemForm, this.doneProfileItem);
        }
    }

    doneProfileItem(profileItemForm) {
        const form = profileItemForm();

        if (!this.changeDetector.hasChanged()) {
            this._hideForm();

            this.scrollToSelectedSubblock(this.blockId);

            return;
        }

        this.scrollToSelectedSubblock(this.blockId);

        this.validateProfileItemForm(form)
            .then(() => this._hideForm());
    }

    _persistProfileItem(profileItemForm) {
        const form = profileItemForm();

        if (!this.changeDetector.hasChanged()) {
            this._hideForm();

            this.scrollToSelectedSubblock(this.blockId);

            return;
        }

        this.validateProfileItemForm(form)
            .then(() => {
                this.forms.push(profileItemForm);
                sectionValidator.registerForm(profileItemForm, this.sectionNumber);

                this.profileItems.push(form.model());

                this._hideForm();

                this.scrollToSelectedSubblock(this.blockId);
            });
    }

    _showForm(profileItemForm, onSave) {
        this.changeDetector = new FormChangeDetector(profileItemForm());

        timelineEvents.openFormDialog.dispatch({
            form: profileItemForm,
            showRemoveButton: true,
            save: onSave.bind(this),
            remove: this.removeProfileItem.bind(this),
            isValidationInProgress: this.isValidationInProgress,
        });
    }

    _hideForm() {
        timelineEvents.closeFormDialog.dispatch();
    }

    _findContainerElement() {
        const element = document.querySelector('.app-dialog__content');

        return element || document.querySelector('timeline-form-dialog');
    }

    dispose() {
        this._editTimelineItemSignal.detach();
        super.dispose();
    }

}