import { CategoryTilesDisplayContent } from './types';
import i18n from 'core/i18n/i18n';

export const STATIC_TILES_DATA: CategoryTilesDisplayContent[] = [
    {
        order: 0,
        jobFamilyIds: '',
        title: i18n('category-tiles.admin.title-label'),
        url: '',
        tileImageUrl: '',
    },
    {
        jobFamilyIds: '',
        order: 1,
        title: i18n('category-tiles.admin.title-label'),
        url: '',
        tileImageUrl: '',
    },
    {
        order: 2,
        title: i18n('category-tiles.admin.title-label'),
        url: '',
        tileImageUrl: '',
        jobFamilyIds: '',
    },
    {
        order: 3,
        title: i18n('category-tiles.admin.title-label'),
        url: '',
        tileImageUrl: '',
        jobFamilyIds: '',
    },
];
