import { Observable, observable } from 'knockout';
import router from 'app/model/router';
import legalDisclaimerService from 'apply-flow/service/legalDisclaimer';
import CandidateVerificationAbstractViewModel from 'app/module/cx/module/candidate-verification/component/email-verification/CandidateVerificationAbstractViewModel';
import {
    CLAIM_PHONE_NUMBER,
    CLAIM_EMAIL,
    EVENT_REGISTER,
} from 'cx/module/candidate-verification/config/verificationSubmodules';
import {
    CHALLENGE_REQUIRED,
    ATTEMPS_LIMIT_REACHED,
    PIN_LIMIT_REACHED,
    PHONE_BLOCKED,
} from 'cx/module/candidate-verification/config/pinVerificationStatus';
import { createToken } from './service/tokenVerifier';
import { verifyLastName } from './service/lastNameVerifier';
import { verifyDateOfBirth } from './service/dateOfBirthVerifier';
import { claimPhoneNumber } from './service/phoneNumberClaimer';
import { claimEmail } from './service/emailClaimer';
import notificationsService from 'cx/service/notifications';
import { redirectToRegisterFlow } from '../../config/eventRegisterFlowRoutes';
import LegalDisclaimerForm from 'app/module/cx/module/apply-flow/module/agreements/component/legal-disclaimer/form/LegalDisclaimerForm';
import { storeLastLegalDisclaimerFor } from '../../service/eventRegisterFlowLocalStorage';
import LegalDisclaimer from 'app/module/cx/module/apply-flow/model/LegalDisclaimer';
import ValidatableForm from 'app/module/core/form/ValidatableForm';
import {
    DATE_OF_BIRTH_CHECK,
    INVALID_COOKIE,
    LAST_NAME_CHECK,
} from 'app/module/cx/module/candidate-verification/config/candidateVerificationStatus';
import lastNameFormBuilder from 'app/module/cx/module/candidate-verification/component/email-verification/service/lastNameFormBuilder';
import dateOfBirthFormBuilder from 'app/module/cx/module/candidate-verification/component/email-verification/service/dateOfBirthFormBuilder';
import i18n from 'app/module/core/i18n/i18n';
import { CandidateVerificationRequest } from '../../config/types';
import { setClaimedLastName } from './service/lastNameVerifier';

type RouterParams = {
    eventId: string;
};

export default class EventCandidateVerificationViewModel extends CandidateVerificationAbstractViewModel {
    private isLoading: Observable<boolean>;
    private requestErrorType: Observable<string | null>;
    private eventId: Observable<string>;
    private lastName: Observable<string>;
    private lastNameForm: ValidatableForm;
    private showLastNameMismatchMessage: Observable<boolean>;
    private isLastNameCheck: Observable<boolean>;
    private showClaimMessage = true; // unused variable defined here as the reused template use this

    private dateOfBirth: Observable<Date | undefined>;
    private dateOfBirthForm: ValidatableForm;
    private showDateOfBirthMismatchMessage: Observable<boolean>;
    private isDateOfBirthCheck: Observable<boolean>;

    readonly PHONE_BLOCKED_MESSAGE = i18n('apply-flow.candidate-verification.cant-use-this-phone');
    readonly KNOWN_ERROR_TYPES = [
        'already-applied',
        'pin-code-required',
        'token-verification',
        LAST_NAME_CHECK,
        DATE_OF_BIRTH_CHECK,
    ];

    constructor() {
        super();
        this.isLoading = observable<boolean>(false);
        this.requestErrorType = observable(null);
        this.eventId = observable((router.routeParams() as RouterParams).eventId);
        this.challenge.submodule = EVENT_REGISTER;
        this.loadLegalDisclaimer();
        this.lastName = observable('');
        this.lastNameForm = lastNameFormBuilder.createForm(this.lastName);
        this.showLastNameMismatchMessage = observable<boolean>(false);
        this.isLastNameCheck = observable<boolean>(false);
        this.dateOfBirth = observable<Date>();
        this.dateOfBirthForm = dateOfBirthFormBuilder.createForm(this.dateOfBirth);
        this.showDateOfBirthMismatchMessage = observable<boolean>(false);
        this.isDateOfBirthCheck = observable<boolean>(false);
    }

    private redirectBack(): Promise<void> {
        const parentRoute = router.route().parent;

        return router.go(parentRoute.id);
    }

    private next(): void {
        const form = this.activeForm();

        form.enableImmediateValidation();
        this._enableLegalDisclaimerValidation();

        form.validate().then((isFormValid: boolean) => {
            if (!isFormValid || !this._isLegalDisclaimerEmptyOrValid()) {
                return;
            }

            this.beforeCaptchaVerify();
        });
    }

    verify(): void {
        this.storeLegalDisclaimerAcceptance();
        this.trackSourceAndGenerateToken();
    }

    onPinValid(): void {
        // overridden
        redirectToRegisterFlow();
        this.resetView();
    }

    onAttempsLimitReached(): Promise<void> {
        // overridden
        return this.redirectBack();
    }

    private trackSourceAndGenerateToken(): Promise<void> {
        this.isLoading(true);

        return createToken(this.getCandidateVerificationParams())
            .then(this.resetView.bind(this))
            .catch(this.handleTokenError.bind(this));
    }

    private verifyLastName(): void {
        this.lastNameForm.enableImmediateValidation();

        this.lastNameForm.validate().then((isFormValid: boolean) => {
            if (!isFormValid) {
                return;
            }

            this.isLoading(true);

            verifyLastName(this.getCandidateVerificationParams())
                .then(this.resetView.bind(this))
                .catch(this.handleTokenError.bind(this));
        });
    }

    private verifyDateOfBirth(): void {
        this.dateOfBirthForm.enableImmediateValidation();

        this.dateOfBirthForm.validate().then((isFormValid: boolean) => {
            if (!isFormValid) {
                return;
            }

            this.isLoading(true);

            verifyDateOfBirth(this.getCandidateVerificationParams())
                .then(this.resetView.bind(this))
                .catch(this.handleTokenError.bind(this));
        });
    }

    private getCandidateVerificationParams(): CandidateVerificationRequest {
        const verificationRequest: CandidateVerificationRequest = {
            eventNumber: this.eventId(),
            candidate: this.candidate,
            submodule: this.challenge.submodule,
            lastName: this.lastName(),
            dateOfBirth: this.dateOfBirth(),
            captchaToken: this.captchaToken(),
            honeyPot: this.honeyPot(),
        };

        return verificationRequest;
    }

    private claimPhoneNumber(): void {
        this.isLoading(true);
        this.challenge.submodule = CLAIM_PHONE_NUMBER;

        claimPhoneNumber({ candidate: this.candidate })
            .then(this.resetView.bind(this))
            .catch(this.handleTokenError.bind(this));
    }

    private claimEmail(): void {
        this.isLoading(true);
        this.challenge.submodule = CLAIM_EMAIL;

        claimEmail({ candidate: this.candidate })
            .then(this.resetView.bind(this))
            .catch(this.handleTokenError.bind(this));
    }

    private redirectBackToEmailVerification(): void {
        this.showLastNameMismatchMessage(false);
        this.showDateOfBirthMismatchMessage(false);
        this.lastName('');
        this.dateOfBirth(undefined);
        this.setVerificationMethod(this.candidate.verificationMethod());
        this.isLastNameCheck(false);
        this.isDateOfBirthCheck(false);
        this.resetView();
    }

    private loadLegalDisclaimer(): void {
        this.isLoading(true);

        return legalDisclaimerService
            .queryEventLegalDisclaimer(this.eventId())
            .then((legalDisclaimer: LegalDisclaimer) => {
                this.legalDisclaimer(legalDisclaimer);
                this.legalDisclaimerForm(new LegalDisclaimerForm(this.legalDisclaimer().isAccepted));
                this.isLoading(false);
            })
            .catch((error: Error) => console.error(error));
    }

    dispose(): void {
        notificationsService.dismissAll();
    }

    private handleTokenError(error: string): void {
        this.isLoading(false);

        if (error === ATTEMPS_LIMIT_REACHED || error === PIN_LIMIT_REACHED) {
            this.verificationStatus(ATTEMPS_LIMIT_REACHED);
            this.requestErrorType(CHALLENGE_REQUIRED);
        } else if (error === PHONE_BLOCKED) {
            notificationsService.error(this.PHONE_BLOCKED_MESSAGE, 0);
        } else if (error === INVALID_COOKIE) {
            if (this.dateOfBirth()) {
                this.showDateOfBirthMismatchMessage(true);
            } else {
                setClaimedLastName(this.lastName());
                this.showLastNameMismatchMessage(true);
            }
        } else if (error === LAST_NAME_CHECK) {
            this.requestErrorType(error);
            this.isLastNameCheck(true);
        } else if (error === DATE_OF_BIRTH_CHECK) {
            this.requestErrorType(error);
            this.isDateOfBirthCheck(true);
        } else if (this.KNOWN_ERROR_TYPES.includes(error)) {
            this.requestErrorType(error);
        } else {
            notificationsService.error(error, 0);
            console.error(error);
        }
    }

    private storeLegalDisclaimerAcceptance(): void {
        if (!this.enableLegalDisclaimer()) {
            return;
        }

        storeLastLegalDisclaimerFor(this.eventId());
    }

    private resetView(): void {
        this.isLoading(false);
        this.requestErrorType(null);
    }
}
