import { observableArray, pureComputed } from 'knockout';

const IMPORT_PROFILE_ITEMS = ['education-form', 'experience-form', 'name-form', 'address-form', 'language-form', 'license-form'];

class SectionValidator {

    constructor(sections) {
        this.sections = {};

        sections.forEach(({ number }) => {
            this.sections[number] = this._createSectionState(number);
        });

        this.valid = pureComputed(this._checkIsAllSectionsValid.bind(this), this);
    }

    _createSectionState(sectionNumber) {
        return {
            forms: observableArray(),
            extraValidations: observableArray(),
            hasRequiredElementExtraValidation: false,
            errors: pureComputed(this._countSectionErrors.bind(this, sectionNumber), this),
            valid: pureComputed(this._checkIsSectionValid.bind(this, sectionNumber), this),
        };
    }

    _countSectionErrors(sectionNumber) {
        const forms = this.sections[sectionNumber].forms();
        const formErrors = forms.reduce((errorCount, form) => errorCount + form().getErrorCount(), 0);

        const extraValidations = this.sections[sectionNumber].extraValidations();

        const extraValidationsErrors = extraValidations.reduce((errorCount, validation) =>
            (validation() ? errorCount : errorCount + 1), 0);

        return formErrors + extraValidationsErrors;
    }

    _checkIsSectionValid(sectionNumber) {
        const section = this.sections[sectionNumber];
        const validExtraValidations = section.extraValidations().every(validation => validation() === true);

        return section.errors() === 0 && validExtraValidations;
    }

    _checkIsAllSectionsValid() {
        const invalidSections = Object.keys(this.sections).filter(key => !this.sections[key].valid());

        return !invalidSections.length;
    }

}

function _isProfileItem(form) {
    return IMPORT_PROFILE_ITEMS.indexOf(form().componentName()) > -1;
}

export default {
    initialize(sections) {
        this.sectionValidator = new SectionValidator(sections);
        this.summaryErrorCount = pureComputed(this._countAllSectionErrors, this);
    },

    registerForm(form, sectionNumber) {
        const index = this.sectionValidator.sections[sectionNumber].forms.indexOf(form);

        if (index === -1) {
            this.sectionValidator.sections[sectionNumber].forms.push(form);
        }
    },

    unregisterForm(form, sectionNumber) {
        const index = this.sectionValidator.sections[sectionNumber].forms.indexOf(form);

        if (index !== -1) {
            this.sectionValidator.sections[sectionNumber].forms.splice(index, 1);
        }
    },

    getSectionErrorCount(sectionNumber) {
        return this.sectionValidator.sections[sectionNumber].errors;
    },

    getSummaryErrorCount() {
        return this.summaryErrorCount;
    },

    isSectionValid(sectionNumber) {
        return this.sectionValidator.sections[sectionNumber].valid;
    },

    validateAfterImport() {
        return this.validateAllSections();
    },

    validateSection(sectionNumber) {
        return Promise.all(this.sectionValidator.sections[sectionNumber].forms().map((form) => {
            form().enableImmediateValidation();

            return form().validate();
        })).then(() => this.sectionValidator.sections[sectionNumber].valid());
    },

    validateSectionProfileItems(sectionNumber) {
        this.sectionValidator.sections[sectionNumber].forms()
            .filter(_isProfileItem)
            .forEach((form) => {
                form().enableImmediateValidation();
                form().validate();
            });

        return this.sectionValidator.sections[sectionNumber].valid();
    },

    isAllSectionsValid() {
        return this.sectionValidator.valid;
    },

    validateAllSections() {
        const validations = Object.keys(this.sectionValidator.sections).map(key => this.validateSection(key));

        return Promise.all(validations)
            .then(() => this.sectionValidator.valid());
    },

    addValidation(sectionNumber, validation) {
        this.sectionValidator.sections[sectionNumber].extraValidations.push(validation);
    },

    _getSectionErrorMessages(sectionNumber) {
        return this.sectionValidator.sections[sectionNumber].forms()
            .reduce((errors, form) => [...errors, ...form().getErrorMessages()], []);
    },

    getAllSectionErrorMessages() {
        return Object.keys(this.sectionValidator.sections)
            .reduce((errors, sectionNumber) => [...errors, ...this._getSectionErrorMessages(sectionNumber)], [])
            .flat();
    },

    _countAllSectionErrors() {
        const sections = Object.keys(this.sectionValidator.sections);

        return sections.reduce((summaryErrorCount, sectionNumber) => {
            const errorCount = this.getSectionErrorCount(sectionNumber);

            return summaryErrorCount + errorCount();
        }, 0);
    },

    hasRequiredElement(sectionNumber) {
        const section = this.sectionValidator.sections[sectionNumber];

        if (section.hasRequiredElementExtraValidation) {
            return true;
        }

        function formHasRequiredElement(form) {
            return form().elements()
                .some(element => element.isRequired());
        }

        return section.forms()
            .some(formHasRequiredElement);
    },

    setHasRequiredElementExtraValidation(sectionNumber, value) {
        this.sectionValidator.sections[sectionNumber].hasRequiredElementExtraValidation = value;
    },
};
