import { pureComputed } from 'knockout';
import ElementViewModel from 'cx/module/custom-content/component/element/ElementViewModel';
import { addHttpsPrefix } from '../../service/addHttpsPrefix';
import { getFusionCode } from 'custom-content/service/getFusionCode';

export default class ImageViewModel extends ElementViewModel {

    constructor({ params, mode, lang }) {
        super({ params });

        this.params = params;
        this.isAdminMode = mode === 'admin';

        this.mobileSrc = pureComputed(this._getMobileSrc, this);

        this.targetAttr = pureComputed(() => (
            this.params.openInNewTab() ? '_blank' : null
        ));

        const languageCode = lang ? lang() : getFusionCode();

        this.desktopAlt = pureComputed(() => {
            const { desktopAlt } = this.params;
            const paramKey = `desktopAlt_${languageCode}`;
            const langDesktopAlt = this.params[paramKey];

            return langDesktopAlt ? langDesktopAlt() : desktopAlt();
        });

        this.linkUrl = pureComputed(() => {
            const { linkUrl } = this.params;
            const paramKey = `linkUrl_${languageCode}`;
            const langLinkUrl = this.params[paramKey];

            const url = langLinkUrl ? langLinkUrl() : linkUrl();

            if (url === null || url.startsWith('#')) {
                return url;
            }

            return addHttpsPrefix(url);
        });

        this.mobileAlt = pureComputed(() => {
            const { mobileAlt } = this.params;
            const paramKey = `mobileAlt_${languageCode}`;
            const langMobileAlt = this.params[paramKey];

            if (this.params.useDesktopSrcAsMobile()) {
                return this.desktopAlt();
            }

            return langMobileAlt ? langMobileAlt() : mobileAlt();
        });

        this.cssClass = pureComputed(this._computeCssClass, this);
    }

    getDimension(value) {
        return value() || '';
    }

    _getMobileSrc() {
        if (this.params.desktopSrc() && this.params.useDesktopSrcAsMobile()) {
            return this.params.desktopSrc();
        }

        return this.params.mobileSrc();
    }

    _computeCssClass() {
        return `cc-element-media--align-${this.params.align()} ${this.params.cssClass()}`;
    }

    imageClickAction() {
        if (this.linkUrl() === null || !this.linkUrl().startsWith('#')) {
            return true;
        }

        const linkElement = document.getElementById(this.linkUrl().substring(1));

        if (linkElement !== null) {
            linkElement.scrollIntoView({
                behavior: 'smooth',
                // this option is required by: https://github.com/WICG/EventListenerOptions/blob/gh-pages/explainer.md
                passive: true,
            });
        }

        return false;
    }

}
