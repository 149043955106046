import storageService from 'core/storage/sessionStorage';
import base64 from 'core/utils/base64';
import { STORAGE_RECOMMENDED_JOBS_METADATA } from 'minimal/component/recommended-jobs-widget/service/recommendedJobsService';
import localStorageService from 'core/storage/localStorage';
import { VERIFICATION_TOKEN_KEY } from 'candidate-verification/service/token';

type Education = {
    degreeName: string;
    educationalEstablishment: string;
    startDate: string;
    endDate: string;
    major?: string;
};

type WorkExperience = {
    jobTitle: string;
    employerName: string;
    startDate: string;
    endDate: string;
    achievements: string;
};

export const getVerificationTokenDetails = (): Record<string, string> =>
    storageService.restore(VERIFICATION_TOKEN_KEY) ||
    localStorageService.restore(VERIFICATION_TOKEN_KEY) || { candidateNumber: null };

export const prepareCandidateProfile = (): string | null => {
    const candidateProfileMetadata = storageService.restore(STORAGE_RECOMMENDED_JOBS_METADATA);

    if (!candidateProfileMetadata) {
        return null;
    }

    const skills = candidateProfileMetadata.skills?.join(' ') ?? '';

    const educations =
        candidateProfileMetadata.educations
            ?.map((education: Education) => {
                const { degreeName, major, educationalEstablishment, startDate, endDate } = education;

                return `${degreeName} ${major ?? ''} ${educationalEstablishment} ${startDate} - ${endDate}`;
            })
            .join('\\n') ?? '';

    const workExperiences =
        candidateProfileMetadata.workExperiences
            ?.map((workExperience: WorkExperience) => {
                const { jobTitle, employerName, startDate, endDate, achievements } = workExperience;

                return `${jobTitle} ${employerName} ${achievements} ${startDate} - ${endDate}`;
            })
            .join('\\n') ?? '';

    const candidateProfile = `EDUCATION\\n${educations}\\nEXPERIENCE\\n${workExperiences}\\nSKILLS\\n${skills}\\n`;

    const truncateCandidateProfile = candidateProfile.replace(/(\n|\r|\t)/gm, ' ');

    return base64.encode(truncateCandidateProfile);
};
