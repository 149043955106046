import i18n from 'core/i18n/i18n';
import { TileStyle, BaseThemeColor, TileVerticalAlignment } from './types';

export const TYLE_STYLE_OPTIONS = [
    {
        value: 'square' as TileStyle,
        label: i18n('category-tiles.admin.tile-style.square'),
    },
    {
        value: 'circle' as TileStyle,
        label: i18n('category-tiles.admin.tile-style.circle'),
    },
    {
        value: 'verticalPanel' as TileStyle,
        label: i18n('category-tiles.admin.tile-style.vertical-pannel'),
    },
];

export const BASE_THEME_COLOR_OPTIONS = [
    {
        value: 'minimal-dark' as BaseThemeColor,
        label: i18n('category-tiles.admin.base-theme-colors.minimal-dark'),
    },
    {
        value: 'minimal-light' as BaseThemeColor,
        label: i18n('category-tiles.admin.base-theme-colors.minimal-light'),
    },
];

export const TILE_VERTICAL_ALIGNMENT_OPTIONS = [
    {
        value: 'center' as TileVerticalAlignment,
        label: i18n('admin.site-editor.content-editor.center'),
    },
    {
        value: 'top' as TileVerticalAlignment,
        label: i18n('admin.site-editor.content-editor.top'),
    },
    {
        value: 'bottom' as TileVerticalAlignment,
        label: i18n('admin.site-editor.content-editor.bottom'),
    },
];

export const DEFAULT_CATEGORY_TILE_IMAGES = ['blue', 'orange', 'rose', 'plum', 'green', 'brown'];
