import store from 'apply-flow/service/submit-checklist/store/store';

export default class ApplyFlowSubmissionProgressViewModel {

    constructor() {
        this.state = store.state;
        this.progress = store.getters.progress;
    }

}
