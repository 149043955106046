import { ComponentsParams, DEFAULT_PARAMS_MAP } from './defaultParamsFactory';
import {
    CreateComponentData,
    FactoryComponentKey,
} from 'site-editor/module/content-editor/config/components';

type ComponentDataWithParams<Key extends FactoryComponentKey> = Omit<CreateComponentData<Key>, 'order'> & {
    order: number;
    params: ComponentsParams[Key];
    id: string;
};

export const createComponent = <Key extends FactoryComponentKey, ComponentType>(
    componentData: CreateComponentData<Key>,
    initComponent: (type: Key, data: ComponentDataWithParams<Key>) => ComponentType
): ComponentType => {
    const {
        params: { type },
        order,
        content,
        rows,
        columns,
        components,
        elementNumber,
    } = componentData;

    const componentDefaultParams = DEFAULT_PARAMS_MAP[type];
    const componentDataWithParams: ComponentDataWithParams<Key> = {
        elementNumber,
        params: {
            ...componentDefaultParams,
            ...componentData.params,
        },
        order: order ? parseInt(order) || 0 : 0,
        content,
        rows,
        columns,
        components,
        id: elementNumber || '0',
    };

    return initComponent(type, componentDataWithParams);
};
