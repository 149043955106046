import { bindingHandlers, utils } from 'knockout';
import candidateVerificationEvents from 'candidate-verification/config/events';

/**
 * Set focus to first number text element when resend pin action
 * is triggered and "Resend Code" button is changed to text with counter.
 *
 * @example
 * <div data-bind="setFocusWhenPinResend"></div>
 *
 */

bindingHandlers.setFocusWhenPinResend = {
    init(element) {
        function setFocus() {
            const firstChildInput = element.querySelector('[type="number"]');

            firstChildInput.focus();
        }

        const pinResendSignal = candidateVerificationEvents.pinResend.add(setFocus);

        utils.domNodeDisposal.addDisposeCallback(element, () => {
            pinResendSignal.detach();
        });
    },
};