import { getItemActiveSelector } from '../../../config/utils';

type CategoryTilesField =
    | 'wrapper'
    | 'container'
    | 'tileContainer'
    | 'tileTitle'
    | 'categoryTitleOnHover'
    | 'containerOnHover'
    | 'overallTileDisplay'
    | 'overallTileDisplayOnHover'
    | 'categoryTitleHoverStyle'
    | 'categoryTitleStyle';

type CategoryTilesFieldSelector = Record<CategoryTilesField, string>;

export const getCategoryTilesSelectors = (wrapperSelector: string): CategoryTilesFieldSelector => {
    return {
        wrapper: `${wrapperSelector}.category-tiles-wrapper`,
        container: `${wrapperSelector} .category-tiles`,
        tileContainer: `${wrapperSelector} .category-tiles .category-tile`,
        tileTitle: `${wrapperSelector} .category-tiles .category-tile`,
        categoryTitleOnHover: `${wrapperSelector} .category-tiles .category-tile:hover`,
        containerOnHover: `${wrapperSelector} .category-tiles:hover`,
        overallTileDisplayOnHover: [
            getItemActiveSelector(
                `${wrapperSelector} .category-tiles.category-tiles--minimal-dark .category-tile`,
                ''
            ),
            getItemActiveSelector(`${wrapperSelector} .category-tiles .category-tile`, ''),
        ].join(','),
        overallTileDisplay: [
            `${wrapperSelector} .category-tiles.category-tiles--minimal-dark .category-tile`,
            `${wrapperSelector} .category-tiles .category-tile`,
        ].join(','),
        categoryTitleStyle: `${wrapperSelector} .category-tiles .category-tile .category-tile__title`,
        categoryTitleHoverStyle: `${wrapperSelector} .category-tiles .category-tile .category-tile__title:hover`,
    };
};
