import ko from 'knockout';
import TimelineProfileItemsViewModel from './TimelineProfileItemsViewModel';

export default class WorkAndEducationTimelineViewModel {

    constructor(params) {
        const { blockProperties } = params;

        const [experienceContentTypeId, educationContentTypeId] = blockProperties.contentTypeId;
        const [experienceSectionId, educationSectionId] = blockProperties.sectionId;

        const [experienceMetadataServiceUrl, educationMetadataServiceUrl] = params.metadataServiceUrl;

        this.educationViewModel = new TimelineProfileItemsViewModel(ko.utils.extend(Object.create(params), {
            blockProperties: {
                contentTypeId: educationContentTypeId,
                sectionId: educationSectionId,
            },
            metadataServiceUrl: educationMetadataServiceUrl,
        }));

        this.experienceViewModel = new TimelineProfileItemsViewModel(ko.utils.extend(Object.create(params), {
            blockProperties: {
                contentTypeId: experienceContentTypeId,
                sectionId: experienceSectionId,
            },
            metadataServiceUrl: experienceMetadataServiceUrl,
        }));

        this.isEducationValid = this.educationViewModel.isValid;
        this.isExperienceValid = this.experienceViewModel.isValid;

        this.isValid = ko.pureComputed(() => {
            const isEducationValid = this.isEducationValid();
            const isExperienceValid = this.isExperienceValid();

            return isEducationValid && isExperienceValid;
        });

        this.showValidation = ko.observable(false);

        this.isValidForValidator = ko.pureComputed(() => {
            const isValidationActive = this.showValidation();
            const isValid = this.isValid();

            return isValidationActive ? isValid : true;
        });

        this.addButtonsInViewport = ko.observable();
        this.buttonsPinned = ko.pureComputed(() => !this.addButtonsInViewport());
    }

    enableValidation() {
        this.showValidation(true);
    }

    dispose() {
        this.isValidForValidator.dispose();
        this.isValid.dispose();
        this.educationViewModel.dispose();
        this.experienceViewModel.dispose();
    }

}