import { Observable, observable, PureComputed, pureComputed, Subscription } from 'knockout';
import router from 'app/model/router';
import i18n from 'core/i18n/i18n';
import a11yEvents from 'core/a11y/events';
import tokenService from 'candidate-verification/service/token';
import candidateService from 'apply-flow/service/candidate';
import candidateModel from 'apply-flow/model/candidate';
import flowService from 'apply-flow/service/flow';
import profileItemsDefaultBlockFinder from 'apply-flow/module/profile-items/service/defaultBlockFinder';
import sectionState from 'apply-flow/model/sectionState';
import sectionValidator from 'app/module/cx/module/apply-flow/model/sectionValidator';
import notificationsService from 'cx/service/notifications';
import cxEvents from 'cx/config/events';
import applyFlowEvents from 'apply-flow/config/events';
import LegalDisclaimer from 'app/module/cx/module/apply-flow/model/LegalDisclaimer';
import {
    clearLastAcceptedLegalDisclaimer,
    isLastLegalDisclaimerAcceptedFor,
} from './service/eventRegisterFlowLocalStorage';
import { SignalBinding } from 'signals';
import profileItemServiceFactory from 'app/module/cx/module/apply-flow/module/profile-items/service/profileItemServiceFactory';
import customJsValidationService from 'app/module/cx/module/apply-flow/module/custom-js-validation/service/customJsValidation';
import Application from 'app/module/cx/module/apply-flow/model/Application';
import Flow from 'app/module/cx/module/apply-flow/model/Flow';
import FlowIterator from 'app/module/cx/module/apply-flow/model/FlowIterator';
import { SourceTraceDataEventContext } from './config/types';
import { EventRegisterFlowModel, RouterParams } from './config/types';
import sourceTraceService from 'cx/module/source-tracking/service/sourceTrace';
import referrerService from 'source-tracing/service/referrer';
import { getClaimedLastName } from './components/candidate-verification/service/lastNameVerifier';
import { getClaimedDateOfBirth } from './components/candidate-verification/service/dateOfBirthVerifier';
import {
    storeInitialCommunicationChannelsValue,
    setInitialEmail,
    setInitialPhone,
} from 'app/module/cx/module/apply-flow/model/initialCommunicationChannels';
import { EMAIL, SMS } from 'candidate-verification/config/verificationMethods';

export default class EventRegisterViewModel {
    private flow: Observable<Flow | undefined>;
    private application: Observable<void>; // retaining it for reusing the apply flow for event registration
    private legalDisclaimer: Observable<LegalDisclaimer>;
    private acceptAgreementCallback: VoidFunction;
    private summaryComponentName: string;
    private summaryTemplate: Observable<string>;
    private closeAction: VoidFunction;
    private eventId: string;
    private currentPageSub!: Subscription;
    private flowIterator: PureComputed<FlowIterator>;
    private legalDisclaimerAcceptanceRequired: Observable<boolean>;
    private isSingleClickFlow: PureComputed<boolean>;
    private isFlowVisible: Observable<boolean>;
    private isValidationInProgress: Observable<boolean>;
    private isProfileImportLoading: Observable<boolean>;
    private profileImportLoadingSignal: SignalBinding<() => void>;
    private profileImportSucceedSignal: SignalBinding<() => void>;

    constructor() {
        this.eventId = (router.routeParams() as RouterParams).eventId;
        this.flow = observable();
        this.application = observable(Application());
        this.legalDisclaimer = observable({} as LegalDisclaimer);
        this.legalDisclaimerAcceptanceRequired = observable<boolean>(false);
        this.acceptAgreementCallback = this.acceptAgreement.bind(this);
        this.isFlowVisible = observable<boolean>(true);
        this.isValidationInProgress = observable<boolean>(false); // is used in pagination
        this.summaryComponentName = 'event-register-flow-summary';
        this.summaryTemplate = observable().extend({ notify: 'always' });
        this.closeAction = this.exitRegisterFlow.bind(this);
        this.isProfileImportLoading = observable<boolean>(false);

        this.profileImportLoadingSignal = applyFlowEvents.profileImportLoading.add(() => {
            this.isProfileImportLoading(true);
        });

        this.profileImportSucceedSignal = applyFlowEvents.profileImportSucceed.add(() => {
            this.isProfileImportLoading(false);
        });

        cxEvents.loading.dispatch();

        this.flowIterator = pureComputed(() => {
            const flow = this.flow();

            return flow ? flow.iterator() : undefined;
        });

        this.isSingleClickFlow = pureComputed(() => {
            const iterator = this.flowIterator();

            return iterator ? iterator.isSingleClick() : false;
        });

        profileItemServiceFactory.initialize();
        customJsValidationService.checkValidators();

        this.loadCandidate()
            .then(this.loadFlow.bind(this))
            .then(this.determineDefaultProfileItemsBlock.bind(this))
            .then(this.initializeSectionState.bind(this))
            .then(this.initializeSectionValidator.bind(this))
            .then(this.initializeLegalDisclaimer.bind(this))
            .then(this.renderFlow.bind(this))
            .then(this.validateSectionsAssessmentBack.bind(this))
            .catch((error: string) => this.handleError(error));

        this.createSourceTrace();
        applyFlowEvents.submitSucceed.add(() => this.registerSucceed());
        applyFlowEvents.submitFailed.add(() => this.registerFailed());
        applyFlowEvents.afterCommunicationChannelChange.add(this.onCommunicationChannelChanged.bind(this));

        storeInitialCommunicationChannelsValue();
    }

    private exitRegisterFlow(): void {
        const route = router.route();

        if (route.parent && route.parent.id === 'event-preview') {
            router.go('event-preview', {
                eventId: this.eventId,
            });
        } else {
            router.go('event-details', { eventId: this.eventId });
        }
    }

    private loadCandidate(): Promise<void> {
        const token = tokenService.get();

        return candidateService
            .loadForToken(candidateModel, token)
            .then(() => {
                if (getClaimedLastName()) {
                    candidateModel.basicInformation.lastName(getClaimedLastName());
                }

                if (getClaimedDateOfBirth()) {
                    candidateModel.basicInformation.dateOfBirth(getClaimedDateOfBirth());
                }
            })
            .catch(() => {
                console.error('Unable to create candidate model.');
            });
    }

    private loadFlow(): EventRegisterFlowModel {
        return flowService.queryByEventNumber(this.eventId);
    }

    private determineDefaultProfileItemsBlock(flow: EventRegisterFlowModel): EventRegisterFlowModel {
        profileItemsDefaultBlockFinder.find(flow);

        return flow;
    }

    private initializeSectionState(flow: EventRegisterFlowModel): EventRegisterFlowModel {
        sectionState.initialize(flow.sections);

        return flow;
    }

    private initializeSectionValidator(flow: EventRegisterFlowModel) {
        sectionValidator.initialize(flow.sections);

        return flow;
    }

    private renderFlow(flow: EventRegisterFlowModel) {
        return Promise.resolve(flow)
            .then(this.flow.bind(this))
            .then(this.notifySubscribers.bind(this))
            .then(this.subscribeForPageChange.bind(this))
            .then(cxEvents.loaded.dispatch);
    }

    private subscribeForPageChange(): void {
        this.currentPageSub = this.flowIterator().currentSection.subscribe(() => {
            this.notifySubscribers();
        }, this);
    }

    private notifySubscribers(): void {
        const iterator = this.flowIterator();
        const currentSection = iterator.currentSection();

        a11yEvents.status.dispatch(
            i18n('apply-flow.a11y.section-loaded', {
                sectionname: currentSection.title,
            })
        );
    }

    private validateSectionsAssessmentBack(): void {
        const { assessmentSection, element } = router.routeParams() as RouterParams;
        const isAllSectionsReady = sectionState.isAllSectionsReady();

        if (!element) {
            return;
        }

        isAllSectionsReady.subscribeOnce(() => {
            for (let i = 1; i < assessmentSection; i++) {
                sectionState.setSectionVisited(i);
                sectionValidator.validateSection(i);
            }
        });
    }

    private handleError(error: string): void {
        console.error(error);

        notificationsService.error();
    }

    private createSourceTrace(): Promise<SourceTraceDataEventContext | void> {
        if (sourceTraceService.shouldTraceSourceForEvent(this.eventId)) {
            const sourceTraceData: Record<string, string> = {
                sourceLevel: sourceTraceService.LEVEL.EVENT_REGISTRATION,
                eventNumber: this.eventId,
                tokenId: tokenService.get().tokenId,
            };

            if (!referrerService.get()) {
                sourceTraceData.source = 'event';
                sourceTraceData.sourceMedium = 'career site';
            }

            return sourceTraceService.create(sourceTraceData);
        }

        return Promise.resolve();
    }

    private initializeLegalDisclaimer(flow: EventRegisterFlowModel): EventRegisterFlowModel {
        if (this.shouldCandidateAcceptLegalDisclaimer(flow.legalDisclaimer)) {
            this.legalDisclaimer(flow.legalDisclaimer);
            this.legalDisclaimerAcceptanceRequired(true);
            this.isFlowVisible(false);
        }

        return flow;
    }

    private shouldCandidateAcceptLegalDisclaimer(legalDisclaimer: LegalDisclaimer): boolean {
        const isAlreadyAccepted = this.getLegalDisclaimerAcceptanceFromStorage();

        return legalDisclaimer.isEnabled && !isAlreadyAccepted;
    }

    private acceptAgreement(): void {
        this.legalDisclaimerAcceptanceRequired(false);
        this.isFlowVisible(true);
    }

    private getLegalDisclaimerAcceptanceFromStorage(): boolean {
        const candidateHasAcceptedLegalDisclaimer = isLastLegalDisclaimerAcceptedFor(this.eventId);

        clearLastAcceptedLegalDisclaimer();

        return candidateHasAcceptedLegalDisclaimer;
    }

    private dragEnterHandler(context: Window, event: Event): void {
        event.stopPropagation();
        event.preventDefault();
        applyFlowEvents.dragEnter.dispatch();
    }

    private registerSucceed(): void {
        this.isFlowVisible(false);
    }

    private registerFailed(): void {
        this.isFlowVisible(false);
    }

    dispose(): void {
        if (this.currentPageSub) {
            this.currentPageSub.dispose();
        }

        this.unRegisterSignalHandlers();

        this.flow()?.destroy();
    }

    private unRegisterSignalHandlers(): void {
        if (this.profileImportLoadingSignal) {
            this.profileImportLoadingSignal.detach();
        }

        if (this.profileImportSucceedSignal) {
            this.profileImportSucceedSignal.detach();
        }

        Object.keys(applyFlowEvents).forEach((signalName) => {
            applyFlowEvents[signalName as keyof typeof applyFlowEvents].removeAll();
        });
    }

    private onCommunicationChannelChanged(verificationMethod: string, value: string): void {
        if (verificationMethod === EMAIL) {
            setInitialEmail(value, Boolean(value));
            candidateModel.basicInformation.verificationMethod(EMAIL);
        } else {
            setInitialPhone(value, Boolean(value));
            candidateModel.basicInformation.verificationMethod(SMS);
        }

        tokenService.setCandidateCommunicationChannel(verificationMethod, value);
    }
}
