import { RegistrationPhase } from 'app/module/minimal/module/event-details/config/types';
import applicationService from './application';

export type CachedCandidateEventRegistration = {
    id: string;
    confirmedFlag: boolean;
    registrationPhase: RegistrationPhase;
};

class CachedCandidateRegistrations {
    private candidateEventsRegistrations: CachedCandidateEventRegistration[] = [];
    private isDataSet = false;

    async get(): Promise<CachedCandidateEventRegistration[]> {
        if (this.isDataSet) {
            return this.candidateEventsRegistrations;
        }

        this.candidateEventsRegistrations = await applicationService.queryCandidateEventsRegistrations();
        this.isDataSet = true;

        return this.candidateEventsRegistrations;
    }

    clear(): void {
        this.isDataSet = false;
        this.candidateEventsRegistrations = [];
    }
}

export const cachedCandidateRegistrations = new CachedCandidateRegistrations();
