import tokenService from 'candidate-verification/service/token';
import sessionPersistence from './sessionPersistence';
import sourceTrace from 'cx/module/source-tracking/service/sourceTrace';
import userTracking from 'cx/service/userTracking';
import profileImport from '../../../apply-flow/module/profile-import/service/profileImport';

export default class GlobalSession {

    async extend() {
        try {
            if (sessionPersistence.isActive()) {
                return await tokenService.extendAccess();
            }

            return Promise.reject();
        } catch (error) {
            this.cleanup();

            return Promise.reject(error.message || error);
        }
    }

    cleanup() {
        tokenService.destroy();
        sourceTrace.removeParentSourceTrackingId();
        sessionPersistence.deactivate();
        userTracking.clearUserTrackingCookie();
        profileImport.clearIndeedProfileFromCache();
    }

}