import { isFunctionalCookieAllowed } from 'cx/service/cookieAgreement';
import cookieStorage from 'core/cookie/storage';
import signals, { USER_DECLINED_COOKIE_CONSENT_FUNCTIONAL } from 'cx/config/userEvents';

const COOKIE_NAME = 'ORA_CX_SITE_LANG';

function set(lang) {
    if (isFunctionalCookieAllowed()) {
        cookieStorage.set(COOKIE_NAME, lang, { days: 7 });
    }
}

function remove() {
    cookieStorage.remove(COOKIE_NAME);
}

signals[USER_DECLINED_COOKIE_CONSENT_FUNCTIONAL].add(remove);

export default {
    set,
    remove,
};