import BaseCsApplicationsViewModel from 'candidate-self-service/component/cs-applications/CsApplicationsViewModel';
import { PureComputed, pureComputed } from 'knockout';
import { isNoDataLoaded } from '../../service/isNoDataLoaded';

export default class CsApplicationsViewModel extends BaseCsApplicationsViewModel {
    showNoDataComponent: PureComputed<boolean>;

    constructor() {
        super();

        this.showNoDataComponent = pureComputed(() => isNoDataLoaded(this.stateArray));
    }
}
