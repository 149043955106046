import { Observable, PureComputed, pureComputed, observable } from 'knockout';
import { redirectToSearchBasedOnViewBy } from '../../service/recommendedJobsService';
import { getCustomStyles } from '../../config/customStyles';
import { RecommendedJobsWidgetParams } from '../../config/types';
import { getMessages } from '../../service/messages';
import siteLanguage from 'ce-common/service/language/siteLanguage';
import storageService from 'core/storage/sessionStorage';
import {
    STORAGE_RECOMMENDED_JOBS_METADATA,
    DEFAULT_CLASS_ID,
} from 'minimal/component/recommended-jobs-widget/service/recommendedJobsService';
import { USER_NAME_TOKEN } from '../../config/messages';
import { noOperation } from 'core/utils/noOperation';
import { mapParamsConfigurationToObservable } from 'minimal/module/search/service/customCss';
import { emptyParams } from 'minimal/component/recommended-jobs-widget/config/emptyParams';
import { RecommendedJobsWidgetViewModel } from '../../RecommendedJobsWidgetViewModel';
import notificationsService from 'cx/service/notifications';
import i18n from 'core/i18n/i18n';
import recommendedJobsEvents from 'minimal/config/events';
import screenInfo from 'cx/model/screenInfo';

type Props = {
    recommendedJobsParams: {
        params?: RecommendedJobsWidgetParams;
        id?: string;
        adminMode?: boolean;
        lang?: Observable<string>;
    };
};

const RECOMMENDED_JOBS_META_DATA = 'storage_recommended_jobs_metadata';

export class RecommendedJobsWidgetSuccessBannerViewModel extends RecommendedJobsWidgetViewModel {
    successMessageText: PureComputed<string>;
    customCss: PureComputed<string>;
    uniqueWrapperClass: string;
    adminMode: boolean | undefined;
    customizationParams: RecommendedJobsWidgetParams;
    isManageActionMenuVisible: Observable<boolean>;
    showResumeDeletePopup: Observable<boolean>;
    showSuccessToast: Observable<boolean>;
    isSmallScreen: PureComputed<boolean>;

    constructor({ recommendedJobsParams: { params, adminMode, id, lang } }: Props) {
        super({ id, params, mode: '', lang });

        const recommendedJobsMetaData = storageService.restore(STORAGE_RECOMMENDED_JOBS_METADATA);
        const languageCode = lang ? lang() : siteLanguage.getFusionCode();

        this.customizationParams = params || mapParamsConfigurationToObservable(emptyParams);
        this.adminMode = adminMode;
        this.uniqueWrapperClass = `component-styling-wrapper-${id ?? DEFAULT_CLASS_ID}`;
        this.showResumeDeletePopup = observable<boolean>(false);
        this.isManageActionMenuVisible = observable<boolean>(false);
        this.showSuccessToast = observable<boolean>(false);
        this.isSmallScreen = pureComputed(() => screenInfo.isSmall());

        const messages = getMessages(this.customizationParams, languageCode);

        this.successMessageText = pureComputed(() =>
            messages.successMessageText().replace(USER_NAME_TOKEN, recommendedJobsMetaData?.displayName ?? '')
        );

        this.customCss = pureComputed(() =>
            getCustomStyles(this.customizationParams as RecommendedJobsWidgetParams, this.uniqueWrapperClass)
        );
    }

    showAllJobs(): void {
        if (this.adminMode) {
            return noOperation();
        }

        redirectToSearchBasedOnViewBy('SEARCH_ALL_JOBS');
        recommendedJobsEvents.resumeReuploadEvent.dispatch(false);
    }

    deleteMetaData(): void {
        if (this.adminMode) {
            return noOperation();
        }

        this.isManageActionMenuVisible(false);
        this.showResumeDeletePopup(true);
        this.showSuccessToast(false);
    }

    hideManageActionMenu(): void {
        this.isManageActionMenuVisible(false);
    }

    toggleManageActionMenu(): void {
        this.isManageActionMenuVisible(!this.isManageActionMenuVisible());
    }

    deleteMetaDataAction(): void {
        storageService.remove(RECOMMENDED_JOBS_META_DATA);
        this.showResumeDeletePopup(false);
        recommendedJobsEvents.resumeReuploadEvent.dispatch(false);
        redirectToSearchBasedOnViewBy('SEARCH_ALL_JOBS');
        this.showSuccessToast(true);

        notificationsService.success(
            i18n('recommended-jobs-widget.messages.delete-confirmation-toast-message')
        );
    }

    deletePopupCloseAction(): void {
        this.showResumeDeletePopup(false);
    }

    onReupload(_: RecommendedJobsWidgetViewModel | FileList, event: Event): void {
        this.isManageActionMenuVisible(false);
        this.onFileSelected(_, event, true);
    }
}
