import { observable } from 'knockout';

const blocksWithNoHeader = [
    'ORA_SUMMARY',
    'ORA_PROFILE_IMPORT',
];

export default class ApplyFlowBlockViewModel {

    constructor(params) {
        this.application = params.application;
        this.section = params.section;
        this.block = params.block;
        this.isActive = params.isActive;
        this.isSingleClickFlow = params.isSingleClickFlow;
        this.flow = params.flow;
        this.sectionDisabled = observable(false);

        this.shouldRenderBlockHeader = () => blocksWithNoHeader.indexOf(this.block.code) === -1;
        this.blockAriaLabel = `${this.block.title || ''} ${this.block.instructions || ''}`;
    }

}