import { observable } from 'knockout';
import notificationsService from 'cx/service/notifications';
import tokenService from 'candidate-verification/service/token';
import talentCommunityEvents from './config/events';
import router from 'app/model/router';
import CandidateVerificationAbstractViewModel from 'candidate-verification/component/email-verification/CandidateVerificationAbstractViewModel';
import historyService from 'ce-common/service/historyService';
import { LAST_NAME, DATE_OF_BIRTH } from 'app/module/cx/module/candidate-verification/config/claimVerificationChecks';
import { TC_CANDIDATE, CLAIM_EMAIL } from 'candidate-verification/config/verificationSubmodules';
import lastNameFormBuilder from 'candidate-verification/component/email-verification/service/lastNameFormBuilder';
import dateOfBirthFormBuilder from 'candidate-verification/component/email-verification/service/dateOfBirthFormBuilder';
import sourceTraceService from 'source-tracing/service/sourceTrace';
import LegalDisclaimerForm from 'apply-flow/module/agreements/component/legal-disclaimer/form/LegalDisclaimerForm';
import legalDisclaimerService from 'apply-flow/service/legalDisclaimer';
import { INTERNAL_CANDIDATE, INVALID_COOKIE, LAST_NAME_CHECK, DATE_OF_BIRTH_CHECK } from 'candidate-verification/config/candidateVerificationStatus';
import { ATTEMPS_LIMIT_REACHED, PIN_LIMIT_REACHED } from 'candidate-verification/config/pinVerificationStatus';
import storageService from 'core/storage/sessionStorage';
import userTracking from 'app/module/cx/service/userTracking';
import idleTimerService from 'candidate-verification/service/idleTimer';
import tokenVerification from 'candidate-verification/component/email-verification/service/tokenVerification';

const TC_CANDIDATE_TYPE = 'isReturningCandidate';

const KNOWN_ERROR_TYPES = ['pin-code-required', 'token-verification', LAST_NAME_CHECK, DATE_OF_BIRTH_CHECK];

export default class TalentCommunityViewModel extends CandidateVerificationAbstractViewModel {

    constructor() {
        super();

        this.visible = observable(this._shouldOpenDialogForDeeplink());
        this.template = observable(null);
        this.isLoading = observable(false);
        this.hasFocus = observable(false);
        this.requestErrorType = observable(null);
        this.lastNameNotMatched = observable(false);
        this.dateOfBirthNotMatched = observable(false);

        // SMS verification disabled for Talent community
        this.isSMSVerificationEnabled = false;
        this.challenge.submodule = TC_CANDIDATE;

        this._setTemplate();

        this._loadLegalDisclaimer();

        this.show = this.show.bind(this);
        this.hide = this.hide.bind(this);

        this._showConfirmedMessage = this._showConfirmedMessage.bind(this);
        this._showChallenge = this._showChallenge.bind(this);
        this._onSubmitFailed = this._onSubmitFailed.bind(this);

        talentCommunityEvents.show.add(this.show);
        talentCommunityEvents.hide.add(this.hide);

        talentCommunityEvents.userConfirmed.add(this._showConfirmedMessage);
        talentCommunityEvents.challengeRequired.add(this._showChallenge);

        talentCommunityEvents.submitFailed.add(this._onSubmitFailed);

        this.lastName = observable();
        this.lastNameForm = lastNameFormBuilder.createForm(this.lastName);
        this.showLastNameMismatchMessage = observable(false);
        this.showClaimMessage = true;
        this.isLastNameCheck = observable(false);

        this.dateOfBirth = observable();
        this.dateOfBirthForm = dateOfBirthFormBuilder.createForm(this.dateOfBirth);
        this.showDateOfBirthMismatchMessage = observable(false);
        this.isDateOfBirthCheck = observable(false);

        this.cssRoute = 'candidate-self-service';
    }

    show() {
        this._setTemplate();

        this.visible(true);
        this.hasFocus(true);
    }

    hide() {
        this.visible(false);

        historyService.moveBackOrFallbackTo('search', { jobId: null });
    }

    verify() {
        this._createToken();
    }

    subscribe() {
        userTracking.trackJobAppEntryNext(router.routeParams().jobId, router.route().id);

        const form = this.activeForm();

        form.enableImmediateValidation();
        this._enableLegalDisclaimerValidation();

        form.validate()
            .then((isFormValid) => {
                if (!isFormValid || !this._isLegalDisclaimerEmptyOrValid()) {
                    return;
                }

                this.beforeCaptchaVerify();
            });
    }

    dispose() {
        if (this._visibleSubscribe) {
            this._visibleSubscribe.dispose();
        }

        talentCommunityEvents.show.remove(this.show);
        talentCommunityEvents.hide.remove(this.hide);
    }

    loginToCSS() {
        if (tokenService.isKnownCandidate()) {
            return router.go(this.cssRoute);
        }

        return this._showChallenge(this.challenge);
    }

    verifyUser() {
        return this._createToken();
    }

    onPinValid() {
        if (tokenService.isKnownCandidate()) {
            return router.go(this.cssRoute);
        }

        return this.template('talent-community-sign-in');
    }

    onAttempsLimitReached() {
        this.hide();
    }

    _resetTemplate() {
        this.template(null);
    }

    _submitSourceTracking() {
        const defaultParams = {
            sourceLevel: 'CANDIDATE',
            source: 'talent community',
            sourceMedium: 'career site',
        };

        return sourceTraceService.createWithoutStorage(defaultParams);
    }

    _createTokenWithSourceTracking(sourceTrace) {
        return tokenService.create({
            candidate: this.candidate,
            submodule: this.challenge.submodule,
            sourceTrace,
            lastName: this.lastName(),
            dateOfBirth: this.dateOfBirth(),
            captchaToken: this.captchaToken(),
            honeyPot: this.honeyPot(),
        });
    }

    _createToken() {
        this.isLoading(true);

        return this._submitSourceTracking()
            .then(this._createTokenWithSourceTracking.bind(this))
            .then(this._checkCandidate.bind(this))
            .catch(this._showError.bind(this));
    }

    _checkCandidate(token) {
        this.isLoading(false);
        this.lastNameNotMatched(false);
        this.dateOfBirthNotMatched(false);

        if (token.accessCode) {
            this.template('talent-community-sign-in');

            storageService.store(TC_CANDIDATE_TYPE, false);
            idleTimerService.init();

            return;
        }

        if (!token.cookieMatchedFlag) {
            if (token.nextField === DATE_OF_BIRTH) {
                storageService.store(TC_CANDIDATE_TYPE, false);
                this.dateOfBirthNotMatched(true);
                this.isDateOfBirthCheck(true);
                this.template('talent-community-date-of-birth-check');
                // this.captchaToken(errorObject?.params?.captchaToken || null); todo
            } else if (!token.challengeFlag || token.nextField === LAST_NAME) {
                storageService.store(TC_CANDIDATE_TYPE, false);
                this.lastNameNotMatched(true);
                this.isLastNameCheck(true);
                // this.captchaToken(errorObject?.params?.captchaToken || null); todo
                this.template('talent-community-last-name-check');
            }
        } else if (token.challengeFlag) {
            storageService.store(TC_CANDIDATE_TYPE, true);
            this.template('talent-community-user-exist');
        }
    }

    _showConfirmedMessage() {
        this.template('talent-community-user-subscribed');
    }

    _showChallenge(challenge) {
        this._setChallenge(challenge);
        this.template('talent-community-pin-code-required');
    }

    _setChallenge(challenge) {
        this.challenge.candidate = challenge.candidate;
        this.challenge.actions = challenge.actions;
    }

    _showError(error) {
        this.isLoading(false);

        if (error === INTERNAL_CANDIDATE) {
            return;
        }

        if (error === ATTEMPS_LIMIT_REACHED || error === PIN_LIMIT_REACHED) {
            this._handleAttempsLimitReachedError();
        } else if (error === INVALID_COOKIE) {
            if (this.dateOfBirth()) {
                this.showDateOfBirthMismatchMessage(true);
            } else {
                this.showLastNameMismatchMessage(true);
            }
        } else if (error === 'pin-code-required') {
            storageService.store(TC_CANDIDATE_TYPE, true);
            this.template('talent-community-user-exist');
        } else if (KNOWN_ERROR_TYPES.includes(error)) {
            this.requestErrorType(error);
        } else if (typeof error === 'string') {
            notificationsService.error(error, 0);
        } else {
            notificationsService.error();
            console.error(error);

            throw new Error(error);
        }
    }

    _onSubmitFailed(error) {
        talentCommunityEvents.updateFailed.dispatch();

        if (error === 'candidate-exists') {
            this.template('talent-community-concurent-tab-issue');

            return;
        }

        this._showError(error);
    }

    _shouldOpenDialogForDeeplink() {
        return router.isActive('talent-community');
    }

    _loadLegalDisclaimer() {
        this.isLoading(true);

        return legalDisclaimerService.queryTCLegalDisclaimer()
            .then((legalDisclaimer) => {
                this.legalDisclaimer(legalDisclaimer);
                this.legalDisclaimerForm(new LegalDisclaimerForm(this.legalDisclaimer().isAccepted));
                this.isLoading(false);
            })
            .catch(this._showError);
    }

    _setTemplate() {
        if (tokenService.isKnownCandidate()) {
            this.template('talent-community-user-authorized');
        } else if (tokenService.accessCodeExists()) {
            this.template('talent-community-sign-in');
        } else {
            this._createForms();

            if (!this._visibleSubscribe) {
                this._visibleSubscribe = this.visible.subscribe(this._resetTemplate, this);
            }
        }
    }

    _handleAttempsLimitReachedError() {
        this.verificationStatus(ATTEMPS_LIMIT_REACHED);
        this.template('talent-community-pin-code-required');
    }

    redirectBackToEmailVerification() {
        this.lastNameNotMatched(false);
        this.dateOfBirthNotMatched(false);
        this.showLastNameMismatchMessage(false);
        this.showDateOfBirthMismatchMessage(false);
        this.lastName(undefined);
        this.dateOfBirth(null);
        this.setVerificationMethod(this.candidate.verificationMethod());
        this.isLastNameCheck(false);
        this.isDateOfBirthCheck(false);
        this._resetView();
    }

    verifyLastName() {
        this.lastNameForm.enableImmediateValidation();

        this.lastNameForm.validate()
            .then((isFormValid) => {
                if (!isFormValid) {
                    return;
                }

                this.isLoading(true);

                tokenVerification.verifyLastName({
                    jobId: this.jobId,
                    candidate: this.candidate,
                    submodule: this.challenge.submodule,
                    lastName: this.lastName(),
                    captchaToken: this.captchaToken(),
                })
                    .then(this._resetView.bind(this))
                    .catch(this._showError.bind(this));
            });
    }

    verifyDateOfBirth() {
        this.dateOfBirthForm.enableImmediateValidation();

        this.dateOfBirthForm.validate()
            .then((isFormValid) => {
                if (!isFormValid) {
                    return;
                }

                this.isLoading(true);

                tokenVerification.verifyDateOfBirth({
                    jobId: this.jobId,
                    candidate: this.candidate,
                    submodule: this.challenge.submodule,
                    dateOfBirth: this.dateOfBirth(),
                    captchaToken: this.captchaToken(),
                })
                    .then(this._resetView.bind(this))
                    .catch(this._showError.bind(this));
            });
    }

    claimEmail() {
        this.isLoading(true);
        this.challenge.submodule = CLAIM_EMAIL;

        return tokenVerification.claimEmail({ candidate: this.candidate })
            .then(this._resetView.bind(this))
            .catch(this._showError.bind(this));
    }

    _resetView() {
        this.isLoading(false);
        this.requestErrorType(null);
        this._resetTemplate();
    }

}