import { CategoryTilesCustomizationParams } from './types';
import { EMPTY_TYPOGRAPHY } from 'site-editor/module/content-editor/config/emptyTypography';
import { EMPTY_COMMON_PARAMS } from 'site-editor/module/content-editor/config/emptyCommonParams';
import { EMPTY_BLOCK_STYLES } from 'site-editor/module/content-editor/config';
import {
    BASE_THEME_COLOR_OPTIONS,
    TILE_VERTICAL_ALIGNMENT_OPTIONS,
    TYLE_STYLE_OPTIONS,
} from './defaultParams';

export const emptyParams: CategoryTilesCustomizationParams = {
    content: {
        width: 300,
        widthUnit: 'px',
        height: 300,
        heightUnit: 'px',
        tileStyle: TYLE_STYLE_OPTIONS[0].value,
        baseThemeColor: BASE_THEME_COLOR_OPTIONS[0].value,
        tileVerticalAlignment: TILE_VERTICAL_ALIGNMENT_OPTIONS[0].value,
    },
    categoryTitleTypography: { ...EMPTY_TYPOGRAPHY },
    categoryTitleHoverTypography: { ...EMPTY_TYPOGRAPHY },
    containerStyling: { ...EMPTY_BLOCK_STYLES },
    containerHoverStyling: { ...EMPTY_BLOCK_STYLES },
    categoryTitleHoverStyling: { ...EMPTY_BLOCK_STYLES },
    categoryTitleStyling: { ...EMPTY_BLOCK_STYLES },
    categoryTileStyling: { ...EMPTY_BLOCK_STYLES },
    categoryTileHoverStyling: { ...EMPTY_BLOCK_STYLES },
    overallStyling: { ...EMPTY_BLOCK_STYLES },
    commonParams: { ...EMPTY_COMMON_PARAMS },
};
