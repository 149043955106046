import { emptyParams } from './config/emptyParams';
import { mapParamsConfigurationToObservable } from 'minimal/module/search/service/observableParams';
import { CustomComponentViewModel } from 'minimal/component/custom-component/CustomComponentViewModel';
import { PureComputed, pureComputed } from 'knockout';
import { COOKIE_CONSENT_WIDGET_TYPE, CookieConsentWidgetData } from '../../types';
import {
    acceptAll,
    closeCookieConsentDialogs,
    cookieConfig,
    declineAll,
    savePreferences,
    showCookiePolicyOrPreferenceDialog,
} from 'cx/service/cookieAgreement';
import {
    CookieConsentModalActionsCustomizationParams,
    CookieConsentModalActionsParams,
} from './config/types';
import { getCustomStylesCookie } from './service/customStyles';
import { getCookieConsentActionsSelectors } from './config/selectors';
import screenInfo from 'cx/model/screenInfo';
import { isAdminRoute } from 'core/router/service/isAdminRoute';
import {
    COOKIE_CONSENT_NARROW_STYLE,
    COOKIE_CONSENT_WIDE_STYLE,
} from 'site-editor/module/cookie-consent-editor/component/cookie-consent-widget-options-dialog/types';
import { BUTTON_STYLE, ButtonSize } from 'site-editor/module/cookie-consent-editor/config/types';
import tokenService from 'candidate-verification/service/token';

type Props = {
    pageData: PureComputed<CookieConsentWidgetData>;
    params?: CookieConsentModalActionsParams;
    id?: string;
};

export class CookieConsentModalActionsViewModel extends CustomComponentViewModel<CookieConsentModalActionsParams> {
    pageData: PureComputed<CookieConsentWidgetData>;
    cookieConfig = cookieConfig;
    isDeclineButtonEnabled: PureComputed<boolean | undefined>;
    isSavePreferencesButtonEnabled: PureComputed<boolean | undefined>;
    isCookiePreferenceLinkEnabled: PureComputed<boolean | undefined>;
    isCookiePreferenceButtonEnabled: PureComputed<boolean | undefined>;
    template: string;
    isMediumUpScreen = pureComputed(() => screenInfo.isMediumUp());
    buttonSize: PureComputed<ButtonSize>;
    initalCategorySelectionValues: boolean[] = [];

    constructor({ pageData, ...customComponentProps }: Props) {
        super({
            ...customComponentProps,
            defaultParams: mapParamsConfigurationToObservable(emptyParams),
        });

        this.pageData = pageData;

        this.buttonSize = pureComputed(() => this.getButtonSizeBasedOnModalStyle());

        this.isDeclineButtonEnabled = pureComputed(() => this.pageData().isDeclineButtonEnabled?.(), this);

        this.isSavePreferencesButtonEnabled = pureComputed(
            () =>
                this.pageData().isCookiePreferencesEnabled &&
                this.pageData().widgetType === COOKIE_CONSENT_WIDGET_TYPE.COOKIE_PREFERENCES_WIDGET
        );

        this.isCookiePreferenceLinkEnabled = pureComputed(() => {
            const { isCookiePreferencesEnabled, widgetType, modalStyle } = this.pageData();

            return (
                isCookiePreferencesEnabled?.() &&
                widgetType === COOKIE_CONSENT_WIDGET_TYPE.COOKIE_CONSENT_WIDGET &&
                (modalStyle?.() === COOKIE_CONSENT_NARROW_STYLE || !this.isMediumUpScreen())
            );
        });

        this.isCookiePreferenceButtonEnabled = pureComputed(() => {
            const { isCookiePreferencesEnabled, widgetType, modalStyle } = this.pageData();

            return (
                isCookiePreferencesEnabled?.() &&
                this.isMediumUpScreen() &&
                widgetType === COOKIE_CONSENT_WIDGET_TYPE.COOKIE_CONSENT_WIDGET &&
                modalStyle?.() === COOKIE_CONSENT_WIDE_STYLE
            );
        });

        this.template = this.getTemplate();

        this.initalCategorySelectionValues = this.getCategorySelectionAsArray();
    }

    openCookiePreferencesModal(): void {
        showCookiePolicyOrPreferenceDialog();
    }

    private getTemplate(): string {
        if (this.pageData().widgetType === COOKIE_CONSENT_WIDGET_TYPE.COOKIE_CONSENT_WIDGET) {
            return 'cookie-consent-modal-actions-template';
        } else {
            return 'cookie-consent-preferences-modal-actions-template';
        }
    }

    getCustomStyle(key: keyof CookieConsentModalActionsCustomizationParams): string {
        return getCustomStylesCookie(
            key,
            getCookieConsentActionsSelectors(`.${this.uniqueWrapperClass}`),
            this.customizationParams
        );
    }

    acceptAll(): void {
        if (isAdminRoute()) {
            return;
        }

        acceptAll();

        tokenService.refreshDeviceIDCookie().finally(() => {
            closeCookieConsentDialogs();

            if (this.initalCategorySelectionValues.some((val) => !val)) {
                this.reloadOnValueChange();
            }
        });
    }

    declineAll(): void {
        if (isAdminRoute()) {
            return;
        }

        declineAll();

        tokenService.refreshDeviceIDCookie().finally(() => {
            closeCookieConsentDialogs();

            if (this.initalCategorySelectionValues.some((val) => val)) {
                this.reloadOnValueChange();
            }
        });
    }

    savePreferences(): void {
        if (isAdminRoute() || !this.pageData().categoriesSelectionModel) {
            return;
        }

        savePreferences({
            isFunctionalCookieEnabled:
                this.pageData().categoriesSelectionModel?.isFunctionalCookieEnabled() || false,
            isAnalyticalCookieEnabled:
                this.pageData().categoriesSelectionModel?.isAnalyticalCookieEnabled() || false,
            isCustomCookieCategoryEnabled:
                this.pageData().categoriesSelectionModel?.isCustomCookieCategoryEnabled() || false,
        });

        tokenService.refreshDeviceIDCookie().finally(() => {
            closeCookieConsentDialogs();

            if (this.hasCategorySelectionValuesChanged()) {
                this.reloadOnValueChange();
            }
        });
    }

    private reloadOnValueChange(): void {
        const isCustomJsEnabled = !!document.querySelector('#custom-js');

        if (isCustomJsEnabled) {
            this.reloadPage();
        }
    }

    private reloadPage(): void {
        window.location.reload();
    }

    private hasCategorySelectionValuesChanged(): boolean {
        return (
            this.initalCategorySelectionValues.toString() !== this.getCategorySelectionAsArray().toString()
        );
    }

    private getCategorySelectionAsArray(): boolean[] {
        return [
            this.pageData().categoriesSelectionModel?.isFunctionalCookieEnabled() || false,
            this.pageData().categoriesSelectionModel?.isAnalyticalCookieEnabled() || false,
            this.pageData().categoriesSelectionModel?.isCustomCookieCategoryEnabled() || false,
        ];
    }

    private getButtonSizeBasedOnModalStyle(): ButtonSize {
        let buttonSize = this.customizationParams.content.buttonSize();

        if (
            this.pageData?.().modalStyle?.() === COOKIE_CONSENT_WIDE_STYLE &&
            buttonSize === BUTTON_STYLE.LARGE
        ) {
            buttonSize = BUTTON_STYLE.MEDIUM;
            this.customizationParams.content.buttonSize(buttonSize);
        }

        return buttonSize;
    }
}
