import mapping from 'knockout-mapping';
import siteLanguage from 'ce-common/service/language/siteLanguage';
import Application from 'apply-flow/model/Application';
import appConfig from 'app/model/config';
import scheduledInterviewMapper from '../../../module/candidate-self-service/module/interview-scheduling/mapper/scheduledInterview';
import unscheduledInterviewRequestMapper from '../../../module/candidate-self-service/module/interview-scheduling/mapper/unscheduledInterviewRequest';
import LocaleBasedDateTimeFormatter from 'cx/service/dateTime/LocaleBasedDateTimeFormatter';
import questionnairesMapper from 'apply-flow/module/questionnaire/mapper/allQuestionnaires';
import regulatoryResponsesMapper from 'apply-flow/module/regulatory/mapper/regulatoryResponses';
import attachmentsMapper from 'apply-flow/module/file-upload/mapper/fileAttachment';
import assessmentMapper from 'apply-flow/mapper/assessment';
import backgroundCheckMapper from 'apply-flow/mapper/backgroundCheck';


export default {
    mapCreateToRest({ applicationModel, applicationDraftId, attachments = [], questionnaires, regulatoryResponses }) {
        const application = mapping.toJS(applicationModel);
        const attachmentsData = mapping.toJS(attachments);

        const mappedApplication = {
            candidateNumber: application.candidateNumber,
            siteNumber: appConfig.siteNumber,
            requisitionNumber: application.jobId,
            action: 'CREATE',
            name: application.eSignature.fullName,
            referralId: application.referralId,
            shareId: application.shareId,
            preferredLanguage: siteLanguage.getFusionCode(),
            flowVersionId: application.flowVersionId,
            legalDescriptionVersionId: application.legalDescriptionVersionId,
            esignDescriptionVersionId: application.esignDescriptionVersionId,
            preferredLocations: application.preferredLocations.map(location => location.value),
            APPDraftId: applicationDraftId,
            miscAttachmentIds: attachmentsMapper.mapMiscAttachmentsToRest(attachmentsData),
        };

        if (questionnaires?.length) {
            mappedApplication.questionnaireResponses = questionnairesMapper.mapAllQuestionnairesToRest(questionnaires);
        }

        if (regulatoryResponses?.length) {
            mappedApplication.regulatoryResponses = [regulatoryResponsesMapper.mapDataToRest(
                regulatoryResponses, application.jobId)];
        }

        return mappedApplication;
    },

    mapSecondaryToRest({ applicationModel, applicationDraftId, attachments, questionnaires, regulatoryResponses }) {
        const application = this.mapCreateToRest({
            applicationModel, applicationDraftId, questionnaires, regulatoryResponses, attachments,
        });

        const secondarySubmission = {
            flowVersionId: applicationModel.flowVersionId(),
            stepActionUsageId: applicationModel.currentPhaseId(),
            submissionId: applicationModel.submissionId(),
        };

        if (application.esignDescriptionVersionId) {
            secondarySubmission.esignDescriptionVersionId = application.esignDescriptionVersionId;
        }

        application.action = 'SECONDARY';
        application.secondarySubmissions = [];
        application.secondarySubmissions.push(secondarySubmission);

        return application;
    },

    mapAcceptToRest(applicationModel) {
        const application = mapping.toJS(applicationModel);

        return {
            candidateNumber: application.candidateNumber,
            requisitionNumber: application.jobId,
            action: 'CONFIRM',
        };
    },

    mapRejectToRest(applicationModel) {
        const application = mapping.toJS(applicationModel);

        return {
            candidateNumber: application.candidateNumber,
            requisitionNumber: application.jobId,
            action: 'DELETE',
        };
    },

    mapWithdrawToRest(applicationModel) {
        const application = mapping.toJS(applicationModel);

        return {
            candidateNumber: application.candidateNumber,
            requisitionNumber: application.jobId,
            action: 'WITHDRAW',
        };
    },

    mapCollectionFromRest(applications) {
        return applications.items.map(this.mapFromRest.bind(this));
    },

    mapFromRest(restApplicationData) {
        const restApplication = restApplicationData;
        const dateFormatter = new LocaleBasedDateTimeFormatter(appConfig.siteLang);

        if (restApplication.submissionDate) {
            restApplication.submissionDateOriginal = restApplication.submissionDate;
            restApplication.submissionDate = dateFormatter.formatDate(restApplication.submissionDate);
        }

        restApplication.jobId = restApplication.requisitionNumber;
        delete restApplication.requisitionNumber;

        restApplication.jobLocation = restApplication.jobLocationName;
        delete restApplication.jobLocationName;

        restApplication.isConfirmed = restApplication.confirmedFlag;
        delete restApplication.confirmedFlag;

        restApplication.isActive = restApplication.activeFlag;
        delete restApplication.activeFlag;

        restApplication.isPipeline = restApplication.pipelineRequisitionFlag;
        delete restApplication.pipelineRequisitionFlag;

        if (restApplication.isPipeline) {
            restApplication.isPipelineApplicationActive = restApplication.isActive;
        }

        restApplication.isHiring = restApplication.pipelineSubmissionId > 0;

        restApplication.hasAssessment = restApplication.assessmentsFlag;
        delete restApplication.assessmentsFlag;

        restApplication.hasTaxCredit = restApplication.taxCreditsFlag;
        delete restApplication.taxCreditsFlag;

        if (restApplication.assessmentLinks && restApplication.assessmentLinks.length) {
            restApplication.assessmentLinks = restApplication.assessmentLinks
                .map(assessmentMapper.mapApplicationAssessmentsFromRest);
        }

        if (restApplication.BackgroundCheckLinks && restApplication.BackgroundCheckLinks.length) {
            restApplication.backgroundCheckLinks = restApplication.BackgroundCheckLinks
                .map(backgroundCheckMapper.mapApplicationBGCFromRest);
        }

        if (restApplication.secondarySubmissions && restApplication.secondarySubmissions.length) {
            restApplication.secondarySubmissions = restApplication.secondarySubmissions
                .map(this.mapSecondarySubmissionFromRest.bind(this, restApplication.jobId));
        }

        if (restApplication.ScheduleInterviews && restApplication.ScheduleInterviews.length) {
            restApplication.scheduleInterviews = restApplication.ScheduleInterviews.map(
                scheduledInterviewMapper.mapFromRest);

            delete restApplication.ScheduleInterviews;
        }

        if (restApplication.UnscheduledInterviewRequests && restApplication.UnscheduledInterviewRequests.length) {
            restApplication.unscheduledInterviewRequests = restApplication.UnscheduledInterviewRequests.map(
                unscheduledInterviewRequestMapper.mapFromRest);

            delete restApplication.UnscheduledInterviewRequests;
        }

        return new Application(restApplication);
    },

    mapSecondarySubmissionFromRest(jobId, secondarySubmission) {
        return {
            submissionId: secondarySubmission.submissionId,
            statusCode: secondarySubmission.statusCode,
            secondaryFlowId: secondarySubmission.flowId,
            stepActionUsageId: secondarySubmission.stepActionUsageId,
            jobId,
        };
    },


    mapApplicationAssessmentFromRest(application) {
        return {
            hasAssessment: application.assessmentsFlag === true,
        };
    },

    mapApplicationAssessmentAndRemainingSubmissionsFromRest(application) {
        return {
            hasAssessment: application.assessmentsFlag === true,
            remainingSubmissions: application.remainingSubmissions,
        };
    },

    mapSubmission({ items }) {
        const [submission] = items;

        return {
            jobTitle: submission.jobTitle,
        };
    },

    mapSubmissionsFromRest({ items }) {
        return items.map(({ requisitionNumber, confirmedFlag }) => (
            {
                id: requisitionNumber,
                confirmedFlag,
            }));
    },
    mapEventRegistrationsFromRest({ items }) {
        return items.map(({ eventNumber, confirmedFlag, registrationPhase }) => (
            {
                id: eventNumber,
                confirmedFlag,
                registrationPhase,
            }));
    },

};
