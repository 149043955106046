import { PureComputed, pureComputed, observable, Observable } from 'knockout';
import {
    CategoryTilesParams,
    CategoryTilesDisplayContent,
    JobFamilyList,
    CategoryTileFromConfig,
} from './config/types';
import { mapParamsConfigurationToObservable } from 'minimal/module/search/service/observableParams';
import { emptyParams } from './config/emptyParams';
import { getCustomStyles } from './config/customStyles';
import { PageType } from 'site-editor/enum/pageTypes';
import appConfig from 'app/model/config';
import { getCatgeoryListData, getTilesData } from './service/categoryTilesProvider';
import jobAlertService from 'cx/module/job-alerts/service/jobAlerts';
import siteLanguage from 'ce-common/service/language/siteLanguage';
import router from 'app/model/router';
import { isAdminRoute } from 'core/router/service/isAdminRoute';
import { DEFAULT_CATEGORY_TILE_IMAGES } from './config/defaultParams';

export type Props = {
    params?: CategoryTilesParams;
    id?: string;
    mode?: string;
    pageType?: PageType;
    lang?: Observable<string>;
};

export class CategoryTilesViewModel {
    customCss: PureComputed<string>;
    customizationParams: CategoryTilesParams;
    uniqueWrapperClass: string;
    isAdmin: boolean;
    tileStyleClass: PureComputed<string>;
    tileBaseThemeClass: PureComputed<string>;
    tileVerticalAlignementClass: PureComputed<string>;
    categoryTilesStyles: PureComputed<string>;
    categoryTilesList: Observable<CategoryTilesDisplayContent[]>;
    languageCode: string;
    siteNumber: string;
    jobCategoryList: CategoryTileFromConfig[];

    constructor({ id, params, mode, lang }: Props) {
        const isThemePreview = Boolean(window.frameElement && window.frameElement.id === 'preview-frame');
        const { siteCode } = router.routeParams() as { siteCode: string };

        this.siteNumber = appConfig?.siteNumber;
        this.jobCategoryList = appConfig?.links?.jobCategory;

        this.uniqueWrapperClass = `component-styling-wrapper-${id}`;
        this.customizationParams = params || mapParamsConfigurationToObservable(emptyParams);

        this.isAdmin = mode === 'admin';
        this.languageCode = lang ? lang() : siteLanguage.getFusionCode();

        this.customCss = pureComputed(() =>
            getCustomStyles(this.customizationParams, this.uniqueWrapperClass)
        );

        this.tileStyleClass = pureComputed(() => this.getSelectedClassName('TILE_STYLE'));
        this.tileBaseThemeClass = pureComputed(() => this.getSelectedClassName('TILE_BASE_THEME'));
        this.tileVerticalAlignementClass = pureComputed(() => this.getSelectedClassName('TILE_ALIGNMENT'));
        this.categoryTilesStyles = pureComputed(() => this.getCategoryTilesStyles());
        this.categoryTilesList = observable<CategoryTilesDisplayContent[]>([]);

        if (isThemePreview) {
            this.categoryTilesList(getTilesData(this.jobCategoryList));
        } else {
            getCatgeoryListData(isAdminRoute() ? siteCode : this.siteNumber).then((data) => {
                if (data?.length) {
                    this.languageCode === 'US'
                        ? this.categoryTilesList(getTilesData(data))
                        : this.getUpdatedTilesList(data);
                }
            });
        }
    }

    private getUpdatedTilesList(jobCategoryList: CategoryTileFromConfig[]): void {
        jobAlertService.getJobFamilies(this.siteNumber).then((jobFamilyList: JobFamilyList[]) => {
            const configData = getTilesData(jobCategoryList);

            configData.forEach((categoryTile: CategoryTilesDisplayContent) => {
                jobFamilyList.forEach((jobFamily: JobFamilyList) => {
                    if (jobFamily.id === categoryTile.jobFamilyIds) {
                        categoryTile.title = jobFamily.jobFamilyName;
                    }
                });
            });

            this.categoryTilesList(configData);
        });
    }

    private getSelectedClassName(type: string): string {
        switch (type) {
            case 'TILE_STYLE':
                const tileStyle = this.customizationParams.content.tileStyle();

                return `category-tiles--${tileStyle}`;

            case 'TILE_BASE_THEME':
                const baseThemeColor = this.customizationParams.content.baseThemeColor();

                return `category-tiles--${baseThemeColor}`;

            case 'TILE_ALIGNMENT':
                const tileVerticalAlignment = this.customizationParams.content.tileVerticalAlignment();

                return `category-tiles--${tileVerticalAlignment}`;

            default:
                return '';
        }
    }

    private getCategoryTilesStyles(): string {
        return [
            this.getSelectedClassName('TILE_STYLE'),
            this.getSelectedClassName('TILE_BASE_THEME'),
            this.getSelectedClassName('TILE_ALIGNMENT'),
        ].join(' ');
    }

    getTileUrl(url: string): string {
        return !url || isAdminRoute() ? 'javascript:void(0)' : url;
    }

    getTileImageCssClass(categoryTile: CategoryTileFromConfig): string {
        if (categoryTile.tileImageUrl) {
            return `category-tile-image--${categoryTile.jobFamilyIds}`;
        }

        const index = categoryTile.order || 0;
        const imageColor = DEFAULT_CATEGORY_TILE_IMAGES[index % DEFAULT_CATEGORY_TILE_IMAGES.length];

        return `category-tile-image--${imageColor}`;
    }

    getBackgroundImageCss(): string {
        let cssString = '';

        this.categoryTilesList().forEach((categoryTile) => {
            if (categoryTile.tileImageUrl) {
                cssString += `.category-tile-image--${categoryTile.jobFamilyIds} {
                    background-image: url('${categoryTile.tileImageUrl}') ;
                }\n`;
            }
        });

        return cssString;
    }
}
