import { pureComputed } from 'knockout';
import storeFactory, { START, STOP, REVEAL_CLOCK, BLOCK } from './store';
import { PIN_LIMIT_REACHED } from 'candidate-verification/config/pinVerificationStatus';
import { pinResendMessage } from '../../service/pinCodeMessages';
import candidateVerificationEvents from 'candidate-verification/config/events';
import i18n from 'core/i18n/i18n';

export default class ResendPinViewModel {

    constructor({ resendPin }) {
        this.resendPin = resendPin;
        this.store = storeFactory.createStore();
        this.state = this.store.state;
        this.resendMessage = pureComputed(this.computeMessage, this);

        this.resendMessageWarning = () => i18n('apply-flow.candidate-verification.code-resend-counter',
            {
                RESEND_COUNTER: this.resendMessage().tokens.resend_counter,
            },
        );

        this.store.dispatch(START);
    }

    computeMessage() {
        const minutes = Math.floor(this.state.secondsLeft() / 60);
        const seconds = this.state.secondsLeft() % 60;

        const clock = [minutes, seconds]
            .map(String)
            .map(part => part.padStart(2, '0'))
            .join(':');

        return pinResendMessage(clock);
    }

    async resend() {
        if (this.state.secondsLeft()) {
            this.store.commit(REVEAL_CLOCK);

            candidateVerificationEvents.pinResend.dispatch();

            return;
        }

        try {
            await this.resendPin();

            this.store.dispatch(START);
            this.store.commit(REVEAL_CLOCK);
        } catch (error) {
            this._handleError(error);
        }
    }

    _handleError(error) {
        if (error === PIN_LIMIT_REACHED) {
            this.store.dispatch(BLOCK);
        }
    }

    dispose() {
        this.store.dispatch(STOP);
    }

}
