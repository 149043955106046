import { StylingBox } from 'minimal/module/search/service/customCss';
import { EMPTY_BLOCK_STYLES } from 'site-editor/module/content-editor/config';

export const mapStylingBox = (stringifiedStylingBox: string | undefined | null): StylingBox => {
    if (!stringifiedStylingBox) {
        return EMPTY_BLOCK_STYLES;
    }

    const parsedStylingBox = JSON.parse(stringifiedStylingBox) as Record<string, string | undefined | null>;

    return {
        backgroundColor: parsedStylingBox.backgroundColor || null,
        borderColor: parsedStylingBox.borderColor || null,
        borderRadius: parsedStylingBox.borderRadius || null,
        borderWidth: parsedStylingBox.borderWidth || null,
        iconColorDark: parsedStylingBox.iconColorDark || null,
        iconColorLight: parsedStylingBox.iconColorLight || null,
        ruleColor: parsedStylingBox.ruleColor || null,
        margin: parsedStylingBox.margin || null,
        padding: parsedStylingBox.padding || null,
        alignment: parsedStylingBox.alignment || null,
        color: parsedStylingBox.color || null,
        background: parsedStylingBox.background || null,
    };
};
