import router from 'app/model/router';
import searchService from 'cx/module/search/service/search';
import { insertTileToResults } from 'cx/module/search/mapper/talentCommunityTile';
import { searchJobResults } from '../model/searchJobResults';
import { searchEventResults } from '../model/searchEventResults';
import { cachedCandidateApplications } from 'apply-flow/service/cachedCandidateApplications';
import scrollKeeper from 'minimal/service/scrollKeeper';
import storageService from 'core/storage/sessionStorage';
import {
    areRecommendedJobsEnabledWithParams,
    STORAGE_RECOMMENDED_JOBS_METADATA,
} from 'minimal/component/recommended-jobs-widget/service/recommendedJobsService';
import { cachedCandidateRegistrations } from 'app/module/cx/module/apply-flow/service/cachedCandidateRegistrations';
import { areRecommendedJobsEnabled } from 'app/service/areRecommendedJobsEnabled';
import { SEARCH_MAP_JOBS_LIMIT } from 'cx/module/search/service/providers/search';
import { isCustomPageRoute } from 'core/router/service/isCustomPageRoute';

const TALENT_COMMUNITY_TILE_DEFAULT_POSITION = 5;

export class SearchService {

    constructor({ jobsModel, eventsModel }) {
        this.searchJobResultsModel = jobsModel;
        this.searchEventResultsModel = eventsModel;
        this.talentCommunityTilePosition = TALENT_COMMUNITY_TILE_DEFAULT_POSITION;
    }

    setTalentCommunityTilePosition(position) {
        this.talentCommunityTilePosition = position;
    }

    searchJobs(query, isSignedIn) {
        return this._searchJobs(this._removeUiParams(query), isSignedIn);
    }

    searchEvents(query, isSignedIn, areEventsEnabled) {
        this.searchEventResultsModel.loading(true);
        this.searchEventResultsModel.offset(0);

        return this.performEventsSearch(query, isSignedIn, areEventsEnabled)
            .then(this.handleSearchEventsResult.bind(this))
            .catch(this.handleSearchEventsError.bind(this));
    }

    loadMoreJobs(query, isSignedIn) {
        return this._loadMoreJobs(this._removeUiParams(query), isSignedIn);
    }

    isLocationSearch(query) {
        const { query: routerQuery } = router.routeParams();

        const {
            workLocationZipCode,
            latitude,
            locationLevel,
        } = query || routerQuery || {};

        return locationLevel === 'city' || workLocationZipCode || latitude;
    }

    _handleSearchJobsResult({
        requisitionList, totalJobsCount, hasMore, facets, distanceUnit, sortBy,
        keyword, correctedKeyword, useExactKeywordFlag, suggestedKeyword,
    }) {
        this.searchJobResultsModel.results(insertTileToResults(requisitionList, this.talentCommunityTilePosition));
        this.searchJobResultsModel.totalCount(totalJobsCount);
        this.searchJobResultsModel.hasMore(hasMore);
        this.searchJobResultsModel.filters(facets);
        this.searchJobResultsModel.initialized(true);
        this.searchJobResultsModel.loading(false);
        this.searchJobResultsModel.appending(false);
        this.searchJobResultsModel.distanceUnit = distanceUnit;
        this.searchJobResultsModel.sortBy(sortBy);
        this.searchJobResultsModel.keyword(keyword);
        this.searchJobResultsModel.correctedKeyword(correctedKeyword);
        this.searchJobResultsModel.useExactKeywordFlag(useExactKeywordFlag);
        this.searchJobResultsModel.suggestedKeyword(suggestedKeyword);
    }

    handleSearchEventsResult({ eventList, totalEventsCount, facets, hasMore, sortByAttr, distanceUnit }) {
        this.searchEventResultsModel.results(insertTileToResults(eventList, this.talentCommunityTilePosition));
        this.searchEventResultsModel.totalCount(totalEventsCount ?? 0);
        this.searchEventResultsModel.filters(facets);
        this.searchEventResultsModel.initialized(true);
        this.searchEventResultsModel.loading(false);
        this.searchEventResultsModel.hasMore(hasMore);
        this.searchEventResultsModel.appending(false);
        this.searchEventResultsModel.sortBy(sortByAttr);
        this.searchEventResultsModel.distanceUnit = distanceUnit;
    }

    _handleSearchJobsError(error) {
        console.error(error);
        this.searchJobResultsModel.loading(false);
        this.searchJobResultsModel.appending(false);
    }

    handleSearchEventsError(error) {
        console.error(error);
        this.searchEventResultsModel.loading(false);
        this.searchEventResultsModel.appending(false);
    }

    async _performSearch(query, isSignedIn) {
        const candidateReqList = isSignedIn ? await cachedCandidateApplications.get() : [];

        if (areRecommendedJobsEnabled() && areRecommendedJobsEnabledWithParams() && !isCustomPageRoute()) {
            const recommendedJobsMetadata = storageService.restore(STORAGE_RECOMMENDED_JOBS_METADATA);

            return searchService.searchRecommendedJobsAndFacets({ ...recommendedJobsMetadata, ...query });
        }

        return searchService.searchJobsAndFacets(query, candidateReqList);
    }

    async performEventsSearch(query, isSignedIn, areEventsEnabled) {
        const registeredEventList = isSignedIn && areEventsEnabled ? await cachedCandidateRegistrations.get() : [];

        return searchService.searchEvents(query, registeredEventList);
    }

    _loadMoreJobs(query, isSignedIn) {
        this.searchJobResultsModel.appending(true);
        this.searchJobResultsModel.offset(this.searchJobResultsModel.offset() + 1);

        const updatedQuery = Object.assign({}, query, {
            offset: this.searchJobResultsModel.offset(),
        });

        return this._performSearch(updatedQuery, isSignedIn)
            .then(({ requisitionList, hasMore, facets }) => {
                scrollKeeper.store();

                this.searchJobResultsModel.results.push(...requisitionList);
                this.searchJobResultsModel.hasMore(hasMore);
                this.searchJobResultsModel.filters(facets);
                this.searchJobResultsModel.appending(false);

                scrollKeeper.restore();
            })
            .catch(this._handleSearchJobsError.bind(this));
    }

    _loadMoreEvents(query, isSignedIn, areEventsEnabled) {
        this.searchEventResultsModel.appending(true);
        this.searchEventResultsModel.offset(this.searchEventResultsModel.offset() + 1);

        const updatedQuery = Object.assign({}, query, {
            offset: this.searchEventResultsModel.offset(),
        });

        return this.performEventsSearch(updatedQuery, isSignedIn, areEventsEnabled)
            .then(({ eventList, hasMore, facets }) => {
                scrollKeeper.store();

                this.searchEventResultsModel.results.push(...eventList);
                this.searchEventResultsModel.hasMore(hasMore);
                this.searchEventResultsModel.filters(facets);
                this.searchEventResultsModel.appending(false);

                scrollKeeper.restore();
            })
            .catch(this.handleSearchEventsError.bind(this));
    }

    _searchJobs(query, isSignedIn, contentLocaleLang) {
        this.searchJobResultsModel.loading(true);
        this.searchJobResultsModel.offset(0);

        return this._performSearch(query, isSignedIn, contentLocaleLang)
            .then(this._handleSearchJobsResult.bind(this))
            .catch(this._handleSearchJobsError.bind(this));
    }

    _removeUiParams(query) {
        const queryCopy = Object.assign({}, query);

        delete queryCopy.mode;
        delete queryCopy.zipcode;

        return queryCopy;
    }

    areMoreJobsThanMapSearchLimit() {
        const serachResultsTotalCount = this.searchJobResultsModel.totalCount();

        return serachResultsTotalCount >= SEARCH_MAP_JOBS_LIMIT;
    }

    getMarkers(query) {
        const params = this._removeUiParams(query);

        return searchService.searchMarkers(params, SEARCH_MAP_JOBS_LIMIT)
            .then(markers => markers.reduce(this._groupMarkers, []));
    }

    _groupMarkers(grouped, marker) {
        const existingMarker = grouped.find(({ coordinates }) =>
            JSON.stringify(coordinates) === JSON.stringify(marker.coordinates));

        if (existingMarker) {
            const [job] = marker.jobs;
            const [locationId] = marker.locationId;

            existingMarker.addJob(job, locationId);
        } else {
            grouped.push(marker);
        }

        return grouped;
    }

}

export const search = new SearchService({
    jobsModel: searchJobResults,
    eventsModel: searchEventResults,
});