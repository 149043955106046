import { noOperation } from 'app/module/core/utils/noOperation';
import { Observable, PureComputed, pureComputed } from 'knockout';

type DialogAlignment = 'center' | 'left' | 'right';

export type DialogDisplaySettings = {
    opacity: string | null;
    alignment: DialogAlignment | null;
    width: string | null;
};

type Props = {
    baseClass?: string;
    isVisible: Observable<boolean>;
    afterClose?: () => void;
    focusCallback?: () => void;
    displaySettings?: Observable<DialogDisplaySettings>;
    cancelBehavior: string;
    ariaLabel: Observable<string | undefined>;
    ariaDescribedBy: string | undefined;
    role: string | undefined;
    addAriaHiddenToPage: boolean;
    shouldMoveChatbot: boolean;
};

type ComponentInfo = {
    templateNodes: HTMLElement[];
};

const DEFAULT_ALIGNMENT = 'center';

class MinimalDialogViewModel {
    baseClass: string;
    isVisible: Observable<boolean>;
    ariaLabel: Observable<string | undefined>;
    ariaDescribedBy: string | undefined;
    role: string | undefined;
    afterClose: () => void;
    focusCallback: () => void;
    skipToLinkClass: PureComputed<string>;
    opacity: PureComputed<string | null>;
    alignment: PureComputed<DialogAlignment>;
    width: PureComputed<string | null>;
    nodes: Record<string, NodeListOf<ChildNode>>;
    cancelBehavior: string;
    addAriaHiddenToPage: boolean;
    shouldMoveChatbot: boolean;

    constructor({
        baseClass,
        isVisible,
        afterClose,
        focusCallback,
        displaySettings,
        cancelBehavior,
        ariaLabel,
        ariaDescribedBy,
        role,
        addAriaHiddenToPage,
        shouldMoveChatbot,
    }: Props) {
        this.baseClass = baseClass || '';
        this.isVisible = isVisible;
        this.cancelBehavior = cancelBehavior;
        this.ariaLabel = ariaLabel;
        this.ariaDescribedBy = ariaDescribedBy;
        this.role = role;
        this.afterClose = afterClose || noOperation;
        this.focusCallback = focusCallback || noOperation;
        this.addAriaHiddenToPage = addAriaHiddenToPage || false;
        this.shouldMoveChatbot = shouldMoveChatbot ?? true;

        this.skipToLinkClass = pureComputed(
            () =>
                `skip-navigation-link-dialog${
                    this.isVisible() && this.cancelBehavior !== 'none' ? '--active' : ''
                }`
        );

        this.opacity = pureComputed(() => {
            const opacity = displaySettings?.().opacity;

            return opacity ? (Number(opacity) / 100).toFixed(2) : null;
        });

        this.alignment = pureComputed(() => displaySettings?.().alignment ?? DEFAULT_ALIGNMENT);
        this.width = pureComputed(() => displaySettings?.().width ?? null);
        this.nodes = {};

        this.setAriaHiddenPropertyToPage();
    }

    close() {
        if (this.cancelBehavior !== 'none') {
            this.isVisible(false);
        }
    }

    setFocusToBody(event: Event) {
        const dialogElement = event?.target as HTMLElement;
        const dialogBodyElement = dialogElement?.querySelector('.app-dialog__main') as HTMLElement;

        dialogBodyElement?.focus();
    }

    setAriaHiddenPropertyToPage() {
        const app = document.querySelector('.app');

        if (app && this.addAriaHiddenToPage) {
            app.setAttribute('aria-hidden', 'true');
        }
    }
}

export default {
    createViewModel(params: Props, componentInfo: ComponentInfo): MinimalDialogViewModel {
        const viewModel = new MinimalDialogViewModel(params);

        componentInfo.templateNodes
            .filter((node: HTMLElement) => node.tagName)
            .forEach((node: HTMLElement) => {
                const nodeName = node.tagName.toLowerCase();

                viewModel.nodes[nodeName] = node.childNodes;
            });

        return viewModel;
    },
};
