import 'core/i18n/binding/i18n';
import 'core/i18n/binding/i18nAttr';
import 'core/i18n/binding/i18nLang';
import 'core/i18n/binding/i18nRequired';
import 'core/i18n/component/i18n/component';
import 'core/router/binding/route';
import 'core/router/binding/view';
import 'core/router/binding/routeTransition';
import 'core/router/binding/currentRoute';
import 'core/form/config/component';
import 'core/l10n/extender/l10n';
import 'core/react/binding/react';
import './routing'; // Route config must be loaded before all other modules in bundle
import 'ce-common/service/webpackTemplateEngine';
import 'ce-common/binding/autocomplete';
import 'ce-common/binding/boolAttr';
import 'ce-common/binding/a11y/keysHandler';
import 'ce-common/binding/a11y/trapFocus';
import 'ce-common/binding/a11y/toggleFocusIndicator';
import 'ce-common/binding/a11y/setInitialFocus';
import 'ce-common/binding/a11y/setFocusOnClick';
import 'ce-common/binding/a11y/setFocusOnTab';
import 'ce-common/binding/a11y/skipTo';
import 'ce-common/binding/infiniteScroll';
import 'ce-common/binding/scrollTo';
import 'ce-common/binding/setBodyScrollTo';
import 'ce-common/binding/frontImageBackground';
import 'ce-common/binding/setBodyClass';
import 'ce-common/component/media-slider/component';
import 'ce-common/component/accessibility-messages/config/component';
import 'ce-common/component/unsupported-browser-message/component';
import 'app/module/ce-common/component/cx-select-single';
import 'ce-common/module/error-404/config/module';
import 'ce-common/module/downloader/config/module';
import 'ce-common/module/internal-user/config/routing';
import 'ce-common/module/maps/config/module';
import 'ce-common/module/captcha/config/module';
import 'ce-common/service/errorHandler';
import 'cx/component/skip-navigation-link/component';
import 'minimal/binding/bodyScrolledDown';
import 'minimal/binding/loadingIndicator';
import 'minimal/binding/mouseParalax';
import 'minimal/component/cookie-consent/component';
import 'minimal/component/cookie-preference-link';
import 'cookie-consent/components/cookie-consent-modal-title';
import 'cookie-consent/components/cookie-consent-modal-policy';
import 'cookie-consent/components/cookie-consent-modal-message';
import 'cookie-consent/components/cookie-consent-modal-categories';
import 'cookie-consent/components/cookie-consent-modal-actions';
import 'cookie-consent/components/cookie-consent-modal';
import 'cookie-consent/components/cookie-policy-modal';
import 'cookie-consent/components/cookie-preferences-modal';
import 'minimal/component/custom-header/component';
import 'app/module/minimal/component/app-header';
import 'minimal/component/custom-footer/component';
import 'minimal/component/app-footer/component';
import 'minimal/component/splash-page/component';
import 'minimal/module/job-details';
import 'minimal/module/job-tags';
import 'minimal/component/app-menu/component';
import 'minimal/component/app-menu-toggle/component';
import 'minimal/component/internal-user-login-button/component';
import 'minimal/component/minimal-dialog/component';
import 'minimal/component/language-select/component';
import 'minimal/component/spinner/component';
import 'minimal/component/favourite-jobs/component';
import 'minimal/component/copy-link';
import 'minimal/component/share-widget/component';
import 'minimal/component/notifications/component';
import 'minimal/component/company-logo/component';
import 'minimal/component/search-results-distance';
import 'minimal/component/scroll-indicator/component';
import 'minimal/component/indeterminate-progress-bar/component';
import 'minimal/component/user-menu/component';
import 'minimal/module/search/config/module';
import 'minimal/module/custom-content/config/module';
import 'minimal/module/candidate-verification/config/module';
import 'minimal/module/apply-flow/config/module';
import 'minimal/module/talent-community/config/module';
import 'minimal/module/candidate-self-service/config/module';
import 'minimal/module/site-editor/config/module';
import 'minimal/module/interview-scheduling/config/module';
import 'minimal/module/offer/config/module';
import 'minimal/module/campaign/config/module';
import 'minimal/module/job-meta-item/config/module';
import 'ce-common/service/old-urls/routing';
import 'minimal/binding/calculateSearchInputWidth';
import 'ce-common/binding/a11y/setTabIndex';
import 'minimal/module/event-checkin/config/module';
import 'minimal/module/event-details/config/module';
import 'minimal/module/event-register-flow/config/module';
import 'minimal/module/candidate-verification/component/session-expire';
import 'minimal/component/recommended-jobs-widget';
import 'minimal/component/category-tiles';
import 'minimal/module/apply-flow/module/indeed-import-handler/component';
import 'minimal/module/apply-flow/module/profile-selection-overlay/component';
import 'minimal/component/oda-summary';
import 'ce-common/service/removeDeviceId';

import { components } from 'knockout';

import viewModel from '../MinimalViewModel';
import template from '../minimal.html';

components.register('minimal', { viewModel, template });