import {
    getHeightCss,
    getStylingBoxCss,
    getTypographyCss,
    getWidthCss,
    STYLES_SEPARATOR,
} from 'minimal/module/search/service/customCss';
import { CategoryTilesParams } from './types';
import { getCategoryTilesSelectors } from './selectors';
import { mergeObservableParams } from '../../../module/search/service/observableParams/mergeObservableParams';
import { getParamsValueMergeFunction } from '../../../module/search/service/observableParams/getParamsValueMergeFunction';
import { emptyParams as defaultCategoryTilesParams } from './emptyParams';

export const getCustomStyles = (params: CategoryTilesParams, uniqueWrapperClass: string): string => {
    const selectors = getCategoryTilesSelectors(`.${uniqueWrapperClass}`);

    const customizationKeys = Object.keys(params) as (keyof CategoryTilesParams)[];

    const customStyles = customizationKeys.map((key) => {
        switch (key) {
            case 'content':
                const widthSelector = getWidthCss({
                    selector: selectors.tileContainer,
                    width: params[key].width(),
                    widthUnit: params[key].widthUnit(),
                });

                const heightSelector = getHeightCss({
                    selector: selectors.tileContainer,
                    height: params[key].height(),
                    heightUnit: params[key].heightUnit(),
                });

                return [widthSelector, heightSelector].join(STYLES_SEPARATOR);

            case 'categoryTitleTypography':
                return getTypographyCss({
                    selector: selectors.tileTitle,
                    typography: params[key],
                });

            case 'categoryTitleHoverTypography':
                const categoryTitleHoverTypography = mergeObservableParams({
                    sourceParams: params.categoryTitleTypography,
                    targetParams: params.categoryTitleHoverTypography,
                    mergeParamsValue: getParamsValueMergeFunction({
                        type: 'replaceNonExistingParamsWithDefault',
                        defaultParams: defaultCategoryTilesParams.categoryTitleHoverTypography,
                    }),
                });

                return getTypographyCss({
                    selector: selectors.categoryTitleOnHover,
                    typography: categoryTitleHoverTypography,
                });

            case 'containerStyling':
                return getStylingBoxCss({
                    selector: selectors.container,
                    stylingBox: params[key],
                    lightIconSelector: null,
                    darkIconSelector: null,
                });

            case 'containerHoverStyling':
                const containerHoverStyling = mergeObservableParams({
                    sourceParams: params.containerStyling,
                    targetParams: params.containerHoverStyling,
                    mergeParamsValue: getParamsValueMergeFunction({
                        type: 'replaceNonExistingParamsWithDefault',
                        defaultParams: defaultCategoryTilesParams.containerHoverStyling,
                    }),
                });

                return getStylingBoxCss({
                    selector: selectors.containerOnHover,
                    stylingBox: containerHoverStyling,
                    lightIconSelector: null,
                    darkIconSelector: null,
                });

            case 'categoryTitleStyling':
                return getStylingBoxCss({
                    selector: selectors.categoryTitleStyle,
                    stylingBox: params[key],
                    lightIconSelector: null,
                    darkIconSelector: null,
                });

            case 'categoryTitleHoverStyling':
                const categoryTitleHoverStyling = mergeObservableParams({
                    sourceParams: params.categoryTitleStyling,
                    targetParams: params.categoryTitleHoverStyling,
                    mergeParamsValue: getParamsValueMergeFunction({
                        type: 'replaceNonExistingParamsWithDefault',
                        defaultParams: defaultCategoryTilesParams.categoryTitleHoverStyling,
                    }),
                });

                return getStylingBoxCss({
                    selector: selectors.categoryTitleHoverStyle,
                    stylingBox: categoryTitleHoverStyling,
                    lightIconSelector: null,
                    darkIconSelector: null,
                });

            case 'categoryTileStyling':
                return getStylingBoxCss({
                    selector: selectors.overallTileDisplay,
                    stylingBox: params[key],
                    lightIconSelector: null,
                    darkIconSelector: null,
                });

            case 'categoryTileHoverStyling':
                const categoryTileHoverStyling = mergeObservableParams({
                    sourceParams: params.categoryTileStyling,
                    targetParams: params.categoryTileHoverStyling,
                    mergeParamsValue: getParamsValueMergeFunction({
                        type: 'replaceNonExistingParamsWithDefault',
                        defaultParams: defaultCategoryTilesParams.categoryTileHoverStyling,
                    }),
                });

                return getStylingBoxCss({
                    selector: selectors.overallTileDisplayOnHover,
                    stylingBox: categoryTileHoverStyling,
                    lightIconSelector: null,
                    darkIconSelector: null,
                });

            case 'commonParams':
            case 'type':
            case '__ko_mapping__':
                return '';

            default:
                return '';
        }
    });

    return customStyles.filter((style) => style?.trim().length).join(STYLES_SEPARATOR);
};
