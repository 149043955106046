import { observable, pureComputed } from 'knockout';
import FormElementViewModel from 'core/form/component/form-element/FormElementViewModel';
import i18n from 'core/i18n/i18n';

export default class MultiSelectListFormElementViewModel extends FormElementViewModel {

    constructor(...args) {
        super(...args);

        this.value = observable(null);
        this.rawValue = observable(null);
        this.pills = pureComputed(this._computePills, this);

        this.onValueChanged = this.onValueChanged.bind(this);
        this.resetHandler = this.resetHandler.bind(this);

        this.genericRemoveLabel = i18n('general.remove-button');

        // knockout:checked binding requires an Array for multiselect
        if (!Array.isArray(this.element.value())) {
            this.element.value([]);
        }

        this.element.value(this.filterNonexistentValues());

        this.showNoResultsHint = pureComputed(this._showNoResultsHint, this);
    }

    _computePills() {
        return this.element.options()
            .filter(({ lookupCode }) => this.element.value()?.includes(lookupCode));
    }

    filterNonexistentValues() {
        return this.element.value()
            .filter(value => this.element.options()
                .find(({ lookupCode }) => lookupCode === value));
    }

    onValueChanged(event) {
        const { value: itemId, label: itemLabel } = event.detail.value;

        const options = this.element.options();

        const item = options
            .find(({ lookupCode, meaning }) => lookupCode === itemId && meaning === itemLabel);

        if (!item) {
            return;
        }

        const arrayValue = this.element.value();

        arrayValue.push(item.lookupCode);
        this.element.value(arrayValue);
        this.element._disableOptions();

        this.hasFocus(true);
    }

    resetHandler() {
        this.value(null);
    }

    blurHandler() {
        this.hasFocus(false);

        super.blurHandler();
    }

    _showNoResultsHint() {
        if (!this.element.rawValue()) {
            return false;
        }

        return !this.element.options()
            .find(({ meaning }) => meaning.toString().toLowerCase().includes(this.element.rawValue().toLowerCase()));
    }

}