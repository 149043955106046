import { observable } from 'knockout';
import notificationsService from 'cx/service/notifications';
import i18n from 'core/i18n/i18n';
import csEvents from '../../config/events';
import pipelineApplications from '../../../apply-flow/service/pipelineApplications';
import withdrawApplicationService from 'apply-flow/service/withdrawApplication';
import appConfig from 'app/model/config';
import { storeWithdrawnApplication } from 'ce-common/service/withdrawService';

export default class ApplicationWithdrawDialogViewModel {

    constructor() {
        this.application = observable({});
        this.isWithdrawDialogVisible = observable(false);
        this.isWithdrawInProgress = observable(false);
        this.modalTitle = observable(i18n('candidate-self-service.withdraw-button-label'));

        this.showWarningText =
            appConfig.getSettingByKey('ORA_IRC_JA_WITHDRAW_REAPPLY') === 'true'
                ? 'candidate-self-service.reapply-withdraw-warning'
                : 'candidate-self-service.application-withdraw-warning';

        csEvents.openWithdrawDialog.add(application => this.showWithdrawDialog(application));
    }

    get _withdrawFailMessage() {
        return i18n('candidate-self-service.withdraw-fail-message', { public_status: this.application().status });
    }

    hideWithdrawDialog() {
        this.isWithdrawDialogVisible(false);
        this.application({});
    }

    showWithdrawDialog(application) {
        this.isWithdrawDialogVisible(true);
        this.application(application);
    }

    withdrawApplication() {
        this.isWithdrawInProgress(true);

        let withdrawAction;

        if (this.application().isPipeline()) {
            withdrawAction = pipelineApplications.withdraw(this.application());
        } else {
            withdrawAction = withdrawApplicationService.withdraw(this.application());
        }

        withdrawAction
            .then(() => {
                storeWithdrawnApplication(this.application().jobId());
                notificationsService.success(i18n('candidate-self-service.withdraw-application-success-message'));
            })
            .catch((error) => {
                if (typeof error === 'object' && error.message === 'withdraw-application-fail') {
                    return notificationsService.error(this._withdrawFailMessage);
                }

                return notificationsService.error();
            })
            .then(() => {
                this.hideWithdrawDialog();
                this.isWithdrawInProgress(false);
            });
    }

}