import { observable, pureComputed } from 'knockout';
import i18n from 'core/i18n/i18n';
import ValidatableForm from 'core/form/ValidatableForm';
import debounce from 'core/utils/debouncePromise';
import notificationsService from 'cx/service/notifications';
import ChallengeModel from 'candidate-verification/model/challenge';
import { SMS, EMAIL } from 'candidate-verification/config/verificationMethods';
import communicationsChannelService from '../service/prefferedCommunicationsChannel';
import candidateValidation from 'apply-flow/service/backend-validation/candidateValidation';
import phoneLocaleAwareFactory from 'cx/service/phone/phoneLocaleAwareFactory';
import { isLegislationCodeEnabled } from 'app/module/cx/module/apply-flow/module/personal-information-basic/service/isLegislationCodeEnabled';

export default class EditPersonalInformationForm extends ValidatableForm {

    constructor(config, params) {
        super(config);

        this.params = params;

        this.emailEdit = null;
        this.emailFields = [];

        this.phoneEdit = null;
        this.phoneFields = null;

        this.emailCommunication = null;
        this.phoneCommunication = null;

        this.makeEmailPreferred = null;
        this.makePhonePreferred = null;

        this.subscriptions = [];

        this._setupEmailFields();
        this._setupPhoneField();
    }

    registerModel(model) {
        super.registerModel(model);
        this._setupSubscriptions();
    }

    dispose() {
        this.subscriptions.forEach(subscription => subscription.dispose());
    }

    _setupEmailFields() {
        this.emailEdit = this.getElement('email');

        this.emailEdit.requestVerification = this._requestEmailVerification.bind(this);
        this.emailEdit.requestRemoval = this._requestEmailRemoval.bind(this);
        this.emailEdit.isChannelVerified = this.params.emailVerifiedFlag;

        this.emailEdit.challenge = new ChallengeModel({
            candidate: this._getCandidate({
                attachEmail: true,
                attachPhone: false,
            }),
            actions: {
                onPinValid: this._onEmailVerificationSuccess.bind(this),
                onAttempsLimitReached: () => {},
            },
        });

        this.makeEmailPreferred = debounce(communicationsChannelService.makeEmailPreferred, 200);

        this.emailCommunication = this.getElement('emailPreferredFlag');
        this.emailCommunication.isDisabled = pureComputed(this._isEmailCommunicationDisabled.bind(this));

        this.emailFields = [
            this.getElement('emailVerifiedFlag'),
            this.emailCommunication,
        ];
    }

    _setupPhoneField() {
        this.phoneEdit = this.getElement('phoneNumber');

        this.phoneEdit.requestVerification = this._requestPhoneVerification.bind(this);
        this.phoneEdit.requestRemoval = this._requestPhoneRemoval.bind(this);
        this.phoneEdit.isChannelVerified = this.params.phoneVerifiedFlag;

        this.phoneEdit.challenge = new ChallengeModel({
            candidate: this._getCandidate({
                attachEmail: false,
                attachPhone: true,
            }),
            actions: {
                onPinValid: this._onPhoneVerificationSuccess.bind(this),
                onAttempsLimitReached: () => {},
            },
        });

        this.makePhonePreferred = debounce(communicationsChannelService.makePhonePreferred, 200);

        this.phoneCommunication = this.getElement('phonePreferredFlag');
        this.phoneCommunication.isDisabled = pureComputed(this._isPhoneCommunicationDisabled.bind(this));

        this.phoneFields = [
            this.getElement('phoneVerifiedFlag'),
            this.getElement('phonePreferredFlag'),
        ];
    }

    _setupSubscriptions() {
        const emailPreferredSubscription = this.emailCommunication.value
            .subscribe(this._onEmailPreferredChanged, this);

        const phonePreferredSubscription = this.phoneCommunication.value
            .subscribe(this._onPhonePreferredChanged, this);

        const emailVerifySubscription = this.emailEdit.isVerificationInProgress
            .subscribe(value => this.emailFields.forEach(field => field.isHidden(value)));

        const phoneVerifySubscription = this.phoneEdit.isVerificationInProgress
            .subscribe(value => this.phoneFields.forEach(field => field.isHidden(value)));

        this.subscriptions = [
            emailPreferredSubscription,
            phonePreferredSubscription,
            emailVerifySubscription,
            phoneVerifySubscription,
        ];
    }

    _isEmailCommunicationDisabled() {
        const { emailVerifiedFlag, phonePreferredFlag } = this.params;

        return !emailVerifiedFlag() || !phonePreferredFlag();
    }

    _isPhoneCommunicationDisabled() {
        const { emailPreferredFlag, phoneVerifiedFlag } = this.params;

        return !phoneVerifiedFlag() || !emailPreferredFlag();
    }

    _onEmailPreferredChanged(value) {
        const { candidateId } = this.params;

        this.makeEmailPreferred(candidateId, value);
        this.phoneEdit.validateImmediately(false);
    }

    _onPhonePreferredChanged(value) {
        const { candidateId } = this.params;

        this.makePhonePreferred(candidateId, value);
        this.emailEdit.validateImmediately(false);
    }

    _requestPhoneVerification(value) {
        const { candidateId } = this.params;
        const { countryCode, areaCode, number, legislationCode } = value;

        const phone = {
            mobilePhoneCountryCode: countryCode,
            mobilePhoneAreaCode: areaCode,
            mobilePhoneNumber: number,
            mobilePhoneLegislationCode: legislationCode,
        };

        return candidateValidation.validatePhone(candidateId, phone)
            .then(() => communicationsChannelService.requestPhoneVerification(candidateId, phone));
    }

    _requestEmailVerification(value) {
        const { candidateId } = this.params;

        return candidateValidation.validateEmail(candidateId, value)
            .then(() => communicationsChannelService.requestEmailVerification(candidateId, value));
    }

    _requestEmailRemoval() {
        const { candidateId } = this.params;

        return communicationsChannelService.requestEmailRemoval(candidateId)
            .then(() => {
                const { emailPreferredFlag, emailVerifiedFlag } = this.params;

                emailPreferredFlag(false);
                emailVerifiedFlag(false);
            });
    }

    _requestPhoneRemoval() {
        const { candidateId } = this.params;

        return communicationsChannelService.requestPhoneRemoval(candidateId)
            .then(() => {
                const { phonePreferredFlag, phoneVerifiedFlag } = this.params;

                phonePreferredFlag(false);
                phoneVerifiedFlag(false);
            });
    }

    _getCandidate({ attachEmail, attachPhone }) {
        const candidate = {
            email: observable(),
            phone: observable(),
            verificationMethod: observable(),
            id: this.params.candidateId,
        };

        if (attachEmail) {
            candidate.email = this.emailEdit.value;
            candidate.verificationMethod(EMAIL);
        }

        if (attachPhone) {
            candidate.phone = this.phoneEdit.value;
            candidate.verificationMethod(SMS);
        }

        return candidate;
    }

    _onPhoneVerificationSuccess() {
        this.phoneEdit.isDisabled(true);
        this.phoneEdit.isVerificationInProgress(false);
        this.phoneEdit.removeValidator(this.phoneEdit.getValidator('required'));

        const { phoneVerifiedFlag } = this.params;

        phoneVerifiedFlag(true);

        return this._displayPhoneVerificationNotification();
    }

    _onEmailVerificationSuccess() {
        this.emailEdit.isDisabled(true);
        this.emailEdit.isVerificationInProgress(false);
        this.emailEdit.removeValidator(this.emailEdit.getValidator('required'));

        const { emailVerifiedFlag } = this.params;

        emailVerifiedFlag(true);

        const { origin, value } = this.emailEdit;

        if (origin === value()) {
            return notificationsService
                .success(i18n('candidate-self-service.communication-channel.email-verified-success'), 0);
        }

        return notificationsService
            .success(i18n('talent-community.email-change-verification-confirmed'), 0);
    }

    _displayPhoneVerificationNotification() {
        let originPhone;
        let valuePhone;

        const { origin, value } = this.phoneEdit;

        if (isLegislationCodeEnabled && origin.legislationCode) {
            originPhone = phoneLocaleAwareFactory.createPhone(origin).asFormattedStringWithLegislationCode();
            valuePhone = phoneLocaleAwareFactory.createPhone(value()).asFormattedStringWithLegislationCode();
        } else {
            originPhone = phoneLocaleAwareFactory.createPhone(origin).asFormattedString();
            valuePhone = phoneLocaleAwareFactory.createPhone(value()).asFormattedString();
        }

        const notificationMessageKey = originPhone === valuePhone
            ? 'candidate-self-service.communication-channel.phone-verified-success'
            : 'candidate-self-service.communication-channel.phone-changed-success';

        return notificationsService
            .success(i18n(notificationMessageKey), 0);
    }

}